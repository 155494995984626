import React, { useState } from 'react'
import { BsTrash3, BsTelephone, BsEnvelope, BsGlobe2 } from 'react-icons/bs'
import ManageLeadModal from './ManageLeadModal'
import SweetAlert from '../../../CommonComponents/SweetAlert'
import { MdOutlineDriveFileMoveRtl } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { onDeleteLeadFolder, onLeadStatusChange } from '../../../../Redux/Actions/LeadAction';
import { useSelector } from 'react-redux';
import { BiSolidReport } from 'react-icons/bi';
import GenerateReportModal from './GenerateReportModal';


const ManageLeadRow = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const leadFolderData = useSelector((state) => state.lead.data)
    const [show, setShow] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const generateReport = () => setShowReport(true);
    const handleCloseReport = () => setShowReport(false);

    const [moreToggle, setMoreToggle] = useState({
        emails: 3,
        contacts: 2
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: [props.curElem.id],
            type: "lead"
        }
        dispatch(onDeleteLeadFolder("delete-leads", data, leadFolderData, props.index, setSweet, props.mainCheckedArr, props.setMainCheckedArr))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const handleReadMore = (type) => {
        if (type === "emails") {
            setMoreToggle((preData) => {
                return {
                    ...moreToggle,
                    emails: preData.emails === 3 ? JSON.parse(props?.curElem?.emails)?.length : 3
                }
            })
        } else {
            setMoreToggle((preData) => {
                return {
                    ...moreToggle,
                    contacts: preData.contacts === 2 ? JSON.parse(props?.curElem?.contact)?.length : 2
                }
            })
        }
    }

    const handleChange = (e) => {
        if (+auth.user.isClientAccount === 1) {
            return;
        }
        const { value } = e.target
        let data = {
            id: props.curElem.id,
            status: value
        }
        dispatch(onLeadStatusChange(data, props.index, leadFolderData))
    }

    const handleCheck = (e) => {
        let arr = [...props.mainCheckedArr]
        if (e.target.checked) {
            arr.push(props.curElem.id)
        } else {
            let idIndex = props.mainCheckedArr.findIndex((val) => +val === +props.curElem.id)
            arr.splice(idIndex, 1)
        }
        props.setMainCheckedArr((draft) => {
            draft[props.index] = arr
        })
    }

    return (
        <>
            <tr>
                <td width="30">
                    <label className="table-check">
                        <input type="checkbox"
                            checked={props.mainCheckedArr.includes(props.curElem.id)}
                            onChange={handleCheck}
                        />
                        <span className="check-mark"></span>
                    </label>
                </td>
                <td width="300">{props.curElem.businessName}</td>
                <td width="320">
                    <div className='detailTd lead_data'>
                        <div className='lead_content'><small><BsTelephone /></small> <small>{props.curElem.phoneNumber ? <span style={{ fontSize: "13px" }}>{props.curElem.phoneNumber}</span> : ""}
                            {
                                props.curElem?.contact?.length > 0 ?
                                    JSON.parse(props.curElem.contact).length > 0 ?
                                        JSON.parse(props.curElem.contact).map((contact, index) => <span key={index} style={{ fontSize: "13px" }}>{contact}</span>).slice(0, moreToggle.contacts)
                                        : props.curElem.phoneNumber ? "" : "N/A"
                                    : props.curElem.phoneNumber ? "" : "N/A"
                            }
                            {props.curElem?.contact?.length > 0 ?
                                JSON.parse(props.curElem.contact).length > 2 ?
                                    <span className='review_read_more'>
                                        <span style={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => handleReadMore("contacts")}>{moreToggle.contacts > 2 ? "ReadLess" : "ReadMore..."}</span>
                                    </span>
                                    : ""
                                : ""
                            }
                        </small> </div>
                        <div className='lead_content' ><small><BsEnvelope /></small> <small>
                            {
                                props.curElem?.emails?.length > 0 ?
                                    JSON.parse(props.curElem.emails).length > 0 ?
                                        JSON.parse(props.curElem.emails).map((email, index) => <span key={index} style={{ fontSize: "13px" }}>{email}</span>).slice(0, moreToggle.emails)
                                        : "N/A"
                                    : "N/A"
                            }
                            {props.curElem?.emails?.length > 0 ?
                                JSON.parse(props.curElem.emails).length > 3 ?
                                    <span className='review_read_more'>
                                        <span style={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => handleReadMore("emails")}>{moreToggle.emails > 3 ? "ReadLess" : "ReadMore..."}</span>
                                    </span>
                                    : ""
                                : ""
                            }
                        </small > </div>
                        <div className='lead_content' > <span style={{ borderBottom: "none" }}><small><BsGlobe2 /></small> <span style={{ fontSize: "13px" }}>{props.curElem.website ? <a className='table-linkBtn' style={{ fontSize: "13px" }} target='_blank' href={props.curElem.website}>visit</a> : "N/A"}</span> </span></div>
                    </div>
                </td>
                <td width="220">
                    <select
                        className="common-inp alt"
                        style={{ width: "160px", padding: "12px 20px" }}
                        name="leadStatus"
                        value={props.curElem.leadStatus}
                        onChange={handleChange}
                    >
                        <option value="">Select Status</option>
                        <option value="contacted">Contacted</option>
                        <option value="noRespond">No Respond</option>
                        <option value="pendingRespond">Pending Respond</option>
                        <option value="dealSecured">Deal Secured</option>
                    </select>
                </td>
                <td><span>{props.curElem.mapUrl ? <a className='table-linkBtn' style={{ fontSize: "13px" }} target='_blank' href={props.curElem.mapUrl}>View</a> : "N/A"}</span></td>
                <td>
                    <div className="review_data">
                        {props.curElem.userRatingsTotal ? props.curElem.userRatingsTotal : "N/A"}
                    </div>
                </td>
                <td className="text-end">
                    {+auth.user.isClientAccount === 1 ? null :
                        <span className="widg-icon">
                            <a onClick={generateReport}><BiSolidReport style={{ fontSize: "24px" }} /></a>
                            <a onClick={handleShow}><MdOutlineDriveFileMoveRtl style={{ fontSize: "24px" }} /></a>
                            <a onClick={deleteRow}><BsTrash3 /></a>
                        </span>
                    }
                </td>
            </tr >

            <ManageLeadModal
                curElem={[props.curElem.id]}
                index={props.index}
                type="moveFolder"
                show={show}
                handleClose={handleClose}
                fetchData={props.fetchAllFolder}
                mainCheckedArr={props.mainCheckedArr}
                setMainCheckedArr={props.setMainCheckedArr}
            />

            <GenerateReportModal
                show={showReport}
                handleClose={handleCloseReport}
                curElem={props.curElem}
            />

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure?<br><br> Do you want to delete this lead?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </>
    )
}

export default ManageLeadRow
