import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BsSearch } from "react-icons/bs";
import TitleBar from "../../CommonComponents/TitleBar";
import { fetchData } from "../../../Redux/Actions/CommonActions";
import ScheduleRows from "./ScheduleRows";

const Schedule = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [q, setQ] = useState("")
    const [scheduleData, setScheduleData] = useState([])


    const fetchSchedule = () => {
        dispatch(fetchData("fetch-scheduler", {}, setScheduleData, loader, setLoader))
    }

    useEffect(() => {
        fetchSchedule()
    }, [])

    return (
        <>
            <TitleBar title="Schedule" />

            <div className="site-wrap">
                <div className="site-container">

                    <div className="widget-top">
                        <h6>Schedule</h6>
                        <div className="searchBar">
                            <input
                                type="text"
                                placeholder="Search"
                                onChange={(e) => setQ(e.target.value)}
                            />
                            <span><BsSearch /></span>
                        </div>
                    </div>

                    <div className="table-area mt-5">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th >Preview</th>
                                    <th>Type</th>
                                    <th>Social</th>
                                    <th>Schedule Date</th>
                                    <th>Status</th>
                                    <th>View Post</th>
                                    <th>Action</th>
                                </tr>
                                {scheduleData.length > 0 ?
                                    scheduleData.filter((cur) => {
                                        return cur.postingData.title.toLowerCase().includes(q.toLowerCase())
                                    }).length > 0 ?
                                        scheduleData.filter((cur) => {
                                            return cur.postingData.title.toLowerCase().includes(q.toLowerCase())
                                        }).map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <ScheduleRows
                                                        curElem={curElem}
                                                        scheduleData={scheduleData}
                                                        setScheduleData={setScheduleData}
                                                        fetchSchedule={fetchSchedule}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={8} className="text-center">
                                                No scheduled post found.
                                            </td>
                                        </tr>
                                    :

                                    <tr>
                                        <td colSpan={8} className="text-center">
                                            {loader.fetch ? <i className="fa fa-spin fa-spinner spin-style" /> : "No posts scheduled yet."}
                                        </td>
                                    </tr>
                                }

                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Schedule;