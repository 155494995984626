import React, { useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { BsXLg } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { onActiveStyle, onChangeText, onCheckSocialData, onDeactiveStyle, onSubmitWidget } from '../../../../../Redux/Actions/WidgetActions'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../../Redux/Actions/AlertActions'

const FloatingWindow = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const widget = useSelector(state => state.widget.data)

    const [loader, setLoader] = useState(false)

    const handleClick = (e, type) => {
        e.stopPropagation()
        if (widget.activeStyle !== type) {
            dispatch(onActiveStyle(type))
        }
    }

    const handleChange = (e) => {
        if (widget.activeStyle === "headline" || widget.activeStyle === "description") {
            if (widget.activeStyle === "headline" && e.target.value.length > 60) {
                dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
            } else if (widget.activeStyle === "description" && e.target.value.length > 150) {
                dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
            } else {
                dispatch(onChangeText(widget.activeStyle, e.target.value))
            }
        }
        else {
            if (e.target.value.length <= 30) {
                dispatch(onCheckSocialData(widget.activeStyle, "text", e.target.value))
            } else {
                dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
            }
        }
    }

    const canSubmit = (data) => {
        let socials = data.data.socials
        let isUrl = false
        let isCorrectUrl = false
        let keyArr = Object.keys(socials)
        keyArr.forEach((curElem) => {
            if (socials[curElem].enable) {
                if (socials[curElem].btnUrl) {
                    isCorrectUrl = socials[curElem].btnUrl.includes("https://") || socials[curElem].btnUrl.includes("http://") ? false : `${curElem.slice(0, 1).toUpperCase()}${curElem.slice(1)}`
                } else {
                    isUrl = `${curElem.slice(0, 1).toUpperCase()}${curElem.slice(1)}`
                }
            }
        })

        if (isUrl || isCorrectUrl) {
            if (isUrl) {
                dispatch(setAlert(`${isUrl} review field is empty. Either enter a ${isUrl} URL or switch the button off.`, "danger"))
            }
            else if (isCorrectUrl) {
                dispatch(setAlert(`${isCorrectUrl} Review URL is not valid`, "danger"))

            }
            return false
        } else {
            return true
        }
    }

    const handleSubmit = () => {
        let data = {
            ...widget
        }
        let submit = canSubmit(data)
        if (submit) {
            setLoader(true)
            dispatch(onSubmitWidget(data, navigate, setLoader))
        }

    }

    const handleCloseStyle = () => {
        if (widget.activeStyle) {
            dispatch(onDeactiveStyle("website"))
        }
    }

    return (
        <div className={`widget-prev-outer ${widget.data.position === "top" ? "top expand" : widget.data.position === "center" ? "" : widget.data.position === "right" ? "right" : widget.data.position === "b-right" ? "box" : ""}`} onClick={handleCloseStyle}>
            <div className="widget-create-button">
                <button className="theme-btn" onClick={handleSubmit}><span>{loader ? <>Generating <i className='fa fa-spin fa-spinner' /></> : "Generate widget"}</span></button>
            </div>

            {widget.data.position !== "top" ?
                <div className="modal-arrow">
                    <p>Click on any widget element to edit it. <BsXLg /> </p>
                    <img src={require('../../../../../assets/images/review/modal_arrow.png')} alt="" />
                </div> : null}

            <div className={`widget-prev animate__animated ${widget.data.animation}`} style={{
                background: widget.data.background.type === "color" ? widget.data.background.src : `url(${widget.data.background.src})`,
                border: widget.data.border.enable ? `2px solid ${widget.data.border.color}` : "none",
               
            }}>
                <div className="widgetModal-close2" onClick={props.handleClose}><IoClose /></div>
                {/* widget_image */}
                <div className={`${widget.data.position === "top" ? "widegt_top_wrap" : ""}`}>
                    {widget.data.image.enable && widget.data.image.url ?
                        <div className=" widget_img_wrapper mb-2">
                            <img src={widget.data.image.url} className="img-fluid" />
                        </div> : null}
                    {/* widget_image */}
                    <div>
                        {widget.data.headline.enable ?
                            <>
                                <ContentEditable
                                    html={widget.data.headline.text}
                                    tagName={"h6"}
                                    onChange={handleChange}
                                    onClick={(e) => handleClick(e, "headline")}
                                    style={{ ...widget.data.headline.css, cursor: "pointer", border: widget.activeStyle === "headline" ? "2px dashed #dcdcdc" : "" }}
                                />
                            </>
                            : null
                        }
                        {widget.data.description.enable ?
                            <ContentEditable
                                html={widget.data.description.text}
                                tagName={"p"}
                                onChange={handleChange}
                                onClick={(e) => handleClick(e, "description")}
                                style={{ ...widget.data.description.css, cursor: "pointer", border: widget.activeStyle === "description" ? "2px dashed #dcdcdc" : "" }}
                            />
                            : null}
                    </div>
                </div>

                <div className="widget-prev-button">
                    <ul>
                        {widget.data.socials.google.enable ?
                            <li onClick={(e) => handleClick(e, "google")} style={{ border: widget.activeStyle === "google" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.google.css }}>
                                    {widget.data.socials.google.icon ?
                                        <img src={require('../../../../../assets/images/review/GOOGLE.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.google.text}
                                        onChange={handleChange}

                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.bing.enable ?
                            <li onClick={(e) => handleClick(e, "bing")} style={{ border: widget.activeStyle === "bing" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.bing.css }}>
                                    {widget.data.socials.bing.icon ?
                                        <img src={require('../../../../../assets/images/review/BING.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.bing.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.angi.enable ?
                            <li onClick={(e) => handleClick(e, "angi")} style={{ border: widget.activeStyle === "angi" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.angi.css }}>
                                    {widget.data.socials.angi.icon ?
                                        <img src={require('../../../../../assets/images/review/Angi.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.angi.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.zillow.enable ?
                            <li onClick={(e) => handleClick(e, "zillow")} style={{ border: widget.activeStyle === "zillow" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.zillow.css }}>
                                    {widget.data.socials.zillow.icon ?
                                        <img src={require('../../../../../assets/images/review/ZILLOW.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.zillow.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.foursquare.enable ?
                            <li onClick={(e) => handleClick(e, "foursquare")} style={{ border: widget.activeStyle === "foursquare" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.foursquare.css }}>
                                    {widget.data.socials.foursquare.icon ?
                                        <img src={require('../../../../../assets/images/review/FOURSQUARE.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.foursquare.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.trustpilot.enable ?
                            <li onClick={(e) => handleClick(e, "trustpilot")} style={{ border: widget.activeStyle === "trustpilot" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.trustpilot.css }}>
                                    {widget.data.socials.trustpilot.icon ?
                                        <img src={require('../../../../../assets/images/review/TRUSTPILOT.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.trustpilot.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.tripadvisor.enable ?
                            <li onClick={(e) => handleClick(e, "tripadvisor")} style={{ border: widget.activeStyle === "tripadvisor" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.tripadvisor.css }}>
                                    {widget.data.socials.tripadvisor.icon ?
                                        <img src={require('../../../../../assets/images/review/TRIPADVISOR.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.tripadvisor.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.shopify.enable ?
                            <li onClick={(e) => handleClick(e, "shopify")} style={{ border: widget.activeStyle === "shopify" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.shopify.css }}>
                                    {widget.data.socials.shopify.icon ?
                                        <img src={require('../../../../../assets/images/review/SHOPIFY.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.shopify.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.facebook.enable ?
                            <li onClick={(e) => handleClick(e, "facebook")} style={{ border: widget.activeStyle === "facebook" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.facebook.css }}>
                                    {widget.data.socials.facebook.icon ?
                                        <img src={require('../../../../../assets/images/review/facebook_icon.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.facebook.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }

                        {widget.data.socials.yelp.enable ?
                            <li onClick={(e) => handleClick(e, "yelp")} style={{ border: widget.activeStyle === "yelp" ? "2px dashed #dcdcdc" : "" }}>
                                <button style={{ ...widget.data.socials.yelp.css }}>
                                    {widget.data.socials.yelp.icon ?
                                        <img src={require('../../../../../assets/images/review/yelp_icon.png')} alt="" /> : null}
                                    <ContentEditable
                                        html={widget.data.socials.yelp.text}
                                        onChange={handleChange}
                                    />
                                </button>
                            </li> : null
                        }
                    </ul>
                </div>
            </div>
            {widget.data.position === "top" ?
                <div className="modal-arrow">
                    <img src={require('../../../../../assets/images/review/modal_arrow.png')} alt="" />
                    <p>Click on any widget element to edit it. <BsXLg /> </p>
                </div>

                : null}
        </div>
    )
}

export default FloatingWindow