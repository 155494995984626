import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { themeColor } from '../../../Global/Global'

const WidgetStyleWindow = (props) => {
    return (
        <div className="site-wrap">
            <div className="site-container">
                <div className="widget-style">
                    <h4>{props.title}</h4>
                    <p>{props.description}</p>

                    <ul className="widget-list">
                        <li>
                            <span
                                onClick={() => props.handleOpenPopup("center")}
                                style={{ border: props.position === "center" ? `2px solid ${themeColor}` : "", position: "relative" }}
                            >
                                <img src={require('../../../assets/images/modal.png')} alt="" />
                                <p>Modal</p>
                                {props.position === "center" ?
                                    <FaCheck className="widget-check" /> : ""}
                            </span>
                        </li>
                        <li>
                            <span
                                onClick={() => props.handleOpenPopup("top")}
                                style={{ border: props.position === "top" ? `2px solid ${themeColor}` : "", position: "relative" }}
                            >
                                <img src={require('../../../assets/images/floating.png')} alt="" />
                                <p>Floating Bar</p>
                                {props.position === "top" ?
                                    <FaCheck className="widget-check" /> : ""}

                            </span>
                        </li>
                        <li>
                            <span
                                onClick={() => props.handleOpenPopup("right")}
                                style={{ border: props.position === "right" ? `2px solid ${themeColor}` : "", position: "relative" }}
                            >
                                <img src={require('../../../assets/images/slideout.png')} alt="" />
                                <p>Slide-Out</p>
                                {props.position === "right" ?
                                    <FaCheck className="widget-check" /> : ""}

                            </span>
                        </li>
                        <li>
                            <span
                                onClick={() => props.handleOpenPopup("b-right")}
                                style={{ border: props.position === "b-right" ? `2px solid ${themeColor}` : "", position: "relative" }}
                            >
                                <img src={require('../../../assets/images/box.png')} alt="" />
                                <p>Box</p>
                                {props.position === "b-right" ?
                                    <FaCheck className="widget-check" /> : ""}

                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default WidgetStyleWindow