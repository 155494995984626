import React, { useEffect, useState } from "react";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Outlet } from "react-router-dom";
import { onFetchLeadFolder, onRemoveLeadFolderData } from "../../../Redux/Actions/LeadAction";
import { useDispatch } from "react-redux";


const MainLead = () => {

    const dispatch = useDispatch();
    const fetchAllFolder = () => {
        dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: false, loader: true } })
        dispatch(onFetchLeadFolder())
    }

    useEffect(() => {
        fetchAllFolder()

        return () => {
            dispatch(onRemoveLeadFolderData())
        }
    }, [])

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default MainLead;