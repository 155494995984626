import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BsXLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onCreateWidget } from '../../Redux/Actions/WidgetActions'

const CreateModal = (props) => {
    let customDim = ["803x473", "893x596", "794x1122"]
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [createData, setCreateData] = useState({
        name: "",
        aiQr: {
            enable: true,
            text: ""
        },
    })

    const [dim, setDim] = useState({
        h: "",
        w: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setCreateData({
            ...createData,
            [name]: value
        })
    }

    const handleAiChange = (e) => {
        let { name, value, checked } = e.target
        if (name === "enable") {
            value = checked
        }
        setCreateData({
            ...createData,
            aiQr: {
                ...createData.aiQr,
                [name]: value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = { ...createData }
        if (props.url === "create-qr-code") {
           
            if (props.data && props.data.data) {
                data.data = JSON.parse(props.data.data)
            }
            if (data.size === "custom") {
                let multiplier = 1
                if (+dim.w > 800) {
                    multiplier = (+dim.w / 800).toFixed(1)
                } else {
                    multiplier = (800 / +dim.w).toFixed(1)
                }
                data.size = `${dim.w}x${dim.h}`
                data.multiplier = multiplier
            }else{
                if(data.size === "794x1122"){
                    data.multiplier = 2
                }else{
                    data.multiplier = 1
                }
            }
        }
        dispatch(onCreateWidget(props.url, data, navigate, props.redirect, setLoader))
    }

    useEffect(() => {
        if (props.data) {
            let aiQr
            if (props.data.aiQr) {
                aiQr = JSON.parse(props.data.aiQr)
            } else {
                aiQr = {
                    enable: true,
                    text: ""
                }
            }
            let size = props.data.size
            if (!customDim.includes(props.data.size)) {
                setDim({
                    ...dim,
                    h: props.data.size.split("x")[1],
                    w: props.data.size.split("x")[0]
                })
                size = "custom"
            }
            setCreateData({
                ...createData,
                name: props.data.name,
                size: size,
                id: props.data.id,
                redirectUrl: props.data.redirectUrl,
                imageUrl: props.data.imageUrl,
                aiQr: aiQr
            })
        }
    }, [props])


    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="modalClose" onClick={props.handleClose}><BsXLg /></div>
                    <h5>{props.heading}</h5>
                    <div className="input-outer mt-2 ">
                        <label className="pb-3" htmlFor="">Give a campaign name to your widget</label>
                        <input
                            className="common-inp alt"
                            type="text"
                            name='name'
                            placeholder="Campaign name"
                            value={createData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {props.url === "create-qr-code" ?
                        <>
                            <div className="input-outer mt-4">
                                <label className="pb-2" htmlFor="">Select a canvas size below.</label>
                                <select
                                    className="common-inp alt"
                                    name="size"
                                    onChange={handleChange}
                                    value={createData.size}
                                    required
                                >
                                    <option value="">Select size</option>
                                    <option value="803x473">Business card (8.5x5cm)</option>
                                    <option value="893x596">Card (14.8x10.5cm)</option>
                                    <option value="794x1122">Flyer (210x297mm)</option>
                                    <option value="custom">Custom(px)</option>
                                </select>
                            </div>
                            {createData.size === "custom" ?
                                <div className='row mt-3'>
                                    <div className='col-md-6'>
                                        <div className="input-outer">
                                            <label className="pb-2" htmlFor="">Width</label>
                                            <input
                                                className="common-inp alt"
                                                type="number"
                                                name='w'
                                                placeholder="Enter Width"
                                                value={dim.w}
                                                onChange={(e) => setDim({
                                                    ...dim,
                                                    w: e.target.value
                                                })}
                                                min="321"
                                                max="2000"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="input-outer">
                                            <label className="pb-2" htmlFor="">Height</label>
                                            <input
                                                className="common-inp alt"
                                                type="number"
                                                name='h'
                                                placeholder="Enter Height"
                                                value={dim.h}
                                                onChange={(e) => setDim({
                                                    ...dim,
                                                    h: e.target.value
                                                })}
                                                min="321"
                                                max="2000"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null}

                            <div className="input-outer mt-3">
                                <label className="pb-2" htmlFor="">Enter URL </label>
                                <input
                                    className="common-inp alt"
                                    type="url"
                                    name='redirectUrl'
                                    placeholder="Enter URL"
                                    value={createData.redirectUrl}
                                    onChange={handleChange}
                                    required={createData.redirectUrl ? true : false}
                                />
                            </div>

                            {/* <div className="widget-elements-title mb-2">
                                <h6>AI QR</h6>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name='enable'
                                        checked={createData.aiQr.enable}
                                        onChange={handleAiChange}
                                    />
                                    <span className="slider"></span>
                                </label>
                            </div>
                            {createData.aiQr.enable ?
                                <div className="input-outer">
                                    <textarea
                                        className="common-inp alt"
                                        name="text"
                                        value={createData.aiQr.text}
                                        onChange={handleAiChange}
                                    />
                                </div> : null} */}
                        </>

                        : null}

                    <div className="input-outer mt-4">
                        <button type='submit' className="theme-btn full"><span>Next {loader ? <i className='fa fa-spin fa-spinner' /> : ""}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateModal