import React, { useState } from 'react'
import { BsEye, BsTrash3 } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import SweetAlert from '../../../CommonComponents/SweetAlert';
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions';
import { useSelector } from 'react-redux';

const ContactRow = ({ curElem, index, contactLeadData, setContactLeadData }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: curElem.id,
        }
        dispatch(onDeleteCampaign("delete-contact-email", data, contactLeadData, setContactLeadData, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }


    return (
        <tr>
            <td>{index + 1}</td>
            <td>{curElem.subject}</td>
            <td>{curElem.type}</td>
            <td>{curElem.email ? curElem.email : curElem.phone}</td>
            <td className="text-end">
                <span className="widg-icon">
                    {+auth.user.isClientAccount === 1 ? null :
                        <a onClick={deleteRow}><BsTrash3 /></a>
                    }
                </span>
            </td>

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br> <br> Do you want to delete this campaign?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />
        </tr>
    )
}

export default ContactRow
