import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/icomoon.css';
import './App.css';

import '../src/assets/css/dark-theme.css'
import '../src/assets/css/light-theme.css'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/Pages/Authentication/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Integration from './components/Pages/Integration/Integration';
import Templates from './components/Pages/Templates/Templates';
import Editor from './components/Pages/Editor/Editor';
import Help from './components/Pages/Help/Help';
import MyAccount from './components/Pages/Profile/MyAccount';
import Privacy from './components/Pages/Profile/Privacy';
import Upgrade from './components/Pages/Upgrade/Upgrade';
import ReelMerge from './components/Pages/ReelMerge/ReelMerge';
import ResetPassword from './components/Pages/Authentication/ResetPassword';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { removeAlert } from './Redux/Actions/AlertActions';
import { loadUser } from './Redux/Actions/AuthActions';
import { onFetchFont } from './Redux/Actions/CommonActions';
import ForgotPasword from './components/Pages/Authentication/ForgotPassword';
import MasterLogin from './components/Pages/Authentication/MasterLogin';
import AccountManagement from './components/Pages/AccountManagement/AccountManagement';
import Alert from './components/CommonComponents/Alert';
import PrivateRoute from './components/CommonComponents/PrivateRoute';

import HelpPost from './components/Pages/Help/HelpPost';
import SupportArticle from './components/Pages/Help/SupportArticle';
import CreateScript from './components/Pages/CreatePosts/Video/CreateScript';
import Settings from './components/Pages/Settings/outreach/Settings';
import WebsiteWidget from './components/Pages/GetReviews/WebsiteWidget/WebsiteWidget';
import WidgetStyle from './components/Pages/GetReviews/WebsiteWidget/WidgetStyle';
import OptinWidget from './components/Pages/GetReviews/OptinWidget/OptinWidget';
import OptionStyle from './components/Pages/GetReviews/OptinWidget/OptionStyle';
import QrCodeGenerator from './components/Pages/GetReviews/QrCodeGenerator/QrCodeGenerator';
import ChatBotFeedback from './components/Pages/GetReviews/ChatBotFeedback/ChatBotFeedback';
import ChatBotStyle from './components/Pages/GetReviews/ChatBotFeedback/ChatBotStyle';
import FindLeads from './components/Pages/Leads/FindLeads/FindLeads';
import ManageLeads from './components/Pages/Leads/ManageLeads/ManageLeads';
import AddLeads from './components/Pages/Leads/AddLeads/AddLeads';
import ContactLeads from './components/Pages/Leads/ContactLeads/ContactLeads';
import AiEmail from './components/Pages/Leads/AiEmail/AiEmail';
import MainReview from './components/Pages/GetReviews/MainReview';
import MainLead from './components/Pages/Leads/MainLead';
import MainPost from './components/Pages/CreatePosts/MainPost';
import Post from './components/Pages/CreatePosts/Post';
import Create from './components/Pages/CreatePosts/Create';
import ImageEditor from './components/Pages/ImageEditor/ImageEditor';
import Content from './components/Pages/CreatePosts/Content';
import CreateContactLead from './components/Pages/Leads/ContactLeads/CreateContactLead';
import CreateAIEmail from './components/Pages/Leads/AiEmail/CreateAIEmail';
import Schedule from './components/Pages/CreatePosts/Schedule';
import AutoPost from './components/Pages/CreatePosts/AutoPost/AutoPost';
import ReviewInbox from './components/Pages/ReviewInbox/Reviews/ReviewInbox';
import MainReviewInbox from './components/Pages/ReviewInbox/Reviews/MainReviewInbox';
import SavedReplies from './components/Pages/ReviewInbox/SavedReplies/SavedReplies';
import ReviewSettings from './components/Pages/ReviewInbox/Settings/ReviewSettings';
import CreateProof from './components/Pages/ReviewInbox/CreateProof/CreateProof';
import CreateVideo from './components/Pages/ReviewInbox/CreateVideo/CreateVideo';
import UpdateProfile from './components/Pages/ReviewInbox/UpdateProfile/UpdateProfile';
import AutoInnerPost from './components/Pages/CreatePosts/AutoPost/AutoInnerPost';

function App() {

  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  const fetchUser = () => {
    dispatch(loadUser())
  }

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])


  const fetchFonts = () => {
    dispatch(onFetchFont(false, true))
  }


  useEffect(() => {
    fetchUser()
    fetchFonts()
  }, [])

  return (
    <div className="App">
      <Alert />

      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/forgot-password" element={<ForgotPasword />} />
          <Route exact path="/master-login" element={<MasterLogin />} />

          <Route exact path="/dashboard" element={<PrivateRoute> <Dashboard /></PrivateRoute>} />
          <Route exact path="/integration" element={<PrivateRoute><Integration /></PrivateRoute>} />
          <Route exact path="/templates" element={<PrivateRoute><Templates /></PrivateRoute>} />
          <Route exact path="/help" element={<PrivateRoute><Help /></PrivateRoute>} />
          <Route exact path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
          <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
          <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
          <Route exact path="/upgrade" element={<PrivateRoute><Upgrade /></PrivateRoute>} />
          <Route exact path="/reel-merge" element={<PrivateRoute><ReelMerge /></PrivateRoute>} />
          <Route exact path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
          <Route exact path="/support-article" element={<PrivateRoute><SupportArticle /></PrivateRoute>} />
          <Route exact path="/help-post" element={<PrivateRoute><HelpPost /></PrivateRoute>} />


          <Route exact path="/review-inbox" element={<PrivateRoute><MainReview /></PrivateRoute>} >
            <Route index element={<MainReviewInbox />} />
            <Route path="review" element={<ReviewInbox />} />
            <Route path="saved-replies" element={<SavedReplies />} />
            <Route path="settings" element={<ReviewSettings />} />
            <Route path="create-proof" element={<CreateProof />} />
            <Route path="create-video" element={<CreateVideo />} />
            <Route path="update-profile" element={<UpdateProfile />} />
          </Route>


          <Route exact path="/post" element={<PrivateRoute><MainPost /></PrivateRoute>} >
            <Route index element={<Post />} />
            <Route path="create" element={<Create />} />
            <Route path="review-script" element={<CreateScript />} />
            <Route path="editor" element={<Editor />} />
            <Route path="image-editor" element={<ImageEditor />} />
            <Route path="content" element={<Content />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="auto-post" element={<AutoPost />} />
            <Route path="auto-inner-post" element={<AutoInnerPost />} />

          </Route>

          <Route exact path="/review" element={<PrivateRoute><MainReview /></PrivateRoute>} >
            <Route index element={<WebsiteWidget />} />
            <Route path="website-widget" element={<WebsiteWidget />} />
            <Route path='qr-code-generator' element={<QrCodeGenerator />} />
            <Route path="optin-widget" element={<OptinWidget />} />
            <Route path="feedback-widget" element={<ChatBotFeedback />} />
            <Route path="widget-style" element={<WidgetStyle />} />
            <Route path="image-editor" element={<ImageEditor />} />
            <Route path="optin-style" element={<OptionStyle />} />
            <Route path="chatbot-style" element={<ChatBotStyle />} />

          </Route>

          <Route exact path="/lead" element={<PrivateRoute><MainLead /></PrivateRoute>}>
            <Route index element={<FindLeads />} />
            <Route path="find-leads" element={<FindLeads />} />
            <Route path="manage-leads" element={<ManageLeads />} />
            <Route path="add-leads" element={<AddLeads />} />
            <Route path="contact-leads" element={<ContactLeads />} />
            <Route path="create-contact-leads" element={<CreateContactLead />} />
            <Route path="ai-email" element={<AiEmail />} />
            <Route path="create-ai-emails" element={<CreateAIEmail />} />
          </Route>

          <Route path="*" element={<Login />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
