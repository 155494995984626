import React, { useState } from 'react';

// import { onCreateImage } from '../../../Redux/Actions/ImageAction';
import { useDispatch } from 'react-redux';
import { fetchData, onFetchPlatform } from '../../../Redux/Actions/CommonActions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom/dist';
import queryString from "query-string"
import TitleBar from '../../CommonComponents/TitleBar';
import Platform from './Platform';
import { onCreate } from '../../../Redux/Actions/ImageAction';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const CreateImage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const type = queryString.parse(location.search).type
    const social = useSelector((state) => state.social)
    const [loader, setLoader] = useState(false)
    const [country, setCountry] = useState([])
    const [language, setLanguage] = useState([])
    const [platform, setPlatform] = useState(false)
    const [create, setCreate] = useState({
        name: "",
        keyword: "",
        noOfImages: "1",
        // imageType: "library",
        country: "United States",
        language: "English",
        integrationId: "",
        languageCode: "",
        destiantionURL: "",
        caption: "",
        days: "",
    })


    const handleChange = (e) => {
        let { name, value, checked } = e.target
        // if (name === "imageType") {
        //     value = checked ? "ai" : "library"
        // }
        if (name === "language") {
            let count = language.find(({ name }) => name === value)
            if (count) {
                if (type === "Video" && +count.polly_support === 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Warning...',
                        text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
                        confirmButtonColor: "#544fc4"
                    })
                }
                setCreate({
                    ...create,
                    language: value,
                    langCode: count.code
                })
            }
        } else if (name === "dimension") {
            if (type === "Video") {
                value = value === "1920x1080" ? "16x9" : "9x16"
            } else {
                name = "platform"
            }

            setCreate({
                ...create,
                [name]: value
            })
        } else {
            setCreate({
                ...create,
                [name]: value
            })
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            ...create,
            type: type,
        }
        let url
        if (type === "Video") {
            delete data.destiantionURL
            delete data.caption
            delete data.days
            delete data.frequency
            url = "create-video"
        } else if (type === "Auto Post") {
            url = "create-auto-post"
            data.platform = 1

        } else {
            delete data.days
            url = "create-image"
        }

        let count = country.find(({ country_name }) => country_name === create.country)
        let lang = language.find(({ name }) => name === create.language)
        data.languageCode = `${lang.code}-${count.code}`
        dispatch(onCreate(url, data, type, navigate, setLoader))
    }

    const fetchImgPlatform = () => {
        if (type === "Video") {
            dispatch(onFetchPlatform("fetch-platform", {}, setPlatform))
        } else {
            dispatch(onFetchPlatform("fetch-image-platform", {}, setPlatform))
        }

    }
    const fetchLanguage = () => {
        dispatch(fetchData("fetch-google-language", {}, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchCountries = () => {
        dispatch(fetchData("countries", {}, setCountry, false, false, false, "shortDataCt"))
    }

    useEffect(() => {
        fetchImgPlatform()
        fetchLanguage()
        fetchCountries()
    }, [])

    return (
        <>
            <TitleBar title={`Create ${type}`} />

            <section className='site-wrap text-dark'>
                <div className='container'>
                    <h4 className=' text-light text-capitalize'>Create <span className='text-lowercase'>{type}</span></h4>

                    <form className='cont-box' onSubmit={handleSubmit}>
                        <div className='input-outer mb-0'>
                            <label htmlFor="" className='mb-2'>Campaign Name</label>
                            <input
                                className='common-inp alt'
                                type="text"
                                name='name'
                                value={create.name}
                                placeholder='Add Campaign Name'
                                onChange={handleChange}
                                required
                            />
                        </div>


                        <div className='input-outer mt-4'>
                            {type === "Auto Post" ?
                                <label htmlFor="" className='mb-2'>Give keyword on which you want the post to be </label>
                                :
                                <label htmlFor="" className='mb-2'>Shortly describe what the <span className='text-lowercase'>{type}</span> is about.</label>
                            }

                            <input
                                className='common-inp alt'
                                type="text"
                                value={create.keyword}
                                name='keyword'
                                placeholder={type === "Auto Post" ? "Enter Keyword" : `Describe The ${type}`}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='input-outer mt-4'>
                                    <label htmlFor="" className='mb-2'>Country</label>
                                    <select
                                        className='common-inp alt'
                                        type="text"
                                        value={create.country}
                                        name='country'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={""}>Select Country</option>
                                        {country.length > 0 ?
                                            country.map((curElem, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={curElem.country_name}
                                                    >
                                                        {curElem.country_name}
                                                    </option>
                                                )
                                            })
                                            : ""}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='input-outer mt-4'>
                                    <label htmlFor="" className='mb-2'>Languages</label>
                                    <select
                                        className='common-inp alt'
                                        type="text"
                                        value={create.language}
                                        name='language'
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value={""}>Select Language</option>
                                        {language.length > 0 ?
                                            language.filter((lang) => {
                                                return +lang.id !== 40
                                            }).map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.name}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                    </select>

                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            {type !== "Video" ?
                                ""
                                :
                                <div className='col-sm-6'>
                                    <div className='input-outer mt-4'>
                                        <label htmlFor="" className='mb-2'>Style</label>
                                        <select
                                            className='common-inp alt'
                                            name="style"
                                            onChange={handleChange}
                                            value={create.style}
                                            required
                                        >
                                            <option value={""}>Select Style</option>
                                            <option value={"VSL"}>VSL</option>
                                            <option value={"engaging"}>Engaging</option>
                                            <option value={"promo"}>Promo</option>
                                            <option value={"informative"}>Informative</option>
                                            <option value={"fun"}>Fun</option>
                                            <option value={"news"}>News</option>
                                            <option value={"controversial"}>Controversial</option>
                                            <option value={"Product review"}>Product review</option>
                                        </select>

                                    </div>
                                </div>

                            }
                            {type === "Auto Post" ?
                                <>
                                    <div className='col-sm-6'>
                                        <div className='input-outer mt-4'>
                                            <label htmlFor="" className='mb-2'>No. of Posts</label>
                                            <input
                                                className='common-inp alt'
                                                type="number"
                                                name='noOfImages'
                                                value={create.noOfImages}
                                                placeholder='No. of Posts'
                                                onChange={handleChange}
                                                min="1"
                                                max="10"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='input-outer mt-4'>
                                            <label htmlFor="" className='mb-2'>Accounts</label>
                                            <select
                                                className='common-inp alt'
                                                type="text"
                                                name='integrationId'
                                                value={create.integrationId}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value={""}>Select Accounts</option>
                                                {social.google.length > 0 ?
                                                    social.google.map((curElem, index) => {
                                                        return (
                                                            <option key={index} value={curElem.id}>{curElem.username}-Google</option>
                                                        )
                                                    })
                                                    : ""}
                                                {social.facebook.length > 0 ?
                                                    social.facebook.map((curElem, index) => {
                                                        return (
                                                            <option key={index} value={curElem.id}>{curElem.username}-Facebook</option>
                                                        )
                                                    })
                                                    : ""}
                                            </select>

                                        </div>
                                    </div>
                                </>
                                : null}
                            {/* <div className='col-sm-6'>
                                <div className='input-outer mt-4'>
                                    <label htmlFor="" className='mb-2'>Platform</label>
                                    <select
                                        name="dimension"
                                        className="common-inp alt"
                                        onChange={handleChange}
                                        required
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        <option value="">Select Platform</option>
                                        {platform ?
                                            <>
                                                <Platform
                                                    type={type}
                                                    data={platform.Facebook}
                                                />
                                                <Platform
                                                    type={type}
                                                    data={platform.Instagram}
                                                />
                                                <Platform
                                                    type={type}
                                                    data={platform.LinkedIn}
                                                />
                                                <Platform
                                                    type={type}
                                                    data={platform.Pinterest}
                                                />
                                                <Platform
                                                    type={type}
                                                    data={platform.Twitter}
                                                />

                                            </>
                                            : ""}
                                    </select>
                                </div>
                            </div> */}
                        </div>
                        {type === "Auto Post" ?
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='input-outer mt-4'>
                                        <label htmlFor="" className='mb-2'>Frequency</label>
                                        <select
                                            className='common-inp alt'
                                            name="frequency"
                                            onChange={handleChange}
                                            value={create.frequency}
                                            required
                                        >
                                            <option value={""}>Select Frequency</option>
                                            <option value={"everyday"}>Everyday</option>
                                            <option value={"days"}>Days #</option>
                                        </select>
                                    </div>
                                </div>
                                {create.frequency === "days" ?
                                    <div className='col-sm-6'>
                                        <div className='input-outer mt-4'>
                                            <label htmlFor="" className='mb-2'>Enter No. of Days</label>
                                            <input
                                                className='common-inp alt'
                                                type="number"
                                                name='days'
                                                min={"1"}
                                                value={create.days}
                                                placeholder='Enter Days'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div> : null}
                            </div> : null}

                        <div className='client-btn'>
                            <button className='theme-btn ms-0' type='submit' disabled={loader}><span>{loader ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /></> : `Create ${type}`}</span></button>
                        </div>
                    </form>

                </div>
            </section>
        </>
    )

}

export default CreateImage;