import React, { useEffect } from 'react'
import { FiChevronDown, FiUpload, FiEdit3, FiTrash2 } from 'react-icons/fi'
import { MdPublish, MdSchedule } from 'react-icons/md'
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useState } from 'react';
import SweetAlert from './SweetAlert';
import { useDispatch } from 'react-redux';
import { onDeleteCampaign, onImageStatusCheck } from '../../Redux/Actions/CommonActions';
import { useSelector } from 'react-redux';
import dummyImg from "../../assets/images/thumb_16x9.png"
import ScheduleModal from './ScheduleModal';
import { FaDownload } from 'react-icons/fa';
import PublishModal from '../Pages/CreatePosts/PublishModal';

const ImageCard = ({ curElem, image, setImage }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const [common, setCommon] = useState(curElem)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [show1, setShow1] = useState(false)
    const handleShow1 = () => setShow1(true)
    const handleClose1 = () => setShow1(false)

    const onDelete = (tId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: tId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })

        let url = "delete-image-campaign"
        dispatch(onDeleteCampaign(url, data, image, setImage, setSweet))
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.download = 'download.png';
        link.href = curElem.url
        link.target = "_blank"
        link.click();
    }

    const checkStatus = (interval) => {
        let data = {
            id: curElem.cId
        }
        dispatch(onImageStatusCheck(data, setCommon, interval))
    }

    useEffect(() => {
        if (curElem.status === "2") {
            const interval = setInterval(() => {
                checkStatus(interval)
            }, 5000)
        }
        setCommon(curElem)
    }, [curElem])

    return (
        <div className='col-sm-4'>
            <div className='video-listing-single'>
                <div className='video-listing-img' style={{ position: "relative" }}>
                    <img alt='' src={common.thumbnail ? common.thumbnail : dummyImg} />
                    {+common.status === 2 ?
                        <div className="Video_Project_Render" style={{ position: "absolute", bottom: 0 }}>
                            <div className="progress" style={{ height: 22, background: "gray", position: "relative", textAlign: "center" }}>
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                                    title="Generating" style={{ width: `${100}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                    <span className="progress-bar-text">Generating...</span>
                                </div>
                            </div>
                        </div> : null}
                </div>

                <div className='video-project-bottom'>
                    <div className='video-listing-info'>
                        <h3 className='text-capitalize'>{common.name}</h3>
                        <p>{common.created}</p>
                    </div>
                    <div className='drop-style-1'>
                        <Dropdown>
                            <Dropdown.Toggle variant="hh" id="dropdown-basic">
                                <FiChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    {common.data ?
                                        <>
                                            <li style={{ cursor: "pointer" }}> <a onClick={handleDownload}><span><FaDownload /></span> Download</a></li>
                                            {+auth.user.isClientAccount === 1 ? "" :
                                                <>
                                                    <li style={{ cursor: "pointer" }}><a onClick={handleShow1}><MdPublish /> Publish</a></li>
                                                    <li style={{ cursor: "pointer" }}> <a onClick={handleShow}><span><MdSchedule /></span> Schedule</a></li>
                                                </>
                                            }
                                        </>
                                        : ""}
                                    {+auth.user.isClientAccount === 1 ? "" :
                                        <>
                                            {+common.status === 1 ?
                                                <li><Link to={`/post/image-editor?id=${common.cId}&type=${common.type}`}><span><FiEdit3 /> </span> Edit</Link></li> : null}
                                            <li style={{ cursor: "pointer" }} className='cursor-pointer' onClick={() => onDelete(common.id)}>
                                                <a><span><FiTrash2 /></span> Delete</a>
                                            </li>
                                        </>}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <ScheduleModal
                show={show}
                handleClose={handleClose}
                id={common.id}
                type={"image"}
            />

            <PublishModal
                curElem={common}
                show={show1}
                handleClose={handleClose1}
                type={"image"}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure? <br> <br> You want to delete this campaign."}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div >
    )
}

export default ImageCard