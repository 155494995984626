import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { BsThreeDots, BsSearch, BsTrash3 } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const GetReviewHeader = (props) => {
    const auth = useSelector(state => state.auth)
    return (
        <div className="widget-top">
            <div className="widget-top-left">
                <h6>{props.name}</h6>
                {+auth.user.isClientAccount === 1 ? null :
                    <button className="theme-btn" onClick={props.handleShow}><span>+ Create new widget</span></button>
                }
                <div className="searchBar">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => props.setQ(e.target.value)}
                    />
                    <span><BsSearch /></span>
                </div>
            </div>
            {+auth.user.isClientAccount === 1 ? null :
                <div className="widget-top-right">
                    <Dropdown className="theme-toggle">
                        <Dropdown.Toggle variant="theme-btn" className="theme-btn" id="dropdown-basic"><span><BsThreeDots /></span></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <li><Link onClick={props.handleDeleteAll}><BsTrash3 /> Delete</Link></li>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            }
        </div>
    )
}

export default GetReviewHeader