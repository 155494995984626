import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateWidget = (url, data, navigate, redirect, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`${redirect}?id=${res.data ? res.data : data.id}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchWidget = (data, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-website", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_WIDGET_DATA", payload: res.data[0] })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onSubmitWidget = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("save-website", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate(`/review/website-widget`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.message, "danger"))
        })
}


export const onFetchOptinWidget = (data, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-optin", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_OPTIN_WIDGET_DATA", payload: res.data[0] })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onSubmitOptin = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("save-optin", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate(`/review/optin-widget`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onCreateFeedback = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/review/feedback-widget`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchFeedback = (data, setData,setIsActiveDefault, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-feedback", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data[0])
                setIsActiveDefault(false)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchQr = (data, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-qr", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_QR_DATA", payload: res.data[0] })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.message, "danger"))
        })
}


// export const onGenerateQrCode = (ur, obj, setObj, setLoader) => (dispatch, getState) => {
//     commonAxios("generate-qr", data, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 setObj({
//                     ...obj,
//                     url: "",
//                     image: res.data
//                 })
//                 dispatch({ type: "ADD_QR_URL", payload: data.url })
//             } else {
//                 dispatch(setAlert(res.msg, "danger"))
//             }
//             setLoader(false)
//         }).catch((err) => {
//             console.log(err)
//             setLoader(false)
//             dispatch(setAlert(err.message, "danger"))
//         })
// }

export const onCreateQrImage = (obj, setImgUrl) => (dispatch, getState) => {
    commonAxios("save-base", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let url = res.data
                setImgUrl(url)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onSaveCanvas = (newData, navigate, setCanvasLoader, setSave) => (dispatch, getState) => {
    setSave(false)
    commonAxios("create-qr-code", newData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/review/qr-code-generator`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setCanvasLoader(false)
        }).catch((err) => {
            console.log(err)
            setCanvasLoader(false)
        })
}

export const onGenerateDefaultMessage = (data, state, setState, setIsActiveDefault, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    preQuestion: res.data
                })
                setIsActiveDefault(false)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                messageLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                messageLoader: false
            })
        })
}


// No Axios Here

export const onToggleCheck = (name, value) => (dispatch) => {
    dispatch({ type: "ON_WIDGET_TOGGLE", payload: { name, value } })
}
export const onCheckSocialStatus = (name, value) => (dispatch) => {
    dispatch({ type: "ON_WIDGET_SOCIAL_TOGGLE", payload: { name, value } })
}

export const onChangeText = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_WIDGET_TEXT", payload: { name, value } })
}

export const onChange = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_WIDGET", payload: { name, value } })
}

export const onCheckSocialData = (name, innerVar, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_SOCIAL_DATA", payload: { name, innerVar, value } })
}

export const onChangeBorderColor = (val) => (dispatch) => {
    dispatch({ type: "ON_WIDGET_BORDER", payload: val })
}

export const onChangeBackgroundColor = (val) => (dispatch) => {
    let { name, value, checked } = val.target
    if (name === "type") {
        dispatch({ type: "ON_WIDGET_BACKGROUND_TYPE", payload: checked })
    } else {
        dispatch({ type: "ON_WIDGET_BACKGROUND_SRC", payload: value })
    }

}

export const onChangeWidgetImage = (url) => (dispatch) => {
    dispatch({ type: "ON_WIDGET_IMAGE", payload: url })
}

export const onActiveStyle = (type) => (dispatch) => {
    dispatch({ type: "ON_ACTIVE_STYLE", payload: type })
}

export const onDeactiveStyle = (type) => (dispatch) => {
    if (type === "website") {
        dispatch({ type: "ON_HIDE_WEBSITE_STYLE" })
    } else {
        dispatch({ type: "ON_HIDE_OPTIN_STYLE" })
    }
}

export const onChangeStyle = (objName, name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_WIDGET_STYLE", payload: { objName, name, value } })
}

export const onChangeSocialStyle = (objName, name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_WIDGET_SOCIALS_STYLE", payload: { objName, name, value } })
}

export const removeWebsiteWidget = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_WEBSITE" })
}

//QR REDUCER

export const onGenerateQrCode = (url, setLoader) => (dispatch) => {

    dispatch({ type: "ADD_QR_URL", payload: url })
    setLoader(false)
}

export const onUnmountQR = () => (dispatch) => {
    dispatch({ type: "UNMOUTN_QR" })
}

// OPTIN REDUCER

export const onOptinChange = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN", payload: { name, value } })
}
//----
export const onOptinToggleCheck = (name, value) => (dispatch) => {
    dispatch({ type: "ON_OPTIN_WIDGET_TOGGLE", payload: { name, value } })
}

export const onOptinChangeText = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_WIDGET_TEXT", payload: { name, value } })
}


export const onChangeOptinBorderColor = (val) => (dispatch) => {
    dispatch({ type: "ON_OPTIN_WIDGET_BORDER", payload: val })
}

export const onChangeOptinBackgroundColor = (val) => (dispatch) => {
    let { name, value, checked } = val.target
    if (name === "type") {
        dispatch({ type: "ON_OPTIN_WIDGET_BACKGROUND_TYPE", payload: checked })
    } else {
        dispatch({ type: "ON_OPTIN_WIDGET_BACKGROUND_SRC", payload: value })
    }

}

export const onChangeOptinWidgetImage = (url) => (dispatch) => {
    dispatch({ type: "ON_OPTIN_WIDGET_IMAGE", payload: url })
}
export const onChangeOptinStyle = (objName, name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_WIDGET_STYLE", payload: { objName, name, value } })
}

export const onActiveOptinStyle = (type) => (dispatch) => {
    dispatch({ type: "ON_ACTIVE_OPTIN_STYLE", payload: type })
}

export const removeOptinWidget = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_OPTIN" })
}



//Image Editor Stuff