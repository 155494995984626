import produce from "immer"
const initialState = {
    data: false,
    optinData: false,
    qrData: false
}

export const WidgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_WIDGET_DATA":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                obj.activeStyle = ""
                if (!obj.data) {

                    obj.data = {
                        headline: {
                            text: "Would you recommend us?",
                            enable: true,
                            css: {
                                display: "block",
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                textTransform: "",
                                color: "#383838",
                                fontWeight: "bold",
                                fontStyle: "",
                                textDecoration: "",
                                textAlign: "center"
                            }
                        },
                        description: {
                            text: "Your feedback helps us improve! If you have a second, please leave a review on any of the platforms below. It only takes 30 seconds. Thank you!.",
                            enable: true,
                            css: {
                                display: "block",
                                fontFamily: "Poppins",
                                fontSize: "13px",
                                textTransform: "",
                                color: "#383838",
                                fontWeight: "",
                                fontStyle: "",
                                textDecoration: "",
                                textAlign: "center"
                            }
                        },
                        socials: {
                            google: {
                                enable: true,
                                text: "Review us on Google",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            bing: {
                                enable: true,
                                text: "Review us on Bing",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            angi: {
                                enable: false,
                                text: "Review us on Angi",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            zillow: {
                                enable: false,
                                text: "Review us on Zillow",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            foursquare: {
                                enable: false,
                                text: "Review us on Foursquare",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            trustpilot: {
                                enable: false,
                                text: "Review us on Trustpilot",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            tripadvisor: {
                                enable: false,
                                text: "Review us on Tripadvisor",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            shopify: {
                                enable: false,
                                text: "Review us on Shopify",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            facebook: {
                                enable: true,
                                text: "Review us on Facebook",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },
                            yelp: {
                                enable: false,
                                text: "Review us on Yelp",
                                btnUrl: "",
                                icon: true,
                                css: {
                                    fontFamily: "Poppins",
                                    fontSize: "13px",
                                    textTransform: "",
                                    color: "#282828",
                                    background: "#d4e4fd",
                                    fontWeight: "",
                                    fontStyle: "",
                                    textDecoration: "",
                                    justifyContent: "left"
                                }
                            },

                        },
                        background: {
                            type: "color",
                            src: "#ffffff"
                        },
                        border: {
                            enable: false,
                            color: "#ffffff"
                        },
                        animation: "",
                        image: {
                            enable: false,
                            url: ""
                        },
                        position: ""
                    }
                }

                draft.data = obj
            })


        case "UNMOUNT_WEBSITE":
            return produce(state, (draft) => {
                draft.data = false
            })

        case "ON_WIDGET_TOGGLE":
            return produce(state, (draft) => {
                draft.data.data[action.payload.name].enable = action.payload.value
            })

        case "ON_WIDGET_SOCIAL_TOGGLE":
            return produce(state, (draft) => {
                draft.data.data.socials[action.payload.name].enable = action.payload.value
            })



        case "ON_CHANGE_WIDGET_TEXT":
            return produce(state, (draft) => {
                draft.data.data[action.payload.name].text = action.payload.value
            })

        case "ON_CHANGE_WIDGET":
            return produce(state, (draft) => {
                draft.data.data[action.payload.name] = action.payload.value
            })

        case "ON_CHANGE_SOCIAL_DATA":
            return produce(state, (draft) => {
                draft.data.data.socials[action.payload.name][action.payload.innerVar] = action.payload.value
            })

        case "ON_WIDGET_BORDER":
            return produce(state, (draft) => {
                draft.data.data.border.color = action.payload
            })

        case "ON_WIDGET_BACKGROUND_TYPE":
            return produce(state, (draft) => {
                draft.data.data.background.type = action.payload ? "color" : "image"
                draft.data.data.background.src = action.payload ? "#ffffff" : ""
            })

        case "ON_WIDGET_BACKGROUND_SRC":
            return produce(state, (draft) => {
                draft.data.data.background.src = action.payload
            })

        case "ON_WIDGET_IMAGE":
            return produce(state, (draft) => {
                draft.data.data.image.url = action.payload
            })

        case "ON_ACTIVE_STYLE":
            return produce(state, (draft) => {
                draft.data.activeStyle = action.payload
            })

        case "ON_HIDE_WEBSITE_STYLE":
            return produce(state, (draft) => {
                draft.data.activeStyle = ""
            })


        case "ON_CHANGE_WIDGET_STYLE":
            return produce(state, (draft) => {
                draft.data.data[action.payload.objName].css[action.payload.name] = action.payload.value
            })
        case "ON_CHANGE_WIDGET_SOCIALS_STYLE":
            return produce(state, (draft) => {
                draft.data.data.socials[action.payload.objName].css[action.payload.name] = action.payload.value
            })


        // OPTIN REDUCER

        case "SET_OPTIN_WIDGET_DATA":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                obj.activeStyle = ""
                if (!obj.data) {

                    obj.data = {
                        headline: {
                            text: "Would you recommend us?",
                            enable: true,
                            css: {
                                display: "block",
                                fontFamily: "",
                                fontSize: "20px",
                                textTransform: "",
                                color: "#383838",
                                fontWeight: "bold",
                                fontStyle: "",
                                textDecoration: "",
                                textAlign: "center"
                            }
                        },
                        description: {
                            text: "Your feedback helps us improve! If you have a second, please leave a review on any of the platforms below. It only takes 30 seconds. Thank you!.",
                            enable: true,
                            css: {
                                display: "block",
                                fontFamily: "",
                                fontSize: "13px",
                                textTransform: "",
                                color: "#a5a5a5",
                                fontWeight: "",
                                fontStyle: "",
                                textDecoration: "",
                                textAlign: "center"
                            }
                        },
                        name: {
                            text: "",
                            enable: true,
                            css: {
                                display: "block",
                                fontFamily: "",
                                fontSize: "13px",
                                textTransform: "",
                                color: "#383838",
                                fontWeight: "",
                                fontStyle: "",
                                textDecoration: "",
                                textAlign: "left"
                            }
                        },
                        email: {
                            text: "",
                            enable: true,
                            css: {
                                display: "block",
                                fontFamily: "",
                                fontSize: "13px",
                                textTransform: "",
                                color: "#383838",
                                fontWeight: "",
                                fontStyle: "",
                                textDecoration: "",
                                textAlign: "left"
                            }
                        },
                        phone: {
                            text: "",
                            enable: true,
                            css: {
                                display: "block",
                                fontFamily: "",
                                fontSize: "13px",
                                textTransform: "",
                                color: "#383838",
                                fontWeight: "",
                                fontStyle: "",
                                textDecoration: "",
                                textAlign: "left"
                            }
                        },
                        review: {
                            text: "",
                            enable: true,
                            css: {
                                display: "block",
                                fontFamily: "",
                                fontSize: "13px",
                                textTransform: "",
                                color: "#383838",
                                fontWeight: "",
                                fontStyle: "",
                                textDecoration: "",
                                textAlign: "left"
                            }
                        },
                        background: {
                            type: "color",
                            src: "#ffffff"
                        },
                        border: {
                            enable: false,
                            color: "#ffffff"
                        },
                        animation: "",
                        image: {
                            enable: false,
                            url: ""
                        },
                        position: "",
                        autoresponderId: ""
                    }
                }

                draft.optinData = obj
            })


        case "UNMOUNT_OPTIN":
            return produce(state, (draft) => {
                draft.optinData = false
            })
        case "ON_CHANGE_OPTIN":
            return produce(state, (draft) => {
                draft.optinData.data[action.payload.name] = action.payload.value
            })

        case "ON_OPTIN_WIDGET_TOGGLE":
            return produce(state, (draft) => {
                draft.optinData.data[action.payload.name].enable = action.payload.value
            })

        case "ON_CHANGE_OPTIN_WIDGET_TEXT":
            return produce(state, (draft) => {
                draft.optinData.data[action.payload.name].text = action.payload.value
            })

        case "ON_OPTIN_WIDGET_BORDER":
            return produce(state, (draft) => {
                draft.optinData.data.border.color = action.payload
            })

        case "ON_OPTIN_WIDGET_BACKGROUND_TYPE":
            return produce(state, (draft) => {
                draft.optinData.data.background.type = action.payload ? "color" : "image"
                draft.optinData.data.background.src = action.payload ? "#ffffff" : ""
            })

        case "ON_OPTIN_WIDGET_BACKGROUND_SRC":
            return produce(state, (draft) => {
                draft.optinData.data.background.src = action.payload
            })

        case "ON_OPTIN_WIDGET_IMAGE":
            return produce(state, (draft) => {
                draft.optinData.data.image.url = action.payload
            })

        case "ON_CHANGE_OPTIN_WIDGET_STYLE":
            return produce(state, (draft) => {
                draft.optinData.data[action.payload.objName].css[action.payload.name] = action.payload.value
            })

        case "ON_ACTIVE_OPTIN_STYLE":
            return produce(state, (draft) => {
                draft.optinData.activeStyle = action.payload
            })

        case "ON_HIDE_OPTIN_STYLE":
            return produce(state, (draft) => {
                draft.optinData.activeStyle = ""
            })



        //QR Stuff

        case "ADD_QR_DATA":
            return produce(state, (draft) => {
                let data = { ...action.payload }
                let qrObj = {
                    "angle": 0,
                    "backgroundColor": "",
                    "cropX": 0,
                    "cropY": 0,
                    "crossOrigin": "anonymous",
                    "fill": "rgb(0,0,0)",
                    "fillRule": "nonzero",
                    "filters": [],
                    "flipX": false,
                    "flipY": false,
                    "globalCompositeOperation": "source-over",
                    "height": 500,
                    "left": 28.75,
                    "opacity": 1,
                    "originX": "left",
                    "originY": "top",
                    "paintFirst": "fill",
                    "scaleX": 0.25,
                    "scaleY": 0.25,
                    "shadow": null,
                    "skewX": 0,
                    "skewY": 0,
                    "src": `https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl=${data.redirectUrl}&chld=H|0`,
                    "stroke": null,
                    "strokeDashArray": null,
                    "strokeDashOffset": 0,
                    "strokeLineCap": "butt",
                    "strokeLineJoin": "miter",
                    "strokeMiterLimit": 4,
                    "strokeUniform": false,
                    "strokeWidth": 0,
                    "top": 35.25,
                    "type": "image",
                    "version": "5.3.0",
                    "visible": true,
                    "width": 500
                }
                if (!data.data) {
                    data.data = {
                        "version": "5.3.0",
                        "objects": data.redirectUrl ? [qrObj] : [],
                        "backgroundColor": "#ffffff"
                    }
                } else if (data.redirectUrl) {
                    let newData = JSON.parse(data.data)
                    let index = newData.objects.findIndex(({ src }) => src.includes("https://chart.googleapis.com/"))
                    if (index !== -1) {
                        newData.objects[index].src =  `https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl=${data.redirectUrl}&chld=H|0`
                    } else {
                        newData.objects.push(qrObj)
                    }
                    data.data = newData
                }
                draft.qrData = data
            })

        case "UPDATE_QR_CANVAS_IMAGE":
            return produce(state, (draft) => {
                draft.qrData.imageUrl = action.payload.url
                draft.qrData.data = action.payload.fabricObj
            })

        case "ADD_QR_URL":
            return produce(state, (draft) => {
                draft.qrData.redirectUrl = action.payload
            })

        case "UNMOUTN_QR":
            return produce(state, (draft) => {
                draft.qrData = false
            })
        default:
            return state
    }
}