import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TitleBar from "../../../CommonComponents/TitleBar";
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import { BsSearch, BsTrash3, BsFunnel, BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { onDeleteLeadFolder, onFetchLeadFolder } from "../../../../Redux/Actions/LeadAction";
import ManageLeadCard from "./ManageLeadCard";
import ManageLeadModal from "./ManageLeadModal";
import SweetAlert from "../../../CommonComponents/SweetAlert";
import Swal from "sweetalert2";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { themeColor } from "../../../../Global/Global";
import { MdOutlineDriveFileMoveRtl } from "react-icons/md";


const ManageLeads = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const leadFolderData = useSelector((state) => state.lead.data)
    const loader = useSelector((state) => state.lead.loader)
    const [showType, setShowType] = useState("createFolder");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [q, setQ] = useState("")
    const [mainCheckedArr, setMainCheckedArr] = useImmer([])

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const [filterData, setFilterData] = useState({
        contacted: false,
        noRespond: false,
        pendingRespond: false,
        dealSecured: false,
        claimed: false,
        unClaimed: false
    });

    const handleCreateFolder = () => {
        setShowType("createFolder");
        setShow(true)
    }

    const handleMoveAll = () => {
        let checkedArr = mainCheckedArr.flat()
        if (checkedArr.length > 0) {
            setShowType("moveFolder");
            setShow(true)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select lead to move them.',
                confirmButtonColor: themeColor
            })
        }
    }

    const handleDeleteAll = () => {
        let checkedArr = mainCheckedArr.flat()
        if (checkedArr.length > 0) {
            setSweet({
                ...sweet,
                enable: true,
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select lead to delete them.',
                confirmButtonColor: themeColor
            })
        }
    }

    const handleChange = (e) => {
        const { name, checked } = e.target
        setFilterData({ ...filterData, [name]: checked })
    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: mainCheckedArr.flat(),
            type: "all"
        }
        dispatch(onDeleteLeadFolder("delete-leads", data, fetchAllFolder, false, setSweet, mainCheckedArr, setMainCheckedArr))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const fetchAllFolder = () => {
        dispatch(onFetchLeadFolder())
    }

    useEffect(() => {
        setMainCheckedArr([])
    }, [q, filterData])

    return (
        <>
            <TitleBar title="Manage Leads" />

            <div className="site-wrap">
                <div className="site-container">
                    <div className="widget-top">
                        <div className="widget-top-left">
                            <h6>Leads</h6>
                            {+auth.user.isClientAccount === 1 ? null :
                                <button className="theme-btn" onClick={handleCreateFolder}><span>+ Create new folder</span></button>
                            }
                            <div className="searchBar">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => setQ(e.target.value)}
                                />
                                <span><BsSearch /></span>
                            </div>
                        </div>
                        <div className="widget-top-right">
                            <Dropdown className="theme-toggle">
                                <Dropdown.Toggle variant="theme-btn" className="theme-btn" id="dropdown-basic"><span><BsFunnel /> Filter</span></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <li className="filter-opt justify-content-center" style={{ background: "linear-gradient(90deg, #21c16b 0.08%, #52f195 101.7%)" }}>
                                        <p>Status</p>
                                    </li>
                                    <li className="filter-opt">
                                        <label className="table-check">
                                            <input type="checkbox" name="contacted" onChange={(e) => handleChange(e)} />
                                            <span className="check-mark"></span>
                                        </label>
                                        Contacted
                                    </li>
                                    <li className="filter-opt">
                                        <label className="table-check">
                                            <input type="checkbox" name="noRespond" onChange={(e) => handleChange(e)} />
                                            <span className="check-mark"></span>
                                        </label>
                                        No Respond
                                    </li>
                                    <li className="filter-opt">
                                        <label className="table-check">
                                            <input type="checkbox" name="pendingRespond" onChange={(e) => handleChange(e)} />
                                            <span className="check-mark"></span>
                                        </label>
                                        Pending Respond
                                    </li>
                                    <li className="filter-opt">
                                        <label className="table-check">
                                            <input type="checkbox" name="dealSecured" onChange={(e) => handleChange(e)} />
                                            <span className="check-mark"></span>
                                        </label>
                                        Deal Secured
                                    </li>
                                    {/* <li className="filter-opt justify-content-center" style={{ background: "linear-gradient(90deg, #21c16b 0.08%, #52f195 101.7%)" }}>
                                        <p>GMB Listing</p>
                                    </li>
                                    <li className="filter-opt">
                                        <label className="table-check">
                                            <input type="checkbox" name="claimed" onChange={(e) => handleChange(e)} />
                                            <span className="check-mark"></span>
                                        </label>
                                        Claimed
                                    </li>
                                    <li className="filter-opt">
                                        <label className="table-check">
                                            <input type="checkbox" name="unClaimed" onChange={(e) => handleChange(e)} />
                                            <span className="check-mark"></span>
                                        </label>
                                        Unclaimed
                                    </li> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            {+auth.user.isClientAccount === 1 ? null :
                                <div className="widget-top-right">
                                    <Dropdown className="theme-toggle">
                                        <Dropdown.Toggle variant="theme-btn" className="theme-btn" id="dropdown-basic"><span><BsThreeDots /></span></Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <li><Link onClick={handleDeleteAll}><BsTrash3 /> Delete</Link></li>
                                            <li><Link onClick={handleMoveAll}><MdOutlineDriveFileMoveRtl /> Move</Link></li>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="table-area mt-5">

                        <div className="folder-accordion">
                            <Accordion>
                                {leadFolderData.length > 0 ?
                                    leadFolderData.filter((cur) => {
                                        return cur.folderName.toLowerCase().includes(q.toLowerCase()) || q === ""
                                    }).length > 0 ?
                                        leadFolderData.filter((cur) => {
                                            return cur.folderName.toLowerCase().includes(q.toLowerCase()) || q === ""
                                        }).map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <ManageLeadCard
                                                        curElem={curElem}
                                                        index={index}
                                                        mainCheckedArr={mainCheckedArr[index] ? mainCheckedArr[index] : []}
                                                        setMainCheckedArr={setMainCheckedArr}
                                                        fetchAllFolder={fetchAllFolder}
                                                        filterData={filterData}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <div className="text-center">
                                            {loader ? <i className="fa fa-spin fa-spinner spin-style" /> : "No folder found."}
                                        </div>
                                    : <div className="text-center">
                                        {loader ? <i className="fa fa-spin fa-spinner spin-style" /> : "No folder created yet."}
                                    </div>
                                }
                            </Accordion>
                        </div>


                    </div>
                </div>
            </div>

            <ManageLeadModal
                curElem={mainCheckedArr.flat()}
                type={showType === "moveFolder" ? "moveFolder" : "createFolder"}
                show={show}
                handleClose={handleClose}
                fetchData={fetchAllFolder}
                mainCheckedArr={mainCheckedArr}
                setMainCheckedArr={setMainCheckedArr}
            />

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br><br> Do you want to delete them all?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />
        </>
    )
}

export default ManageLeads;