import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TitleBar from "../../CommonComponents/TitleBar";
import { fetchData } from '../../../Redux/Actions/CommonActions';
import ImageCard from '../../CommonComponents/ImageCard';
import VideoCard from '../../CommonComponents/VideoCard';

const CreatePost = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [images, setImages] = useState([])
    const [posts, setPosts] = useState([])
    const [videos, setVideos] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })


    const fetchImages = () => {
        let data = {}
        dispatch(fetchData("fetch-image-campaign", data, setData))
    }

    const fetchVideos = () => {
        let data = {}
        dispatch(fetchData("fetch-video-all", data, setVideos, loader, setLoader))
    }

    useEffect(() => {
        setLoader({
            ...loader,
            fetch: true
        })
        fetchImages()
        fetchVideos()
    }, [])

    useEffect(() => {
        if (data.length > 0) {
            setImages(data.filter((curElem) => {
                return curElem.type.toLowerCase() === "image"
            }))

            setPosts(data.filter((curElem) => {
                return curElem.type.toLowerCase() === "post"
            }))
        }
    }, [data])

    return (
        <>
            <TitleBar title="My Posts" />

            <section className="site-wrap">
                <div className="site-container">

                    {/* <h2 className='page-title'>Created videos</h2> */}
                    {/* <section className='recent-video mt-0 pb-5 mb-5'>
                        <div className='video-listing'>
                            <div className='row'>
                                {videos.length > 0 ?
                                    videos.slice(0, 6).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <VideoCard
                                                    curElem={curElem}
                                                    videos={videos}
                                                    setVideos={setVideos}
                                                    fetchVideos={fetchVideos}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15 }}>
                                                You have not created any videos yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {videos.length > 0 ?
                            <div className='load-more'>
                                <Link to={'/post/content?type=videos'} className='theme-btn cursor-pointer' ><span>View All</span></Link>
                            </div> : ""}
                    </section> */}

                    <section className='recent-video pb-5 mb-5'>
                        <div className='sec-intro mw-100 text-start'>
                            <h2>Created posts</h2>
                        </div>


                        <div className='video-listing'>
                            <div className='row'>
                                {posts.length > 0 ?
                                    posts.slice(0, 6).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ImageCard
                                                    curElem={curElem}
                                                    image={posts}
                                                    setImage={setPosts}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15, color: "#00000" }}>
                                                You have not created any post yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {posts.length > 0 ?
                            <div className='load-more'>
                                <Link to={'/post/content?type=posts'} className='theme-btn cursor-pointer' ><span>View All</span></Link>
                            </div> : ""}
                    </section>

                    <section className='recent-video pb-5 mb-5'>
                        <div className='sec-intro mw-100 text-start'>
                            <h2> Created images</h2>
                        </div>

                        <div className='video-listing'>
                            <div className='row'>
                                {images.length > 0 ?
                                    images.slice(0, 6).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ImageCard
                                                    curElem={curElem}
                                                    image={images}
                                                    setImage={setImages}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15, color: "#00000" }}>
                                                You have not created any image yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {images.length > 0 ?
                            <div className='load-more'>
                                <Link to={'/post/content?type=images'} className='theme-btn cursor-pointer' ><span>View all</span></Link>
                            </div> : ""}
                    </section>

                </div>


            </section>

        </>
    )

}

export default CreatePost;