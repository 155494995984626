import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { useSelector } from "react-redux";
import ConnectFacebook from "./ConnectFacebook";
import ConnectReel from "./ConnectReel";
import ConnectYoutube from "./ConnectYoutube";
import TitleBar from "../../CommonComponents/TitleBar";

const Integration = () => {

    const auth = useSelector(state => state.auth)
    const socialData = useSelector(state => state.social);
    const [memberShip, setMemberShip] = useState([])


    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <>
            <Header />
            <TitleBar title="Integration" />
            <div className="site-wrap">
                <div className="site-container">
                    <div className="integration">
                        <h2>Integration</h2>
                        <ul>
                            <li>
                                <a href="#facebook" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/integrations/logo-facebook.png')} className={socialData.facebook.length > 0 ? "" : "disable"} /></div>
                                        <p>Facebook</p>
                                    </div>
                                </a>

                            </li>
                            <li>
                                <a href="#youtube" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/integrations/google_icon.png')} className={socialData.google.length > 0 ? "" : "disable"} /></div>
                                        <p>Google</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#smtp" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/integrations/SMTP.png')} className={socialData.smtp.length > 0 ? "" : "disable"} /></div>
                                        <p>SMTP</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#twilio" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/integrations/TWILIO.png')} className={socialData.twilio.length > 0 ? "" : "disable"} /></div>
                                        <p>Twilio</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#wordpress" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/integrations/WORDPRESS.png')} className={socialData.wordpress.length > 0 ? "" : "disable"} /></div>
                                        <p>Wordpress</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#zillow" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/integrations/ZILLOW.png')} className={socialData.zillow.length > 0 ? "" : "disable"} /></div>
                                        <p>Zillow</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#bing" className="text-light">
                                    <div className="integration-single">
                                        <div className="int-logo"><img alt="" src={require('../../../assets/images/integrations/BING.png')} className={socialData.bing.length > 0 ? "" : "disable"} /></div>
                                        <p>Bing</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#autoresponder" className="text-light">
                                    <div className={`integration-single`}>
                                        <div className="int-logo"><img src={require('../../../assets/images/integrations/code.png')} alt="" className={socialData.autoResponder.length > 0 ? "" : "disable"} /></div>
                                        <p>Autoresponder</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="connection">
                        <h2>Accounts</h2>
                        <div className="row">
                            <div className="col-sm-12">
                                <ConnectFacebook
                                    data={socialData.facebook}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectYoutube
                                    data={socialData.google}
                                />
                            </div>

                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/integrations/SMTP.png')}
                                    name="SMTP"
                                    type="smtp"
                                    data={socialData.smtp}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/integrations/TWILIO.png')}
                                    name="Twilio"
                                    type="twilio"
                                    data={socialData.twilio}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/integrations/WORDPRESS.png')}
                                    name="Wordpress"
                                    type="wordpress"
                                    data={socialData.wordpress}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/integrations/ZILLOW.png')}
                                    name="Zillow"
                                    type="zillow"
                                    data={socialData.zillow}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/integrations/BING.png')}
                                    name="Bing"
                                    type="bing"
                                    data={socialData.bing}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ConnectReel
                                    icon={require('../../../assets/images/integrations/code.png')}
                                    name="Autoresponders"
                                    type="autoresponder"
                                    data={socialData.autoResponder}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Footer />



        </>
    )
}

export default Integration;