import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const MainPost = () => {
    const location = useLocation()
    return (
        <>
            <Header />
            <Outlet />
            {location.pathname === "/post/editor" || location.pathname === "/post/image-editor" ? null :
                <Footer />
            }
        </>
    );
}

export default MainPost;
