import React, { useEffect, useState } from 'react';
import TitleBar from '../../../CommonComponents/TitleBar'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { BsShop, BsListUl, BsFileEarmarkText, BsImages, BsBell, BsTags, BsExclamationCircle, BsPinMapFill, BsClockHistory, BsPencil, BsTrash3, BsChevronLeft, BsSearch, BsThreeDotsVertical } from "react-icons/bs";
import { FaRegTrashCan } from "react-icons/fa6";
import BusinessInfo from './BusinessInfo';
import ServicesInfo from './ServicesInfo';
import { onFetchBusinessDetails } from '../../../../Redux/Actions/ReviewAction';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { FaMapMarkerAlt, FaPhone, FaGlobeAmericas } from "react-icons/fa";
import { LuClock } from "react-icons/lu";
import { GoArrowLeft } from 'react-icons/go';
import Post from './Post/Post';
import PostImages from './Post/PostImages';

const UpdateProfile = () => {

    // const [showDiv1, setShowDiv1] = useState(true);
    // const toggleDivs = () => {
    //     setShowDiv1(!showDiv1);
    // };

    const dispatch = useDispatch();
    const location = useLocation()
    const { id } = queryString.parse(location.search);

    const [businessDetails, setBusinessDetails] = useState({});
    const [businessPhotos, setBusinessPhotos] = useState([]);

    const [loader, setLoader] = useState({
        fetch: false
    });

    const fetchBusinessDetails = () => {
        setLoader({ ...loader, fetch: true });
        let data = {
            id: id
        };
        dispatch(onFetchBusinessDetails(data, setBusinessDetails, setBusinessPhotos, loader, setLoader))
    }

    useEffect(() => {
        fetchBusinessDetails()
    }, []);


    return (
        loader.fetch ?
            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
            :
            <>
                <TitleBar title="Review Inbox" />

                <div className="site-wrap">
                    <div className="site-container">
                        <div className='input-wrap' style={{textAlign:"right"}}>
                            <Link to={`/review-inbox/review?id=${id}`} className='theme-btn mx-2' style={{ width: "130px" }}> <span> <GoArrowLeft />Back</span></Link>
                        </div>
                        <div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className='profile-tabs-wrap'>
                                    <div className='profile-tabs-left account-nav'>
                                        <Nav variant="dd" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"><BsShop /> Business Info</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"><BsListUl /> Services Info</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><BsFileEarmarkText /> Posts</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth"><BsImages /> Photos</Nav.Link>
                                            </Nav.Item>
                                            {/*<Nav.Item>*/}
                                            {/*    <Nav.Link eventKey="fifth"><BsBell /> Notifications</Nav.Link>*/}
                                            {/*</Nav.Item>*/}
                                        </Nav>
                                    </div>
                                    <div className='profile-tabs-right'>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <BusinessInfo businessDetails={businessDetails} />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="second">
                                                <ServicesInfo businessDetails={businessDetails} />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="third">
                                                <Post businessDetails={businessDetails} />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="fourth">
                                                <div>
                                                    <h3><BsImages /> Photos</h3>
                                                    <PostImages
                                                        photos={businessPhotos}
                                                        setPhotos={setBusinessPhotos}
                                                    />
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="fifth">
                                                <div>
                                                    <h3><BsBell /> Notifications</h3>
                                                </div>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default UpdateProfile
