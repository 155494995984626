const initialState = {
    facebook: [],
    google: [],
    smtp: [],
    twilio: [],
    wordpress: [],
    zillow: [],
    bing: [],
    autoResponder: [],
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            return {
                ...state,
                facebook: action.payload.facebook,
                google: action.payload.google,
                smtp: action.payload.smtp,
                twilio: action.payload.twilio,
                wordpress: action.payload.wordpress ? action.payload.wordpress : [],
                zillow: action.payload.zillow ? action.payload.zillow : [],
                bing: action.payload.bing ? action.payload.bing : [],
                autoResponder: action.payload.autoresponder ? action.payload.autoresponder : [],
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}