import React, { useEffect } from 'react'

const SmtpForm = (props) => {

    const { state, setState, handleChange, onInputChange, type, socialData, template } = props

    const handleChooseSmtp = (e) => {
        const { value } = e.target
        const currentTemplate = socialData.smtp.find(val => +val.id === +value)
        setState((preData) => {
            return {
                ...preData,
                name: currentTemplate?.data?.username,
                integrationId: e.target.value
            }

        })
    }

    return (
        <>
            <div className="col-lg-6">
                <div className="input-outer mt-4">
                    <label className="pb-2" htmlFor="">Please select one of your SMTP</label>
                    <select
                        className="common-inp alt"
                        name="name"
                        value={state.integrationId}
                        onChange={(e) => handleChooseSmtp(e)}
                        required
                    >
                        <option value="">Select SMTP</option>
                        {socialData.smtp.length > 0 ?
                            socialData.smtp.map((curElem, index) => {
                                return (
                                    <option key={index} value={curElem.id}>{curElem.username}</option>
                                )
                            })
                            : ""
                        }
                    </select>
                </div>
            </div>

            {
                type === "aiEmail" ?
                    "" :
                    <>
                        <div className="col-lg-6">
                            <div className="input-outer mt-4">
                                <label className="pb-2" htmlFor="">Please select one of your ai email</label>
                                <select
                                    className="common-inp alt"
                                    name="category"
                                    value={state.category}
                                    onChange={handleChange}
                                >
                                    <option value="" style={{ fontWeight: "bold" }}>Custom</option>
                                    {template.length > 0 ?
                                        Array.from(new Set(template.map(curElem => curElem.category))).map((category, index) => (
                                            <option key={index} value={category}>{category}</option>
                                        ))
                                        :
                                        ""
                                    }
                                </select>
                            </div>
                        </div>
                    </>
            }

            <div className="col-lg-12">
                <div className="input-outer mt-4">
                    <label className="pb-2" htmlFor="">Attachment</label>
                    <input
                        type="file"
                        className="common-inp alt"
                        onChange={onInputChange}
                    />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="input-outer mt-4">
                    <label className="pb-2" htmlFor="">Please enter the email subject line</label>
                    <input
                        type="text"
                        className="common-inp alt"
                        placeholder="Enter subject"
                        name="subject"
                        value={state.subject}
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
        </>
    )
}

export default SmtpForm
