import React from 'react'
import { Modal } from 'react-bootstrap'
import { BsXLg } from 'react-icons/bs'

const EmbedPopup = (props) => {
    const handleCopy = () => {
        const copyText = document.getElementById("myEmbedScript");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);

    }

    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className="modalClose" onClick={props.handleClose}><BsXLg /></div>
                <h4>{props.type} widget embed code</h4>
                <p className="pt-2" style={{fontSize:13}}>Place the script above the closing {'</body>'} tag on the page where you want the widget to be displayed.</p>
                <div className="input-outer mt-4 position-relative">
                    <textarea
                        className="common-inp alt"
                        readOnly
                        value={props.script}
                        id='myEmbedScript'
                    />

                </div>
                <div className='text-end'>
                    <button className="theme-btn bdr mt-4" onClick={handleCopy}><span>Copy Code</span></button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EmbedPopup