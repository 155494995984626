import React, { useState } from 'react'
import { BsStarFill, BsStarHalf } from 'react-icons/bs'

const ReviewText = ({ review }) => {
    const [readMore, setReadMore] = useState(false)

    const handleReadMore = () => {
        setReadMore((preData) => !preData)
    }
    return (
        <>
            <div className='card-review-single'>
                <div className='card-review-img'><img src={review.profile_photo_url} alt="" srcset="" /></div>
                <div className='card-review-cont'>
                    <h5>{review.author_name}</h5>
                    <div className='card_review_text'>
                        <p style={{ display: "inline-block" }}>{readMore ? review.text : review.text.length >= 200 ? review.text.slice(0, 200) : review.text}
                            {review.text.length >= 200 ?
                                <span className='review_read_more'>
                                    <span style={{ color: "#0d6efd" }} onClick={handleReadMore}>{readMore ? "ReadLess" : "ReadMore..."}</span>
                                </span> : ""
                            }
                        </p>


                    </div>
                    <div className="rating-top-outer">
                        <div className="rating-top">
                            <div className="star-group">
                                {
                                    [...Array(Math.floor(+review.rating))].map((star, index) => <BsStarFill key={index} />)
                                }
                                {+review.rating % 1 !== 0 ? <BsStarHalf /> : ""}
                            </div>
                            ({review.rating}) <span>{review.relative_time_description}</span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default ReviewText
