import React from 'react'
import { useSelector } from 'react-redux'
import TitleBar from '../../../CommonComponents/TitleBar'
import Google_logo from '../../../../assets/images/integrations/google_icon.png'
import { Link, useNavigate } from 'react-router-dom'

const MainReviewInbox = () => {

    const socialYoutube = useSelector((state) => state.social.google)
    const navigate = useNavigate();
    const handleClick = (id) => {
        navigate(`/review-inbox/review?id=${id}`)
    }

    return (
        <>

            <TitleBar title="Review Inbox" />
            <div className="site-wrap">
                <div className="site-container">
                    <h4 className='my-2 mx-2'>Choose account</h4>

                    <div className="row">
                        {socialYoutube.length > 0 ?
                            socialYoutube.map((curElem, index) => {
                                return (
                                    <div key={index} className="col-lg-3 col-xl-2 col-md-6 col-12" style={{ cursor: "pointer" }} onClick={() => handleClick(curElem.id)}>
                                        <div className="review_box">
                                            <div className="review_img">
                                                <img src={Google_logo} alt="" />
                                            </div>
                                            <div className="review_text_wrap">
                                                <p>{curElem.username}</p>
                                                <p>{curElem.created}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className="text-center mt-5">
                                <span>No Review account is connected. Please go to the integration tab and connect.</span><br />
                                <Link to={"/integration"} className="theme-btn my-2"><span>Connect</span></Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainReviewInbox
