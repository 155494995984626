import React, { useEffect, useState } from 'react'
import { Modal, Nav, Tab } from 'react-bootstrap'
import TimezoneSelect from 'react-timezone-select'
import { useImmer } from 'use-immer';
import yt from "../../assets/images/integrations/google_icon.png"
import fb from "../../assets/images/integrations/logo-facebook.png"
import { GiCheckMark } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { addSchedulerContent, rescheduleContent } from '../../Redux/Actions/CommonActions';
import { Link } from 'react-router-dom';

const ScheduleModal = (props) => {
    let date = new Date()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const social = useSelector(state => state.social)

    const [socialArray, setSocialArray] = useState([])

    const [loader, setLoader] = useState(false)
    const [active, setActive] = useState({
        first: props.data ? false : true,
        second: props.data ? true : false,
    })


    const [scheduleStream, setScheduleStream] = useImmer({
        userId: auth.user.id,
        socialAccount: [],
        schedule_date: date.toDateString(),
        mediaId: "",
        mediaType: "",
        hours: "01",
        minutes: "00",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        re_purpose: 0
    });


    const handleTab = (type) => {
        if (type === 't') {
            setActive({
                ...active,
                first: true,
                second: false,

            })
        }
        else if (type === 'fo') {
            if (scheduleStream.socialAccount.length > 0) {
                setActive({
                    ...active,
                    first: false,
                    second: true,
                })
            }
        }
    }


    const handleBack = () => {
        if (active.second) {
            setActive({
                ...active,
                first: true,
                second: false,
            })
        }
    }


    const handleChange = (name, value) => {
        let valData = value

        if (name === "schedule_date") {
            let myDate = new Date(value)
            valData = myDate.toDateString()
        }

        if (name === "socialAccounts") {
            if (scheduleStream.socialAccount.includes(valData)) {
                const index = scheduleStream.socialAccount.indexOf(valData)
                if (index !== -1) {
                    setScheduleStream((draft) => {
                        draft.socialAccount.splice(index, 1)
                    })
                }
            }
            else {
                setScheduleStream((draft) => {
                    draft.socialAccount.push(valData)
                })
            }

        } else {
            setScheduleStream((draft) => {
                draft[name] = valData
            })
        }
    }

    const handleSubmit = () => {
        if (active.second) {
            let data = { ...scheduleStream }
            setLoader(true)
            if (props.data) {
                delete data.userId
                delete data.socialAccount
                delete data.mediaId
                delete data.mediaType
                delete data.re_purpose
                dispatch(rescheduleContent(data, props.fetchSchedule, props.handleClose, setLoader))
            } else {
                dispatch(addSchedulerContent(data, props.handleClose, setLoader))
            }
        } else {
            if (active.first && socialArray.length > 0 && scheduleStream.socialAccount.length > 0) {
                setActive({
                    ...active,
                    first: false,
                    second: true,
                })
            }
        }
    }

    useEffect(() => {
        if (props.data) {
            setScheduleStream({
                ...scheduleStream,
                schedule_date: props.data.scheduleDate,
                hours: props.data.hours,
                minutes: props.data.minutes,
                timezone: props.data.timezone,
                id: props.data.id
            })
        } else {
            setScheduleStream({
                ...scheduleStream,
                mediaId: props.id,
                mediaType: props.type
            })
        }

    }, [props])



    useEffect(() => {
        let arr = []

        if (social.facebook.length > 0) {
            arr = [...social.facebook]
        }

        if (social.google.length > 0) {
            arr = [...arr, ...social.google]
        }
        setSocialArray(arr)

    }, [social])
    return (
        <Modal className="theme-modal xl" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className='modal-tab'>
                    <h3 className='pb-4'>{props.data ? "Reschedule" : "Schedule"} your post</h3>
                    <Tab.Container id="left-tabs-example" >
                        <Nav variant="">
                            {props.data ? null :
                                <Nav.Item
                                    onClick={() => handleTab("t")}
                                >
                                    <Nav.Link className={active.first ? "active" : ''}>Socials</Nav.Link>
                                </Nav.Item>}
                            <Nav.Item
                                onClick={() => handleTab("fo")}
                            >
                                <Nav.Link className={active.second ? "active" : ''}>Schedule</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className='pt-3 schedule_modal'>
                            <Tab.Pane className={active.first ? 'show active' : ""}>
                                <div className='streame-list'>
                                    <ul>
                                        {socialArray.length > 0 ?
                                            socialArray.map((curElem, index) => {
                                                let image = curElem.type === "google" ? yt :
                                                    curElem.type === "facebook" ? fb :
                                                        ""
                                                return (
                                                    <li key={index} className='text-center'>
                                                        <div
                                                            className='streame-list-single'
                                                            onClick={() => handleChange("socialAccounts", curElem.id)}
                                                            style={{ position: 'relative', cursor: "pointer" }}
                                                        >
                                                            <div className='streame-list-img'><img alt='' src={image} /></div>
                                                            {scheduleStream.socialAccount.includes(curElem.id) ?
                                                                <div className='select-tile-style' style={{ zIndex: 9999 }}>
                                                                    <GiCheckMark />
                                                                </div> : ""}
                                                        </div>
                                                        <p style={{ fontSize: 12, fontWeight: "bold", marginTop: 5 }}>{curElem.title}</p>
                                                    </li>
                                                )
                                            })
                                            :
                                            <div className='text-center mt-3 alert alert-info' style={{ width: "100%" }}>
                                                <span>
                                                    You do not have any social account connected. Please <Link to="/integration">Click Here</Link> to connect social accounts to continue!
                                                </span>
                                            </div>
                                        }
                                    </ul>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className={active.second ? 'show active' : ""}>
                                <div className='row pt-3'>

                                    <div className='col-sm-12 col-lg-12'>
                                        <div className='common-input-wrap mt-0'>
                                            <label htmlFor="">Date</label>
                                            <DatePicker
                                                selected={scheduleStream.schedule_date}
                                                onChange={(date) => handleChange("schedule_date", date)}
                                                className='common-inp'
                                                dateFormat='dd/MM/yyyy'
                                                minDate={date.toDateString()}
                                                dayClassName={(date) => (date < new Date() ? 'disabled' : null)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-sm-6 col-lg-6'>
                                        <div className='common-input-wrap'>
                                            <label htmlFor="">Hours</label>
                                            <input
                                                type='number'
                                                className='common-inp'
                                                min={1}
                                                max={24}
                                                name='hours'
                                                value={scheduleStream.hours}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-lg-6'>
                                        <div className='common-input-wrap'>
                                            <label htmlFor="">Minutes</label>
                                            <input
                                                type='number'
                                                className='common-inp'
                                                min={0}
                                                max={59}
                                                name='minutes'
                                                value={scheduleStream.minutes}
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='common-input-wrap'>
                                    <label htmlFor="">Timezone</label>
                                    <TimezoneSelect className='timeSelect'
                                        value={scheduleStream.timezone}
                                        onChange={(e) => handleChange("timezone", e.value)}
                                        style={{ color: "black" }}
                                    />
                                </div>
                                {/* <div className="alert alert-primary mt-2" role="alert">
                                    Hit "repurpose" to have AiSocials repost the content monthly without having to reschedule again. AiSocials will select your best content from that particular content stream to post each time without scheduling again.
                                </div>
                                <div className='inp-wrap mt-2'>
                                    <label htmlFor='re_purpose' className='text-capitalize'>Repurpose</label>
                                    <label className="switch">
                                        <input
                                            className='input solid'
                                            name="re_purpose"
                                            type="checkbox"
                                            id="re_purpose"
                                            checked={+scheduleStream.re_purpose === 0 ? false : true}
                                            onChange={(e) => handleChange("re_purpose", e.target.checked ? 1 : 0)}
                                        />
                                        <span className="sliderN round"></span>
                                    </label>

                                </div> */}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <div className='text-end mt-3'>
                    {props.data ? null :
                        <button className='theme-btn bdr' onClick={active.first ? props.handleClose : handleBack}><span>{active.first ? "Cancel" : "Back"}</span></button>}
                    <button className='theme-btn ms-1' onClick={handleSubmit}><span>{active.second ? loader ? <>Scheduling <i className="fa fa-spinner fa-spin mx-1" /></> : "Schedule" : "Next"}</span></button>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default ScheduleModal