import React, { useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import { fetchData, onDeleteCampaign } from "../../../../Redux/Actions/CommonActions";
import WidgetRow from "./WidgetRow";
import { useDispatch } from "react-redux";
import SweetAlert from "../../../CommonComponents/SweetAlert";
import Swal from "sweetalert2";
import GetReviewHeader from "../../../CommonComponents/GetReviewHeader";
import CreateModal from "../../../CommonComponents/CreateModal";
import { themeColor } from "../../../../Global/Global";


const WebsiteWidget = () => {
    const dispatch = useDispatch()
    const [widgetData, setWidgetData] = useState([])
    const [checkedArr, setCheckedArr] = useState([])
    const [isCheckedAll, setIsCheckedAll] = useState(false)
    const [q, setQ] = useState("")
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleCheckAll = (e) => {
        if (widgetData.length > 0) {
            let arr = []
            if (e.target.checked) {
                widgetData.forEach((curElem) => {
                    arr.push(curElem.id)
                })
                setIsCheckedAll(true)
            } else {
                setIsCheckedAll(false)
                arr = []
            }
            setCheckedArr(arr)
        }
    }

    const handleDeleteAll = () => {
        if (checkedArr.length > 0) {
            setSweet({
                ...sweet,
                enable: true,
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select website widget to delete them.',
                confirmButtonColor: themeColor
            })
        }
    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: checkedArr,
            type: "all"
        }
        dispatch(onDeleteCampaign("delete-website", data, fetchWidgetData, false, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }


    useEffect(() => {
        if (checkedArr.length > 0) {
            if (checkedArr.length === widgetData.length) {
                setIsCheckedAll(true)
            } else {
                setIsCheckedAll(false)
            }
        } else {
            setIsCheckedAll(false)
        }
    }, [checkedArr])




    //Fetch Stuff
    const fetchWidgetData = () => {
        dispatch(fetchData("fetch-website-all", {}, setWidgetData, loader, setLoader))
    }

    useEffect(() => {
        fetchWidgetData()
    }, [])

    return (
        <>
            <TitleBar title="Website Widget" />

            <div className="site-wrap">
                <div className="site-container">

                    <GetReviewHeader
                        name={"Website widget"}
                        handleDeleteAll={handleDeleteAll}
                        setQ={setQ}
                        handleShow={handleShow}
                    />

                    <div className="table-area mt-5">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th width="30">
                                        <label className="table-check">
                                            <input
                                                type="checkbox"
                                                checked={isCheckedAll}
                                                onChange={handleCheckAll}
                                            />
                                            <span className="check-mark"></span>
                                        </label>
                                    </th>
                                    <th>Campaign Name</th>
                                    <th>Views</th>
                                    <th>Unique Views</th>
                                    <th>Clicked</th>
                                    <th>Created</th>
                                    <th className="text-end">Action</th>
                                </tr>
                                {widgetData.length > 0 ?
                                    widgetData.filter((cur) => {
                                        return cur.name.toLowerCase().includes(q.toLowerCase()) || q === ""
                                    }).length > 0 ?
                                        widgetData.filter((cur) => {
                                            return cur.name.toLowerCase().includes(q.toLowerCase()) || q === ""
                                        }).map((curElem, index) => {
                                            let totalClick = 0
                                            let keyArr = Object.keys(curElem)
                                            keyArr.forEach((key) => {
                                                if (key.includes("Click")) {
                                                    totalClick += +curElem[key]
                                                }
                                            })
                                            return (
                                                <React.Fragment key={index}>
                                                    <WidgetRow
                                                        curElem={curElem}
                                                        widgetData={widgetData}
                                                        setWidgetData={setWidgetData}
                                                        checkedArr={checkedArr}
                                                        setCheckedArr={setCheckedArr}
                                                        clicked={totalClick}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <tr >
                                            <td colSpan={7} className="text-center">
                                                No website widget found.
                                            </td>
                                        </tr>
                                    :
                                    <tr >
                                        <td colSpan={7} className="text-center">
                                            {loader.fetch ? <i className="fa fa-spin fa-spinner spin-style"  /> : "No website widget created yet."}
                                        </td>
                                    </tr>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <CreateModal
                show={show}
                handleClose={handleClose}
                heading="Create new website widget"
                redirect={"/review/widget-style"}
                url={"create-website-widget"}
            />


            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br><br> Do you want to delete ${checkedArr.length === 1 ? "this website widget" : "them all"}?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />
        </>
    )
}

export default WebsiteWidget;