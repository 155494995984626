import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";
import Wabinar from "./Wabinar";
import TitleBar from "../CommonComponents/TitleBar";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { gapi } from 'gapi-script';
import { BsDatabaseAdd, BsInboxes, BsReverseLayoutTextWindowReverse } from "react-icons/bs";

const Dashboard = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showClose, setShowClose] = useState(false)

    const [showPopup, setShowPopup] = useState(false);
    const colorObj = {
        color: "#ffffff",
        position: "absolute",
        top: '-20px',
        right: "0px",
        fontSize: 11,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: "8px 7px",
        borderRadius: 2,
        cursor: "pointer"
    }
    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelClose2 = () => {
        setShowPopup(false);
    }

    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }
    const handlePlayPause = () => {
        setShow(true)
    }

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    // useEffect(() => {
    //     const popupCookie = document.cookie
    //     if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
    //         setShowPopup(false)
    //     } else {
    //         setShowPopup(true)
    //     }
    // }, []);

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: "26993941446-lqsok345c6ooacak4fk01l6eqqt4emd0.apps.googleusercontent.com",
                scope: 'https://www.googleapis.com/auth/business.manage',
            }).catch(error => {
                console.error('Error initializing Google API client:', error);
            });
        }
        gapi.load('client:auth2', start);
    }, []);

    return (
        <>
            <div className="header-dash"><Header /></div>
            <TitleBar title="Dashborad" />
            <div className="site-wrap for-dashboard pt-0">
                <div className='hero-banner '>
                    <div className='hero-banner-video'>
                        <iframe src="https://player.vimeo.com/video/920009266?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="app intro video"></iframe>
                    </div>
                    <div className='container'>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="welcome" style={{ position: "relative", zIndex: "2" }}>
                                    <div className="welcome-top">
                                        <h1>WELCOME TO <br /> {rebrand ? rebrand.name : appName}</h1>
                                        <p>Embark on a new era of online reputation management with {rebrand ? rebrand.name : appName} at your side. Unlock control over your business’s online reputation. From AI-powered analytics to innovative widgets, complete automation, and advanced traffic and lead generation tools, everything you need is at your fingertips. Your journey to an awesome reputation starts here.</p>
                                        <button className="theme-btn mt-4" onClick={handleShow3}><span>Watch Demo Video</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="dash-link">
                                    <ul>
                                        <li>
                                            <Link to={`/review`}>
                                                <span className="dash-link-icon"><BsReverseLayoutTextWindowReverse /></span>
                                                <h6>Get Reviews</h6>
                                            </Link>
                                        </li>
                                        {+auth.user.isClientAccount === 1 ? null :
                                            <li>
                                                <Link to={`/lead`}>
                                                    <span className="dash-link-icon"><BsDatabaseAdd /></span>
                                                    <h6>Find Leads</h6>
                                                </Link>
                                            </li>}
                                        <li>
                                            <Link to={`/review-inbox`}>
                                                <span className="dash-link-icon"><BsInboxes /></span>
                                                <h6>Review Inbox</h6>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="welcome-video d-none">
                        <div className="welcome-video-in">


                            <div className="play-btn" onClick={handlePlayPause}>

                                <img
                                    id="play-button"
                                    alt=""
                                    src={require("../../assets/images/play.png")}
                                    onClick={handlePlayPause}
                                />
                            </div>

                            <Modal className="theme-modal ai_img_wrap video-modal ds" show={showPopup} onHide={handelClose2} centered>
                                <Modal.Body>
                                    <div onClick={() => setShowClose(true)} className="vidClose" >
                                        <div style={{ position: "relative" }}>
                                            <div className='modalClose' onClick={handleClose}><AiOutlineClose style={{
                                                fontSize: "30px", marginBottom: "3px", top: '-3px',
                                                right: '-10px', position: "absolute"
                                            }} /></div>
                                            {showClose ?
                                                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                                        </div>

                                    </div>
                                    <div className="modalVid">
                                        {/* <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
                                            <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
                                        </a> */}
                                        <div className="para_ai h-100">
                                            <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                                                <p style={{
                                                    fontSize: "55px",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    lineHeight: "1.3",
                                                    color: "#fff"
                                                }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                                                <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> Click Here</button>
                                            </a>
                                        </div>
                                    </div>

                                </Modal.Body>
                            </Modal>

                            <Modal className='theme-modal video-modal ds ' show={show} onHide={handleClose} centered>
                                <Modal.Body className="p-0">
                                    <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                                    <div className="" >
                                        <iframe src="https://player.vimeo.com/video/881969178?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="738" height="430" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="ClipsReelAI Walkthrough"></iframe>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>

                <Modal className="theme-modal video-modal desktop_modal " show={show3} onHide={handleClose3} centered>
                    <Modal.Body>
                        <div className='modalClose text-end' onClick={handleClose3}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "-55px" }} /></div>
                        <div className="modalVidN" >
                            <iframe src="https://player.vimeo.com/video/881969178?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="430" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="ClipsReelAI Walkthrough"></iframe>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal className="theme-modal video-modal desktop_modal_bs " show={show3} onHide={handleClose3} centered>
                    <Modal.Body>
                        <div className='modalClose text-end' onClick={handleClose3}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "-55px" }} /></div>
                        <div className="modalVidN" >
                            <iframe src="https://player.vimeo.com/video/881969178?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="768" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="ClipsReelAI Walkthrough"></iframe>
                        </div>
                    </Modal.Body>
                </Modal>


                <div className="image-block">

                    <div className="image-block-single mt-4">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5 className="text-uppercase">AI Powered with New Widgets</h5>
                                    <p>{rebrand ? rebrand.name : appName} revolutionizes reputation management by combining AI power with new dynamic widgets. Enjoy an intuitive experience with AI automation and analytics, enhanced by customizable widgets for instant insights. It simplifies and streamlines your online presence, tailored to your business needs.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-1.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5 className="text-uppercase">Full Automation</h5>
                                    <p>With {rebrand ? rebrand.name : appName}, embrace the convenience of full automation. Let our system take care of repetitive tasks like monitoring reviews, updating profiles, and generating reports. Free up your time to focus on strategic decisions while {rebrand ? rebrand.name : appName} handles the day-to-day management.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-2.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5 className="text-uppercase">New Traffic Generation Options</h5>
                                    <p>{rebrand ? rebrand.name : appName} introduces new ways to drive traffic to your business. Utilize our advanced tools to attract more visitors, from SEO optimization to targeted content strategies. Increase your online visibility and draw in a larger, more engaged audience.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-3.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5 className="text-uppercase">New Lead Search and Filtering Options</h5>
                                    <p>Finding and managing leads is now more efficient with {rebrand ? rebrand.name : appName}. Our enhanced search and filtering options allow you to pinpoint ideal clients with precision. Streamline your lead generation process, target the right audience, and convert prospects into loyal customers faster.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-4.png')} /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <Wabinar />
            </div >

            <Footer />
        </>
    )
}

export default Dashboard;