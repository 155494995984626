import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchData } from '../../../Redux/Actions/CommonActions'
import VideoCard from '../../CommonComponents/VideoCard'
import { useDispatch } from 'react-redux'
import TitleBar from '../../CommonComponents/TitleBar'
import ImageCard from '../../CommonComponents/ImageCard'

const Content = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { type } = queryString.parse(location.search)
    const [data, setData] = useState([])

    const [loader, setLoader] = useState({ fetch: false })

    const fetchAllData = () => {
        let url = type === "videos" ? "fetch-video-all" : "fetch-image-campaign"
        dispatch(fetchData(url, {}, setData, loader, setLoader))
    }



    useEffect(() => {
        if (type) {
            setLoader({
                ...loader,
                fetch: true
            })
            fetchAllData()
        }
    }, [type])




    return (
        <>
            <TitleBar title="Content" />

            <section className="site-wrap">
                <div className="site-container">
                    <h2 className='page-title text-capitalize'>{type}</h2>

                    <section className='recent-video mt-0 pb-5 mb-5'>
                        <div className='video-listing'>
                            <div className='row'>
                                {data.length > 0 ?
                                    data.filter((curElem) => {
                                        if (type !== "videos") {
                                            let myType = type === "posts" ? "post" : "image"
                                            return curElem.type.toLowerCase() === myType
                                        } else {
                                            return curElem
                                        }
                                    }).length > 0 ?
                                        data.filter((curElem) => {
                                            if (type !== "videos") {
                                                let myType = type === "posts" ? "post" : "image"
                                                return curElem.type.toLowerCase() === myType
                                            } else {
                                                return curElem
                                            }
                                        }).map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {type === "videos" ?
                                                        <VideoCard
                                                            curElem={curElem}
                                                            videos={data}
                                                            setVideos={setData}
                                                        /> :
                                                        <ImageCard
                                                            curElem={curElem}
                                                            image={data}
                                                            setImage={setData}
                                                        />}

                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <div className='fetch-loader-block'>
                                            <span style={{ fontSize: 15 }}>
                                                You have not created any {type} yet!
                                            </span>
                                        </div> :
                                    <div className='fetch-loader-block'>
                                        {loader.fetch ?
                                            <i className="fa fa-spinner fa-spin fetch-loader" />
                                            :
                                            <span style={{ fontSize: 15 }}>
                                                You have not created any {type} yet!
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </div>

            </section>

        </>
    )
}

export default Content