import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import SweetAlert from '../../../CommonComponents/SweetAlert';
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions';
import { BsEye, BsPencil, BsTrash3 } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import AiEmailModal from './AiEmailModal';
import { useSelector } from 'react-redux';

const AiEmailRow = ({ curElem, index, contactLeadData, setContactLeadData }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: curElem.id,
        }
        dispatch(onDeleteCampaign("delete-ai-email", data, contactLeadData, setContactLeadData, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{curElem.category}</td>
            <td>{curElem.searchServiceKeyword}</td>
            <td className="text-end">
                <span className="widg-icon">
                    <a onClick={handleShow}><BsEye /></a>
                    {+auth.user.isClientAccount === 1 ? null :
                        <>
                            <Link to={`/lead/create-ai-emails?id=${curElem.id}`} ><BsPencil /></Link>
                            <a onClick={deleteRow}><BsTrash3 /></a>
                        </>
                    }
                </span>
            </td>

            <AiEmailModal
                curElem={curElem}
                show={show}
                handleClose={handleClose}
            />

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? Do you want to delete this campaign?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />
        </tr>
    )
}

export default AiEmailRow
