import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions';
import { BsPencil, BsTrash3 } from 'react-icons/bs';
import { FaDownload } from "react-icons/fa6";
import SweetAlert from '../../../CommonComponents/SweetAlert';
import CreateModal from '../../../CommonComponents/CreateModal';
import { useSelector } from 'react-redux';

const CampRow = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [show, setShow] = useState(false);
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: props.curElem.id,
            type: ""
        }
        dispatch(onDeleteCampaign("delete-qr", data, props.campData, props.setCampData, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }


    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const handleCheck = (e) => {
        let arr = [...props.checkedArr]
        if (e.target.checked) {
            arr.push(props.curElem.id)
        } else {
            let idIndex = props.checkedArr.findIndex((val) => val === props.curElem.id)
            arr.splice(idIndex, 1)
        }
        props.setCheckedArr(arr)

    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = props.curElem.imageUrl
        link.download = 'download.png';
        link.target = "_blank"
        link.click();
    }

    return (
        <tr>
            <td width="30">
                <label className="table-check">
                    <input
                        type="checkbox"
                        checked={props.checkedArr.includes(props.curElem.id)}
                        onChange={handleCheck}
                    />
                    <span className="check-mark"></span>
                </label>
            </td>
            <td>{props.curElem.name}</td>
            <td>{props.curElem.size === "803x473" ? "Business card (8.5x5cm)" : props.curElem.size === "893x596" ? "Card (14.8x10.5cm)" : props.curElem.size === "794x1122" ?  "Flyer (210x297mm)" : `Custom (${props.curElem.size})px`}</td>
            <td>
                <textarea
                    className="common-inp alt"
                    style={{ padding: "10px 0px 0 13px " }}
                    value={props.curElem.redirectUrl}
                    readOnly
                />
            </td>
            <td>
                {props.curElem.created}
            </td>
            <td className="text-end">
                <span className="widg-icon">
                    {props.curElem.imageUrl ?
                        <a onClick={handleDownload} title='Download'><FaDownload /></a> : null}
                    {+auth.user.isClientAccount === 1 ? null :
                        <>
                            <a onClick={handleShow} title='Edit'><BsPencil /></a>
                            <a onClick={deleteRow} title='Delete'><BsTrash3 /></a>
                        </>
                    }
                </span>
            </td>


            <CreateModal
                show={show}
                handleClose={handleClose}
                heading="QR Code Generator"
                description="Enter a campaign name below."
                redirect={"/review/image-editor"}
                url={"create-qr-code"}
                data={props.curElem}
            />

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure?<br><br> Do you want to delete this QR-Code widget?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />
        </tr>
    )
}

export default CampRow