import React, { useState } from 'react'
import { BsPencil, BsTrash3 } from 'react-icons/bs'
import yt from "../../../assets/images/integrations/google_icon.png"
import fb from "../../../assets/images/integrations/logo-facebook.png"
import SweetAlert from '../../CommonComponents/SweetAlert'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import ScheduleModal from '../../CommonComponents/ScheduleModal'
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from 'react-redux'

const ScheduleRows = (props) => {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: props.curElem.id,
            type: ""
        }
        dispatch(onDeleteCampaign("delete-schedule", data, props.scheduleData, props.setScheduleData, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const handleOpenUrl = () => {
        window.open(props.curElem.postSuccessData.post_url, "_blank")
    }

    return (
        <tr>
            <td>
                <img src={props.curElem.postingData.thumbnail} alt='' style={{ height: 90 }} />
            </td>
            <td className='text-capitalize'>{props.curElem.mediaType}</td>
            <td >
                {props.curElem.socialAccount.length > 0 ?
                    props.curElem.socialAccount.map((curElem, index) => {
                        let url = curElem.type === "google" ? yt : fb
                        return (
                            <div key={index} >
                                <img src={url} className='schedule-social-icons mx-1' alt='' key={index} />
                                <p style={{ fontSize: 10 }}>{curElem.username}</p>
                            </div>
                        )
                    })

                    : null}
            </td>
            <td>{props.curElem.scheduleDate} {props.curElem.hours.length === 1 ? "0" + props.curElem.hours : props.curElem.hours}:{props.curElem.minutes.length === 1 ? "0" + props.curElem.minutes : props.curElem.minutes}</td>
            <td>
                <span
                    className='post-url-btn'
                    style={{ backgroundColor: +props.curElem.done === 1 ? "#DAA520" : +props.curElem.done === 2 ? "#008000" : "#800000" }}
                    data-tooltip-id={`my-tooltip-schedule-${props.curElem.id}`}
                >
                    {+props.curElem.done === 1 ? "Scheduled" : +props.curElem.done === 2 ? "Posted" : "Failed"}

                </span>
                {+props.curElem.done === 3 ?
                    <Tooltip
                        id={`my-tooltip-schedule-${props.curElem.id}`}
                        style={{ backgroundColor: "#544fc4", color: "#fff", maxWidth: 500, position: "absolute", top: 0, padding: 10 }}
                        place="bottom"
                        content={props.curElem.postSuccessData.message}
                    /> : null}
            </td>
            <td>{props.curElem.postSuccessData && props.curElem.postSuccessData.post_url ?
                <span className='post-url-btn' style={{ backgroundColor: "#544fc4" }} onClick={handleOpenUrl}>
                    View
                </span> : null}
            </td>
            <td>
                <span className="widg-icon">
                    {+auth.user.isClientAccount === 1 ? "" :
                        <>
                            <a onClick={handleShow}><BsPencil /></a>
                            <a onClick={deleteRow}><BsTrash3 /></a>
                        </>}
                </span>
            </td>

            <ScheduleModal
                show={show}
                handleClose={handleClose}
                data={props.curElem}
                fetchSchedule={props.fetchSchedule}
            />

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br> <br> Do you want to delete this post schedule?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />


        </tr>
    )
}

export default ScheduleRows