import React from 'react'
import ContentEditable from 'react-contenteditable'

const InfoBoxFind = (props) => {
    return (
        <div style={{ border: "1px dashed gray", padding: "10px 20px", borderRadius : 10 }}>
            <ul style={{ listStyleType: "disc" }}>
                {props.data.length > 0 &&
                    props.data.map((curElem, index) => {
                        return (
                            <li key={index} style={{fontSize:13, color:'#000'}}>
                                <ContentEditable
                                    html={curElem}
                                    tagName='span'
                                    disabled={true}
                                />
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default InfoBoxFind