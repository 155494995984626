import React, { useEffect, useState } from 'react'
import { BsFileEarmarkText, BsImages, BsExclamationCircle, BsClockHistory, BsPencil, BsTrash3, BsChevronLeft, BsSearch, BsThreeDotsVertical } from "react-icons/bs";
import { FaMapMarkerAlt, FaPhone, FaGlobeAmericas } from "react-icons/fa";
import { LuClock } from "react-icons/lu";
import { GoArrowLeft } from 'react-icons/go';
import PostImages from './PostImages';
import { useImmer } from 'use-immer';

const PostMobileView = ({ state }) => {

    const [couponDate, setCouponDate] = useState({
        fromMonthName: "",
        fromDateNumber: "",
        toMonthName: "",
        toDateNumber: ""
    })

    useEffect(() => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const fromParts = state.fromDate.split("/");
        const fromDate = new Date(`${fromParts[2]}-${fromParts[1]}-${fromParts[0]}`);
        const toParts = state.toDate.split("/");
        const toDate = new Date(`${toParts[2]}-${toParts[1]}-${toParts[0]}`);
        const fromMonthName = months[fromDate.getMonth()];
        const toMonthName = months[toDate.getMonth()];
        const fromDateNumber = fromDate.getDate();
        const toDateNumber = toDate.getDate();

        setCouponDate({
            fromMonthName: fromMonthName,
            fromDateNumber: fromDateNumber,
            toMonthName: toMonthName,
            toDateNumber: toDateNumber
        });
    }, [state.fromDate, state.toDate]);

    return (
        <div className='create-post-right'>
            <div className='post-phone'>
                <div className='post-phone-area'>
                    <div className='phone-area-top'>
                        <span><BsChevronLeft /></span>
                        <div>
                            <span><BsSearch /></span>
                            <span><BsThreeDotsVertical /></span>
                        </div>
                        <div className='business-name'>Business Name</div>
                    </div>

                    <div className='phone-tabs'>
                        <ul>
                            <li className='active'><a href="">OVERVIEW</a></li>
                            <li><a href="#">POSTS</a></li>
                            <li><a href="#">MENU</a></li>
                            <li><a href="#">REVIEWS</a></li>
                            <li><a href="#">ITEM</a></li>
                        </ul>
                    </div>

                    <div className='phone-scroll'>

                        <div className='phone-listings'>
                            <ul>
                                <li className='map-img'>
                                    <span className='phone-listings-icon'><FaMapMarkerAlt /></span>
                                    <span className='phone-listings-text'>The Capital Mirage, 40 Chiappini st, Cape Town, 8001, South Africa</span>
                                    <span className='phone-map'><img src={require('../../../../../assets/images/map.png')} alt="" /></span>
                                </li>
                                <li>
                                    <span className='phone-listings-icon'><FaMapMarkerAlt /></span>
                                    <span className='phone-listings-text'>Business service Area</span>
                                </li>
                                <li>
                                    <span className='phone-listings-icon'><LuClock /></span>
                                    <span className='phone-listings-text'>Business Hours</span>
                                </li>
                                <li>
                                    <span className='phone-listings-icon'><FaPhone /></span>
                                    <span className='phone-listings-text'>1234 - 5678</span>
                                </li>
                                <li>
                                    <span className='phone-listings-icon'><FaGlobeAmericas /></span>
                                    <span className='phone-listings-text'>https:/www.business-webiste.com</span>
                                </li>
                            </ul>
                        </div>

                        <div className='txt-area'>
                            <span>Offers</span>
                            <span><a href="#">View All</a></span>
                        </div>

                        <div className='offer-sec-wrap'>
                            <div className='offer-sec'>
                                <div className='offer-sec-img'><img src={state.url[0] ? state.url[0] : "https://images.pexels.com/photos/20393167/pexels-photo-20393167/free-photo-of-lake-in-town.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} alt="" /></div>
                                <div className='offer-sec-txt'>
                                    <h5>{state.title ? state.title : "AIReputors"}</h5>
                                    {couponDate.fromMonthName && couponDate.toMonthName ?
                                        <h6>{couponDate.fromMonthName} {couponDate.fromDateNumber} {state.fromTime} {+state.fromTime.split(":")[0] >= 12 ? "PM" : "AM"} - {couponDate.toMonthName} {couponDate.toDateNumber} {state.toTime} {+state.toTime.split(":")[0] >= 12 ? "PM" : "AM"}</h6>
                                        : ""
                                    }
                                    <p>{state.created ? state.created : ""}</p>
                                    <h6><a href="#">View Offer</a></h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostMobileView
