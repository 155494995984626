import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { IoPlay, IoPause } from "react-icons/io5"
import TitleBar from "../../CommonComponents/TitleBar";
import { useDispatch } from "react-redux";
import { onFetchProjects, onMergingVideo } from "../../../Redux/Actions/CommonActions";
import SweetAlert from "../../CommonComponents/SweetAlert";
import ReactPlayer from "react-player";
import { FaSpinner } from "react-icons/fa";
import MergeTiles from "./MergeTiles";


const ReelMerge = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [videoData, setVideoData] = useState([])
    const [loader, setLoader] = useState(false)
    const [videoLoader, setVideoLoader] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false)

    const [dimension, setDimension] = useState("16x9")
    const [play, setPlay] = useState(false)
    const [mergeVideos, setMergeVideos] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(false);

    const [mergeData, setMergeData] = useState({
        data: [],
        name: '',
        preview_image: false,
        ratio: '16:9',
        // user_id: auth.user.id
    })

    const [sweet, setSweet] = useState({
        enable: false,
        eventValue: false,
        confirmButtonName: "Change",
    })

    const handleChange = (e) => {
        setSweet({
            ...sweet,
            enable: true,
            eventValue: e.target.value
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            eventValue: false,
        })
    }

    const performDelete = () => {
        setDimension(sweet.eventValue)
        setMergeVideos([])
        setMergeData({ ...mergeData, ratio: sweet.eventValue === "16x9" ? "16:9" : "9:16" })
        setSweet({
            ...sweet,
            enable: false,
            eventValue: false,
        })
    }

    //merge stuff 


    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitLoader(true);
        mergeData.data = mergeVideos;
        mergeData.preview_image = mergeVideos[0].preview_image;
        dispatch(onMergingVideo(mergeData, navigate, setSubmitLoader))
    }

    const handleMerge = (item) => {
        if (currentSlide.url !== item.videoUrl || mergeVideos.length === 0) {
            let jsonObj = {
                id: item.id,
                // name: item.name,
                preview_image: item.thumbnailUrl,
                url: item.videoUrl,
                isSelected: true
            }
            let data = [...mergeVideos]
            data = data.concat(jsonObj)

            if (data.length > 1) {
                const selectedIndex = data.findIndex(({ isSelected }) => isSelected === true)
                data[selectedIndex].isSelected = false
            }
            setCurrentSlide(jsonObj);
            setMergeVideos(data);
        }
    }


    const fetchCamp = () => {
        setLoader(true)
        dispatch(onFetchProjects(setVideoData, false, setLoader))
    }

    useEffect(() => {
        fetchCamp()
    }, [])

    useEffect(() => {
        if (currentSlide.url) {
            setVideoLoader(true)
        }
    }, [currentSlide])


    return (
        <>
            <Header />

            <TitleBar title="Reelmerge" />
            <div className="site-wrap" style={{ paddingBottom: "0" }}>
                <div className="site-container" style={{ maxWidth: "100%", paddingLeft: "0", paddingRight: "0" }}>
                    <div className="site-container for-editor" style={{ maxWidth: "100%", padding: "0" }}>
                        <div className="editor-wrap" style={{ padding: "0 50px" }}>
                            <div className="editor-left">
                                <div className="editor-left-cont" style={{ margin: "0 14px" }}>
                                    <div class="row justify-content-center align-items-center g-2">

                                        <div class="col-12">
                                            <div className="Select_heading text-center">
                                                <h3 style={{ fontSize: "18px" }}>Select Resolution</h3>
                                            </div>
                                            <div className="set_resolution text-center mt-3" >
                                                <div className='d-flex justify-content-center ' >
                                                    <div className="form-check" style={{ marginRight: "10px" }}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="flexCheckDefault"
                                                            name='dimension'
                                                            checked={dimension === "16x9" ? true : false}
                                                            value={"16x9"}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="" for="flexCheckDefault">
                                                            16:9
                                                        </span>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="flexCheckChecked"
                                                            name='dimension'
                                                            checked={dimension === "9x16" ? true : false}
                                                            value={"9x16"}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="" for="flexCheckChecked">
                                                            9:16
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr style={{ opacity: "1", }} />
                                        </div>

                                    </div>

                                    <div className="photo-scroll" style={{ height: "calc(100vh - 475px)" }}>
                                        <div className="row">
                                            {videoData.length > 0 ?
                                                videoData.filter((elem) => {
                                                    return elem.video[0]?.videoUrl && dimension === elem.dimension
                                                }).map((curElem, index) => {
                                                    return (
                                                        <div className="col-6" key={index} onClick={() => handleMerge(curElem.video[0])} style={{ cursor: "pointer" }}>
                                                            <div className="photo-single">
                                                                <img src={curElem.video[0]?.thumbnailUrl} alt="" />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :

                                                <div className="text-center">
                                                    {loader ? <FaSpinner className="spin" style={{ fontSize: 25, color: "#00c4a6" }} /> : null}
                                                </div>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="editor-right" style={{ height: "calc(100vh - 252px)", overflow: "hidden" }}>
                                <form className="col-12 d-flex align-items-center justify-content-center mb-5" onSubmit={handleSubmit}>
                                    <div className="input-wrap">
                                        <div className="input-outer">
                                            <input
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Add Campaign Name"
                                                name='name'
                                                required
                                                value={mergeData.name}
                                                onChange={(e) => setMergeData({ ...mergeData, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <button className="theme-btn" type="submit" style={{ marginTop: "20px", marginLeft: "20px" }}><span> {submitLoader ? <>Merging <FaSpinner className="spin" /></> : "Merge"}</span></button>
                                </form>
                                <div className="editable" style={{ height: "100%" }}>
                                    <div className="d-flex align-items-center justify-content-center flex-column">
                                        <div className={`editor_${dimension === "16x9" ? "16X9" : "9X16"}`} style={{ overflow: "unset", height: "100%", marginTop: "-20px" }}>
                                            <div className="canvasEditor-main" style={{ borderBottomLeftRadius: "0", borderBottomRightRadius: "0" }}>
                                                <div className="video-container">
                                                    <ReactPlayer
                                                        width="100%"
                                                        height="100%"
                                                        onReady={() => setVideoLoader(false)}
                                                        url={currentSlide.url}
                                                        playing={play}
                                                    // muted={true}
                                                    // loop={true}
                                                    />
                                                    {videoLoader ?
                                                        <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", top: 0, width: "100%", height: "100%" }}>
                                                            <FaSpinner className="spin" style={{ fontSize: 50, color: "#00c4a6" }} />
                                                        </div> : null}


                                                </div>

                                            </div>
                                            <div className="playButton text-center">
                                                <i class="fa fa-step-backward playIcon me-2 cursor-pointer" aria-hidden="true" ></i>

                                                {!play ?
                                                    <IoPlay
                                                        className='playIcon2 cursor-pointer'
                                                        onClick={() => setPlay(true)}
                                                        style={{ fontSize: "25px" }}
                                                    /> :
                                                    <IoPause
                                                        className='playIcon2 cursor-pointer'
                                                        onClick={() => setPlay(false)}
                                                        style={{ fontSize: "25px" }}
                                                    />
                                                }

                                                <i class="fa fa-step-forward playIcon ms-2 cursor-pointer" aria-hidden="true" ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-12">
                            <section class="slide-sec-merge">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="slide-box">
                                                <MergeTiles
                                                    mergeVideos={mergeVideos}
                                                    setCurrentSlide={setCurrentSlide}
                                                    setMergeVideos={setMergeVideos}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                </div>
            </div >

            <SweetAlert
                show={sweet.enable}
                message={"If you change resolution you will lose current data."}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={false}
            />

            <Footer />
        </>
    )
}

export default ReelMerge;

