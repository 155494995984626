import React, { useState, useEffect, useRef, useMemo } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import InfiniteScroll from "react-infinite-scroll-component";
import Dropdown from "react-bootstrap/Dropdown";
import { BsFunnel } from "react-icons/bs";
import {
    onFetchFolderDataById,
    onFetchLeadFolder,
    onSearchLeads,
} from "../../../../Redux/Actions/LeadAction";
import { useDispatch } from "react-redux";
import LeadCard from "./LeadCard";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { useSelector } from "react-redux";

const FindLeads = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector(state => state.auth)
    const { fId } = queryString.parse(location.search);

    const [state, setState] = useState({
        fId: "",
        keyword: "",
        latitude: "",
        longitude: "",
        addressName: "",
        next_page: "",
    });
    const locationRef = useRef(null);
    const [loader, setLoader] = useState({
        submit: false,
        fetchMore: false,
    });
    const [filterData, setFilterData] = useState({
        email: false,
        gmbListing: false,
        lowRating: false,
        review: false,
        reviewNumber: 0
    });

    const [leadData, setLeadData] = useState([]);
    const [currentTodo, setCurrentTodo] = useState([]);
    const [searchResult, setSearchResult] = useState("");
    const libraries = useMemo(() => ["places"], []);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBDR_JpjuxybgfEJuciljUjBKtTXOrCFB4",
        libraries: libraries,
    });

    const onLoad = (autocomplete) => {
        setSearchResult(autocomplete);
    };

    const handleChange = (e, type) => {
        const { name, value, checked } = e.target;
        if (type === "filter") {
            if (name === "reviewNumber") {
                setFilterData({ ...filterData, [name]: value });
            } else {
                setFilterData({ ...filterData, [name]: checked });
            }
        } else {
            setState({ ...state, [name]: value });
        }
    };

    const fetchMoreLeads = () => {
        if (fId) {
            return;
        }
        setLoader({ ...loader, fetchMore: true });
        let data = { ...state };
        dispatch(onSearchLeads(data, setLeadData, state, setState, loader, setLoader, "fetchMore"));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (fId) {
            return;
        }
        if (state.latitude && state.longitude) {
            setLeadData([]);
            setLoader({ ...loader, submit: true });
            let data = { ...state };
            data = {
                ...data,
                next_page: "",
                fId: "",
            };
            dispatch(
                onSearchLeads(data, setLeadData, state, setState, loader, setLoader)
            );
        }
    };

    const onPlaceChanged = () => {
        if (searchResult != null) {
            const place = searchResult.getPlace();
            if (place?.geometry?.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                const addressName = locationRef.current.value;
                setState({
                    ...state,
                    latitude: lat,
                    longitude: lng,
                    addressName: addressName,
                });
            }
        }
    };

    useEffect(() => {
        let dataArr = [...leadData];
        const isAnyFilterSelected = Object.values(filterData).some(
            (value) => value === true
        );

        if (isAnyFilterSelected) {
            if (filterData.email) {
                dataArr = dataArr.filter((item) => item?.emails?.length > 0);
            }
            if (filterData.gmbListing) {
                dataArr = dataArr.filter((item) => !item.mapUrl || item.mapUrl === "");
            }
            if (filterData.review) {
                dataArr = dataArr.filter(
                    (item) => +item?.userRatingsTotal > +filterData.reviewNumber
                );
            }
            if (filterData.lowRating) {
                dataArr = dataArr
                    .sort((a, b) => +a.userRatingsTotal - +b.userRatingsTotal)
                    .sort((a, b) => +a.rating - +b.rating);
            }
        }
        setCurrentTodo(dataArr);
    }, [filterData, leadData]);

    useEffect(() => {
        return () => {
            dispatch({
                type: "SET_LEAD_FOLDER_DATA",
                payload: { data: false, loader: true },
            });
            dispatch(onFetchLeadFolder());
        };
    }, []);

    const fetchFolderData = () => {
        setLoader({ ...loader, fetch: true });
        let data = {
            id: fId,
        };
        dispatch(onFetchFolderDataById(data, setLeadData, loader, setLoader));
    };

    useEffect(() => {
        if (fId) {
            fetchFolderData();
        } else {
            setLeadData([]);
        }
    }, [fId]);

    return (
        <>
            <TitleBar title="Find Leads" />

            <div className="site-wrap">
                <div className="site-container">
                    {fId ? (
                        ""
                    ) : (
                        <div className="chatBot-form full mt-0">
                            <h4>Find leads</h4>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="input-outer mt-4">
                                            <input
                                                className="common-inp alt"
                                                name="keyword"
                                                value={state.keyword}
                                                type="text"
                                                placeholder="Enter Keywords"
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="input-outer mt-4">
                                            {isLoaded ? (
                                                <Autocomplete
                                                    onPlaceChanged={onPlaceChanged}
                                                    onLoad={onLoad}
                                                >
                                                    <input
                                                        ref={locationRef}
                                                        className="common-inp alt"
                                                        type="text"
                                                        placeholder="Enter Location"
                                                        required
                                                        onChange={() =>
                                                            setState({
                                                                ...state,
                                                                latitude: "",
                                                                longitude: "",
                                                                addressName: "",
                                                            })
                                                        }
                                                    />
                                                </Autocomplete>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        <div className="input-outer mt-4">
                                            {+auth.user.isClientAccount === 1 ? null :
                                                <button className="theme-btn full" type="submit">
                                                    <span>
                                                        {" "}
                                                        {loader.submit ? (
                                                            <>
                                                                {" "}
                                                                Searching{" "}
                                                                <i className="fa fa-spinner fa-spin ms-1" />
                                                            </>
                                                        ) : (
                                                            "Search"
                                                        )}
                                                    </span>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}

                    <div className="lead-title">
                        {leadData.length > 0 ? (
                            <>
                                <h5>Search result ({leadData.length} Leads)</h5>
                                <div className="widget-top-right">
                                    <Dropdown className="theme-toggle">
                                        <Dropdown.Toggle
                                            variant="theme-btn"
                                            className="theme-btn"
                                            id="dropdown-basic"
                                        >
                                            <span>
                                                <BsFunnel /> Filter
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <li className="filter-opt">
                                                <label className="table-check">
                                                    <input
                                                        type="checkbox"
                                                        name="email"
                                                        onChange={(e) => handleChange(e, "filter")}
                                                    />
                                                    <span className="check-mark"></span>
                                                </label>
                                                Have Emails
                                            </li>
                                            {/* <li className="filter-opt">
                                                <label className="table-check">
                                                    <input
                                                        type="checkbox"
                                                        name="gmbListing"
                                                        onChange={(e) => handleChange(e, "filter")}
                                                    />
                                                    <span className="check-mark"></span>
                                                </label>
                                                Unclaimed GMB Listing
                                            </li> */}
                                            <li className="filter-opt">
                                                <label className="table-check">
                                                    <input
                                                        type="checkbox"
                                                        name="lowRating"
                                                        onChange={(e) => handleChange(e, "filter")}
                                                    />
                                                    <span className="check-mark"></span>
                                                </label>
                                                Low Rating
                                            </li>
                                            <li className="filter-opt">
                                                <label className="table-check">
                                                    <input
                                                        type="checkbox"
                                                        name="review"
                                                        onChange={(e) => handleChange(e, "filter")}
                                                    />
                                                    <span className="check-mark"></span>
                                                </label>
                                                Reviews <span className="inputArrow">{`>`}</span>
                                                <input
                                                    type="number"
                                                    name="reviewNumber"
                                                    className="reviewsBox"
                                                    value={filterData.reviewNumber}
                                                    onChange={(e) => handleChange(e, "filter")}
                                                />
                                            </li>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>

                    <div
                        className="lead-result"
                        id="scrollableLeadDiv"
                        style={{ maxHeight: "655px", overflowX: "auto", overflowY: "auto" }}
                    >
                        <InfiniteScroll
                            dataLength={leadData.length}
                            next={() => fetchMoreLeads()}
                            hasMore={(!filterData.email && !filterData.gmbListing && !filterData.lowRating && !filterData.review) ? true : false}
                            scrollableTarget="scrollableLeadDiv"
                        >
                            <div className="row">
                                {currentTodo.length > 0 ? (
                                    currentTodo.map((curElem, index) => {
                                        curElem = { ...curElem, openingHours: curElem.openingHours ? JSON.parse(curElem.openingHours) : [], };
                                        curElem = { ...curElem, tag: curElem.tag ? JSON.parse(curElem.tag) : [] };
                                        curElem = { ...curElem, photos: curElem.photos ? JSON.parse(curElem.photos) : [], };
                                        curElem = { ...curElem, review: curElem.review ? JSON.parse(curElem.review) : [], };

                                        if (fId) {
                                            curElem = { ...curElem, contact: curElem.contact ? JSON.parse(curElem.contact) : [], };
                                            curElem = { ...curElem, videoLink: curElem.videoLink ? JSON.parse(curElem.videoLink) : [], };
                                            curElem = { ...curElem, emails: curElem.emails ? JSON.parse(curElem.emails) : [], };
                                        }
                                        return (
                                            <React.Fragment key={index}>
                                                <LeadCard
                                                    leadData={leadData}
                                                    setLeadData={setLeadData}
                                                    curElem={curElem}
                                                />
                                            </React.Fragment>
                                        );
                                    })
                                ) : (
                                    <div className="text-center">
                                        {leadData.length > 0 ? (
                                            "No data found"
                                        ) : loader.fetch ? (
                                            <i
                                                className="fa fa-spinner fa-spin mr-2 my-2 spin-style"

                                            />
                                        ) : fId ? (
                                            <div className="text-center mt-5">
                                                <span>No data found</span>
                                                <br />
                                                <Link
                                                    to={`/lead/manage-leads`}
                                                    className="theme-btn mx-2"
                                                    style={{ width: "130px" }}
                                                >
                                                    {" "}
                                                    <span>
                                                        {" "}
                                                        <GoArrowLeft />
                                                        Back
                                                    </span>
                                                </Link>
                                            </div>
                                        ) : (
                                            "Please enter keyword or location to search leads."
                                        )}
                                    </div>
                                )}
                            </div>
                        </InfiniteScroll>
                        {loader.fetchMore ? (
                            <div className="text-center">
                                <i
                                    className="fa fa-spinner fa-spin mr-2 my-2 spin-style"
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FindLeads;
