import React, { useEffect, useState } from 'react'
import LeftPanel from './LeftPanel'
import ReviewCard from './ReviewCard'
import { onFetchSettingsData } from '../../../../Redux/Actions/ReviewAction'
import { useDispatch } from 'react-redux'

const Reviews = ({ currentTodo, reviewData, filterData, setFilterData, loader, integrationId }) => {

    const dispatch = useDispatch()
    const [state, setState] = useState({
        autoReply: [],
        phoneNumbers: [],
        NotificationEmail: [],
        contactEmails: []
    })

    const fetchSettingsData = () => {
        let data = {
            id: integrationId,
        }
        dispatch(onFetchSettingsData(data, state, setState, false, false))
    }
    useEffect(() => {
        if (integrationId) {
            fetchSettingsData();
        }
    }, [integrationId])

    return (
        <div className="review-container">
            <div className="review-left">
                <LeftPanel
                    reviewData={reviewData}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </div>
            <div className="review-right review_right_warp">
                {currentTodo.length > 0 ?
                    currentTodo.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ReviewCard
                                    curElem={curElem}
                                    emails={state.contactEmails}
                                    phones={state.phoneNumbers}
                                    integrationId={integrationId}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className="text-center">
                        {loader.fetch ? <i className="fa fa-spin fa-spinner spin-style"  /> : "No review found."}
                    </div>
                }
            </div>
        </div>
    )
}

export default Reviews
