import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from '../../CommonComponents/SweetAlert';
import {  deleteSocialAccounts } from '../../../Redux/Actions/SocialActions';
import ConnectModal from './ConnectModal';

const ConnectReel = (props) => {

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [sweet, setSweet] = useState({
    enable: false,
    id: false,
    confirmButtonName: "Delete",
    loader: false
  })

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)


  const deleteIntegration = (id) => {
    setSweet({
      ...sweet,
      enable: true,
      id: id
    })
  }

  const onConfirm = () => {
    setSweet({
      ...sweet,
      confirmButtonName: "Deleting",
      loader: true
    })
    let data = {
      id: sweet.id,
      user_id: auth.user.id
    }
    dispatch(deleteSocialAccounts("delete-integration", data, setSweet))
  }

  const onCancel = () => {
    setSweet({
      ...sweet,
      enable: false,
      id: false,
      confirmButtonName: "Delete",
      loader: false
    })
  }


  return (

    <div className="mt-3" id={props.type}>
      {
        props.data.length > 0 ?
          <div className="connection-single" style={{ display: "block" }}>
            {
              props.data.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center mb-4">
                      <div className="connection-left">
                        <div className={`connection-logo ${props.data.length > 0 ? '' : 'disable-img'}`}>
                          <img src={props.icon} width="56px" alt="facebook" />
                        </div>
                        <div>
                          <p>{props.name}</p>
                          <p className="m-0">{item?.username} - Added on {item.created}</p>
                        </div>
                      </div>

                      <div className="facebook-delete">
                        <button type="button" onClick={(e) => deleteIntegration(item.id)} className="connection-btn connected"><span>Disconnect</span></button>
                      </div>
                    </div>
                    {index === props.data.length - 1 ?
                      <div className="connection-right facebook-connect-account d-flex justify-content-center">
                        <button className='connection-btn connected' onClick={handleShow}> <span>Connect New Account</span></button>
                      </div> : ""}
                  </React.Fragment>
                )
              })}
          </div>
          :
          <div className="connection-single " style={{flexDirection : "row"}}>
            <div className="connection-left">
              <div className={`connection-logo ${props.data.length > 0 ? '' : 'disable-img'}`}>
                <img src={props.icon} className={props.data.length > 0 ? "" : "disable"} width="56px" alt="facebook" />
              </div>
              <p>{props.name}</p>
            </div>
            <div className="connection-right">
              <div className="connection-right">
                <button className='connection-btn connected' onClick={handleShow}><span>Connect</span> </button>
              </div>
            </div>
          </div>
      }

      <ConnectModal
        show={show}
        handleClose={handleClose}
        name={props.name}
        type={props.type}
      />

      <SweetAlert
        show={sweet.enable}
        message={`Are you sure? <br><br> Do you want to delete this ${props.type} account?`}
        confirmButtonColor={""}
        cancelButtonColor={""}
        confirmButtonName={sweet.confirmButtonName}
        cancelButtonName={"Cancel"}
        alertLoader={sweet.loader}
        handleClose={onCancel}
        performDelete={onConfirm}

      />

    </div>
  )
}

export default ConnectReel