import React, { useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { BsXLg } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onActiveOptinStyle, onDeactiveStyle, onOptinChangeText, onSubmitOptin } from '../../../../../Redux/Actions/WidgetActions'
import { setAlert } from '../../../../../Redux/Actions/AlertActions'

const FloatingWindow = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const optin = useSelector(state => state.widget.optinData)

    const [loader, setLoader] = useState(false)

    const handleClick = (e, type) => {
        e.stopPropagation()
        if (optin.activeStyle !== type) {
            dispatch(onActiveOptinStyle(type))
        }
    }

    const handleChange = (e) => {
        if (optin.activeStyle === "headline" && e.target.value.length > 60) {
            dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
        } else if (optin.activeStyle === "description" && e.target.value.length > 150) {
            dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
        } else {
            dispatch(onOptinChangeText(optin.activeStyle, e.target.value))
        }
    }

    const handleSubmit = () => {
        let data = {
            ...optin
        }
        setLoader(true)
        dispatch(onSubmitOptin(data, navigate, setLoader))
    }

    const handleCloseStyle = () => {
        if (optin.activeStyle) {
            dispatch(onDeactiveStyle("optin"))
        }
    }

    return (
        <div className={`widget-prev-outer ${optin.data.position === "top" ? "top expand" : optin.data.position === "center" ? "" : optin.data.position === "right" ? "right" : optin.data.position === "b-right" ? "box" : ""}`} onClick={handleCloseStyle}>
            <div className="widget-create-button">
                <button className="theme-btn" onClick={handleSubmit}><span>{loader ? <>Generating <i className='fa fa-spin fa-spinner' /></> : "Generate Widget"}</span></button>
            </div>
            {optin.data.position !== "top" ?
                <div className="modal-arrow">
                    <p>Click on any widget element to edit it. <BsXLg /> </p>
                    <img src={require('../../../../../assets/images/review/modal_arrow.png')} alt="" />
                </div> : null}
            <div className={`widget-prev animate__animated ${optin.data.animation}`} style={{ 
                background: optin.data.background.type === "color" ? optin.data.background.src : `url(${optin.data.background.src})`,
                border: optin.data.border.enable ? `2px solid ${optin.data.border.color}` : "" }}>

                <div className="widgetModal-close2" onClick={props.handleClose}><IoClose /></div>
                <div className={`${optin.data.position === "top" ? "widegt_top_wrap" : ""}`}>
                    {optin.data.image.enable && optin.data.image.url ?
                        <div className=" widget_img_wrapper mb-2">
                            <img src={optin.data.image.url} className="img-fluid" />
                        </div> : null}
                    {/* widget_image */}
                    <div>

                        {optin.data.headline.enable ?
                            <>
                                <ContentEditable
                                    html={optin.data.headline.text}
                                    tagName={"h6"}
                                    onChange={handleChange}
                                    onClick={(e) => handleClick(e, "headline")}
                                    style={{ ...optin.data.headline.css, cursor: "pointer", border: optin.activeStyle === "headline" ? "2px dashed #dcdcdc" : "" }}
                                />
                            </>
                            : null
                        }
                        {optin.data.description.enable ?
                            <ContentEditable
                                html={optin.data.description.text}
                                tagName={"p"}
                                onChange={handleChange}
                                onClick={(e) => handleClick(e, "description")}
                                style={{ ...optin.data.description.css, cursor: "pointer", border: optin.activeStyle === "description" ? "2px dashed #dcdcdc" : "" }}
                            />
                            : null}
                    </div>
                </div>
                <div className={`${optin.data.position === "top" ? "widget-prev-topBtn widget-prev-button full" : "widget-prev-button full"}`}>
                    <ul>
                        {optin.data.name.enable ?
                            <li style={{ border: optin.activeStyle === "name" ? "2px dashed #dcdcdc" : "" }}>
                                <input
                                    className="widget-input"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={handleChange}
                                    onClick={(e) => handleClick(e, "name")}
                                    value={optin.data.name.text}
                                    style={{ ...optin.data.name.css, cursor: "pointer" }}
                                />
                            </li> : null}
                        {optin.data.email.enable ?
                            <li style={{ border: optin.activeStyle === "email" ? "2px dashed #dcdcdc" : "" }}>
                                <input
                                    className="widget-input"
                                    type="text"
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                    onClick={(e) => handleClick(e, "email")}
                                    value={optin.data.email.text}
                                    style={{ ...optin.data.email.css, cursor: "pointer" }}
                                />
                            </li> : null}
                        {optin.data.phone.enable ?
                            <li style={{ border: optin.activeStyle === "phone" ? "2px dashed #dcdcdc" : "" }}>
                                <input
                                    className="widget-input"
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    onChange={handleChange}
                                    onClick={(e) => handleClick(e, "phone")}
                                    value={optin.data.phone.text}
                                    style={{ ...optin.data.phone.css, cursor: "pointer" }}
                                />
                            </li> : null}
                        {optin.data.review.enable ?
                            <li style={{ border: optin.activeStyle === "review" ? "2px dashed #dcdcdc" : "" }}>
                                <textarea
                                    className="widget-input"
                                    placeholder="Enter Your Review"
                                    onChange={handleChange}
                                    onClick={(e) => handleClick(e, "review")}
                                    value={optin.data.review.text}
                                    style={{ ...optin.data.review.css, cursor: "pointer" }}
                                />
                            </li> : null}
                        <li>
                            <button type='button' className="submit-btn"><span>Submit</span></button>
                        </li>
                    </ul>
                </div>
            </div>

            {optin.data.position === "top" ?
                <div className="modal-arrow">
                    <img src={require('../../../../../assets/images/review/modal_arrow.png')} alt="" />
                    <p>Click on any widget element to edit it. <BsXLg /> </p>
                </div>
                : null}
        </div>

    )
}

export default FloatingWindow