import React from 'react'

const SocialIcons = (props) => {
    return (
        <div className={`${props.status ? '' : 'disable'} cursor-pointer icon_hover ${props.selectIcon}`}  >
            <div className="img-wrraper">
                <img src={props.icon} alt={props.name} width="56px" />
            </div>
            <p className="mt-1 m-0" style={{ color: props.selectIcon ? "#fff" : "" }}>{props.name}</p>
        </div>
    )
}

export default SocialIcons