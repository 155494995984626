import React, { useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import WidgetModal from "./StyleComponent/WidgetModal";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { onChange, onDeactiveStyle, onFetchWidget, removeWebsiteWidget } from "../../../../Redux/Actions/WidgetActions";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import WidgetStyleWindow from "../WidgetStyleWindow";

const WidgetStyle = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const widget = useSelector(state => state.widget.data)
    const [loader, setLoader] = useState(true);
    const [show, setShow] = useState(false);
   
    const handleShow = () => setShow(true);

    const handleOpenPopup = (type) => {
        dispatch(onChange("position", type))
        handleShow()
    }

    const fetchWidget = () => {
        let data = { id }
        dispatch(onFetchWidget(data, setLoader))
    }

    const handleClose = () => {
        dispatch(onDeactiveStyle("website"))
        setShow(false)
    }

    useEffect(() => {
        if (id) {
            fetchWidget()

            return () => {
                dispatch(removeWebsiteWidget())
            }
        }
    }, [id])


    return (
        loader ?
            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
            :
            <>
                <TitleBar title="Review Inbox" />

                <WidgetStyleWindow
                    handleOpenPopup={handleOpenPopup}
                    position={widget.data.position}
                    title={"Review Gathering Widget"}
                    description={"Select a widget style below."}
                />

                <WidgetModal
                    pos={widget.data.position}
                    show={show}
                    handleClose={handleClose}
                />

            </>
    )
}

export default WidgetStyle;