import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FiPlay, FiSkipForward, FiSkipBack } from 'react-icons/fi'
import { BsPlayCircle, BsStopCircle } from 'react-icons/bs'
import { onChangeBackgroundInterval, onPlay, onRemovePlaySlide, updateSelectedTiles } from '../../../../Redux/Actions/VideoActions'
import { FaSpinner } from 'react-icons/fa'


let voice = false, music = false, clearPlay = false, newIntervalId = false, bgSlideInterval = false
const SlidePlayPause = ({ setSecond, playStatus, setPlayStatus, timer, setTimer }) => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")

    const [playLoader, setPlayLoader] = useState(false)

    const handleForwardSlide = () => {
        if (campaign.slides.length - 2 >= selectedSlideIndex) {
            dispatch(updateSelectedTiles(selectedSlideIndex + 1))
        }
    }

    const handleBackwardSlide = () => {
        if (selectedSlideIndex > 0) {
            dispatch(updateSelectedTiles(selectedSlideIndex - 1))
        }
    }

    const handlePlay = () => {
        const bgMusic = campaign.bgAudio
        const voiceData = selectedSlide.audio
        if (voiceData.enable) {
            setPlayLoader(true)
            voice = new Audio(voiceData.src)
            voice.volume = voiceData.volume / 100
            voice.play().then(() => {
                setPlayLoader(false)
                setPlayStatus(true)
                if (bgMusic.enable) {
                    music = new Audio(bgMusic.source)
                    music.volume = bgMusic.volume / 100
                    music.play()
                }
                let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                newIntervalId = setInterval(() => {
                    setSecond(prevSec => prevSec + 1)
                }, 1000);

                clearPlay = setTimeout(() => {
                    handlePause()
                }, durToPlay * 1000)
                dispatch(onPlay(selectedSlideIndex))
            })
        }
        else {
            if (bgMusic.enable) {
                music = new Audio(bgMusic.source)
                music.volume = bgMusic.volume / 100
                music.play().then(() => {
                    setPlayStatus(true)
                    setPlayLoader(true)
                    let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                    newIntervalId = setInterval(() => {
                        setSecond(prevSec => prevSec + 1)
                    }, 1000);

                    clearPlay = setTimeout(() => {
                        handlePause()
                    }, durToPlay * 1000)
                    dispatch(onPlay(selectedSlideIndex))
                })
            }
            else {
                setPlayStatus(true)
                let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

                newIntervalId = setInterval(() => {
                    setSecond(prevSec => prevSec + 1)
                }, 1000);

                clearPlay = setTimeout(() => {
                    handlePause()
                }, durToPlay * 1000)
                dispatch(onPlay(selectedSlideIndex))
            }

        }
        bgSlideInterval = setInterval(() => {
            dispatch(onChangeBackgroundInterval(selectedSlideIndex))
        }, 7000)
    }

    const handlePause = () => {
        setPlayLoader(false)
        setSecond(0)
        setTimer({
            ...timer,
            h: 0,
            m: 0
        })
        if (voice) {
            voice.pause()
            voice = false
        }
        if (music) {
            music.pause()
            music = false
        }
        clearTimeout(clearPlay)
        clearInterval(newIntervalId)
        clearInterval(bgSlideInterval)
        setPlayStatus(false)
        dispatch(onRemovePlaySlide(selectedSlideIndex))
    }

    useEffect(() => {
        if (playStatus) {
            handlePause()
        }
    }, [selectedSlideIndex])

    useEffect(() => {
        return () => {
            if (voice) {
                voice.pause()
                voice = false
            }
            if (music) {
                music.pause()
                music = false
            }
            clearTimeout(clearPlay)
            clearInterval(newIntervalId)
            clearInterval(bgSlideInterval)
        }
    }, [])

    return (
        <>
            <span onClick={handleBackwardSlide} title="Backward Slide" style={{ cursor: "pointer" }}>
                <FiSkipBack className='whiteFont' />
            </span>
            <span className='mx-1'>
                {playStatus ?
                    <span onClick={handlePause} title="Stop" style={{ cursor: "pointer" }}>
                        <BsStopCircle className='whiteFont' />
                    </span>
                    :
                    playLoader ?
                        <FaSpinner className='spin' />
                        :
                        <span onClick={handlePlay} title="Play" style={{ cursor: "pointer" }} >
                            <BsPlayCircle className='whiteFont' />
                        </span>
                }
            </span>
            <span onClick={handleForwardSlide} title="Forward Slide" style={{ cursor: "pointer" }}>
                <FiSkipForward className='whiteFont' />
            </span>

        </>
    )
}

export default SlidePlayPause