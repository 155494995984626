import React, { useEffect, useState } from "react";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from "react-redux";
import { addBgMusic } from "../../../../Redux/Actions/VideoActions";
import { fetchMedia } from "../../../../Redux/Actions/CommonActions";
import AudioTrack from "../../../CommonComponents/AudioTrack";
import { FaSpinner } from "react-icons/fa";
import UploadMusic from "./UploadMusic";
import InfiniteScroll from "react-infinite-scroll-component";

const Audio = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")

    const [selectedAudio, setSelectedAudio] = useState(false)
    const [pageCount, setPageCount] = useState(1);
    const [audio, setAudio] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })



    const addMusic = (url, duration) => {
        dispatch(addBgMusic(url, selectedSlideIndex, duration))
    }

    const fetchAudios = (page = 1) => {
        let data = {
            search_type: 'music',
            page_number: page,
            keyword: ''
        }
        setPageCount(page)
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchMedia("load-library-audio", data, audio, setAudio, loader, setLoader, false))
    }

    useEffect(() => {
        fetchAudios()
    }, [])

    useEffect(() => {
        setSelectedAudio(campaign.bgAudio.source)
    }, [campaign.bgAudio.source])

    return (
        <>
            <h2 className="tabTitle">Audio</h2>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <Nav variant="b" className="innerTab mt-0">
                    <Nav.Item>
                        <Nav.Link eventKey="first">Library Audio</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="second">Upload Audio</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <div className="audio-scroll" id='scrollableAudio123'>
                            <InfiniteScroll
                                dataLength={audio.length} //This is important field to render the next data
                                next={() => fetchAudios(pageCount + 1)}
                                hasMore={true}
                                scrollableTarget="scrollableAudio123"
                            >
                                <div className="audio-list">
                                    {audio.length > 0 ?
                                        audio.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <AudioTrack
                                                        name={curElem.title}
                                                        url={curElem.url}
                                                        type="music"
                                                        fetchAudios={fetchAudios}
                                                        selectedAudio={selectedAudio}
                                                        setSelectedAudio={setSelectedAudio}
                                                        onSelectAudio={addMusic}
                                                        key={index}
                                                        id={curElem.id}
                                                    />
                                                </React.Fragment>
                                            )
                                        }) :
                                        ""

                                    }
                                    {loader.fetch ?
                                        <div className='text-center mt-2' style={{ height: 35 }} > <FaSpinner className="spin spin-style"  /> </div>
                                        : ""}
                                </div>

                            </InfiniteScroll>
                        </div>

                    </Tab.Pane>

                    <Tab.Pane eventKey="second">

                        <UploadMusic
                            type="music"
                            onSelectAudio={addMusic}
                            selectedAudio={selectedAudio}
                            setSelectedAudio={setSelectedAudio}
                        />

                    </Tab.Pane>

                </Tab.Content>

            </Tab.Container>
        </>
    )
}

export default Audio;