import React, { useEffect, useState } from 'react'
import ContactRow from './ContactRow'
import { useDispatch } from 'react-redux';
import { onFetchEmailCampaigns } from '../../../../Redux/Actions/LeadAction';

const ContactTable = () => {

    const dispatch = useDispatch();
    const [contactLeadData, setContactLeadData] = useState([]);
    const [loader, setLoader] = useState({
        fetch: false
    });

    const fetchEmailCampaigns = () => {
        setLoader({ ...loader, fetch: true });
        let data = {}
        dispatch(onFetchEmailCampaigns(data, setContactLeadData, loader, setLoader))
    }

    useEffect(() => {
        fetchEmailCampaigns();
    }, [])

    return (
        <div className="table-area mt-3">
            <div className="table-responsive">
                <table className="table widget-table">
                    <tr>
                        <th>S.NO</th>
                        <th>Subject</th>
                        <th>SMTP/Twilio From</th>
                        <th>Contact Type</th>
                        <th className="text-end">Action</th>
                    </tr>

                    {contactLeadData.length > 0 ?
                        contactLeadData.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <ContactRow
                                        curElem={curElem}
                                        index={index}
                                        contactLeadData={contactLeadData}
                                        setContactLeadData={setContactLeadData}
                                    />
                                </React.Fragment>
                            )
                        })
                        : <tr>
                            <td className="text-center" colSpan={5}>
                                {loader.fetch ? <i className="fa fa-spin fa-spinner spin-style" /> : "No campaigns created yet."}
                            </td>
                        </tr>
                    }
                </table>
            </div>
        </div>
    )
}

export default ContactTable
