import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import rgbHex from 'rgb-hex'

const WidgetColor = (props) => {
    const [show, setShow] = useState(false)

    const handleChange = (e) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
       props.handleChangeColor(hex, props.type)
    }

    return (
        <div className="input-outer" style={{ position: "relative" }}>
            <input
                className="common-inp alt"
                type="text"
                placeholder="#ffffff"
                value={props.color}
                readOnly
            />
            <span className='widget-color-box' style={{background : props.color}} onClick={() => setShow(true)} />
            {show ?
                <>
                    <div
                        style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                        onClick={(e) => setShow(false)}
                    />
                    <SketchPicker color={props.color} onChange={(e) => handleChange(e)} />
                </>
                : null
            }
        </div>
    )
}

export default WidgetColor