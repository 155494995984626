import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { loginUser, masterLoginUser } from "../../../Redux/Actions/AuthActions";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";
import { appName } from "../../../Global/Global";

const MasterLogin = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [type, setType] = useState("password")
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: "",
        useremail: ""
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(masterLoginUser(loginInfo, setLoader, navigate))
    }

    const handleType = () => {
        setType("text")
        setTimeout(() => {
            setType("password")
        }, 2000)
    }
    return (
        <>
            <TitleBar title="Master Login" />
            <div className="login-header">
                <LoginHeader />
            </div>

            <div className="login-wrap">

                <div className="login-left">
                    <div className="welcome-txt">
                        <h2><span>Welcome To </span>{appName}</h2>
                        <p>Revolutionise your online reputation management with our AI-driven tool. Automate, enhance, and influence your business's online presence effectively. Begin your journey to a transformative digital reputation now!</p>
                        <h3>..it's Super Easy-to-use</h3>
                    </div>
                </div>

                <div className="login-right">
                    {/* <div className="symbol"><RiRobot2Fill/></div> */}

                    <div className="login-right-main">
                        <h2><span>Master Login</span> to {appName}</h2>

                        <div className="login-form">
                            <form onSubmit={handleSubmit}>
                                <div className="input-wrap">
                                    <label htmlFor="">User Email Address</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type="email"
                                            placeholder="Email Address"
                                            value={loginInfo.useremail}
                                            name="useremail"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Admin Email Address</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type="email"
                                            placeholder="Email Address"
                                            value={loginInfo.email}
                                            name="email"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Admin Passoword</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type={type}
                                            onChange={handleChange}
                                            value={loginInfo.password}
                                            name="password"
                                            placeholder='Password'
                                            required
                                        />
                                        <span className="inp-icon" style={{ cursor: "pointer" }} onClick={handleType}><AiOutlineEyeInvisible /></span>
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <button className="theme-btn full" type="submit"><span>Login {loader ? <FaSpinner className="spin" /> : ""}</span></button>
                                </div>
                            </form>
                        </div>

                        <div className="log-option text-center">Forgot your account details? <Link to='/forgot-password'>Click here</Link></div>
                    </div>


                </div>

            </div>

            <div className="login-footer"><Footer /></div>
        </>
    )
}

export default MasterLogin;