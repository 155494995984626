import React, { useEffect, useState } from 'react'
import { BsImages } from 'react-icons/bs'
import { FaRegTrashCan } from 'react-icons/fa6'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { onDeleteCampaign, onUploadMedia } from '../../../../../Redux/Actions/CommonActions'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { themeColor } from '../../../../../Global/Global'

const PostImages = ({ photos, setPhotos }) => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState({
        upload: false
    })
    const [percent, setPercent] = useState("")
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: sweet.id
        }
        dispatch(onDeleteCampaign("", data, photos, setPhotos, setSweet))
    }

    const handleUploadedImage = (url) => {
        let arr = [...photos]
        arr = arr.concat(url)
        setPhotos(arr)
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const deleteRow = (url) => {
        setSweet({
            ...sweet,
            id: url,
            enable: true,
        })
    }

    const onInputChange = (e) => {
        let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    const formData = new FormData()
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, handleUploadedImage, loader, setLoader, setPercent, true))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    return (
        <div className='input-wrap'>
            <label htmlFor="">Photos</label>
            <div className="photoWrap">
                <div className="photo-row">
                    {photos.length > 0 ?
                        photos.map((url, index) => {
                            return (
                                <div className='photo-col' key={index}>
                                    <span className='photo-col-del' onClick={() => deleteRow(url)}><FaRegTrashCan size={14} /></span>
                                    <div className='photo-col-img'><img src={url} alt="" /></div>
                                </div>
                            )
                        })
                        : ""
                    }
                    <div className='photo-col' >
                        <input type="file" onChange={onInputChange} style={{cursor:"pointer"}}/>
                        <BsImages />
                        <p>Upload Photo</p>
                        {loader.upload ? <i className="fa fa-spinner fa-spin mt-3" style={{ fontSize: "13px", color: themeColor }} /> : ""}
                    </div>

                </div>

            </div>

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br><br> Do you want to delete this media?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </div>
    )
}

export default PostImages
