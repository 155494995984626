import React, { useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import CommonWysiwyg from "../../../CommonComponents/CommonWysiwyg";
import { useSelector } from "react-redux";
import { onFetchAiEmailDetails, onGenerateAiEmail, onSaveAiEmail } from "../../../../Redux/Actions/LeadAction";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { themeColor } from "../../../../Global/Global";
import Select from 'react-select';

const CreateAIEmail = () => {

    const dispatch = useDispatch();
    const location = useLocation()
    const navigator = useNavigate()
    const { id } = queryString.parse(location.search)
    const leadFolderData = useSelector((state) => state.lead.data);
    const [emailOptions, setEmailOptions] = useState([]);

    const [state, setState] = useState({
        fId: "",
        leadId: "",
        category: "",
        phoneNumber: "",
        email: [],
        customEmailInput: "",
        operation: "",
        searchServiceKeyword: "",
        subject: "",
        body: "",
    });

    const [currentLeadData, setCurrentLeadData] = useState(false)

    const [loader, setLoader] = useState({
        fetch: false,
        generate: false,
        submit: false
    });

    const onChangeLead = (e) => {
        let currentFolder = leadFolderData.find((folder) => +folder.fId === +state.fId)
        let currentLead = currentFolder.leads.find((lead) => +lead.id === +e.target.value)
        setCurrentLeadData(currentLead)
        handleChange(e)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }

    const dataFunction = (value, name) => {
        setState({ ...state, [name]: value })
    }

    const handleEmailInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setState({ ...state, customEmailInput: inputValue });
        return inputValue;
    };

    const handleEmailKeyDown = (event) => {
        if (event.key === 'Enter' && state.customEmailInput.trim() !== '') {
            event.preventDefault();
            const newEmailOption = { value: state.customEmailInput.toLowerCase(), label: state.customEmailInput };
            setEmailOptions([...emailOptions, newEmailOption]);
            setState({ ...state, email: [...state.email, newEmailOption], customEmailInput: '' });
        }
    };

    const generateAiEmail = () => {
        if (state.searchServiceKeyword) {
            setLoader({ ...loader, generate: true })
            let data = {
                keyword: state.searchServiceKeyword
            }
            dispatch(onGenerateAiEmail(data, state, setState, loader, setLoader))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, submit: true })
        let data = { ...state }
        data = {
            ...data,
            email: state.email.map((ele) => ele.value)
        }
        dispatch(onSaveAiEmail(data, loader, setLoader, navigator))
    }

    const fetchAiEmailDetails = () => {
        setLoader({ ...loader, fetch: true });
        let data = {
            id: id
        }
        dispatch(onFetchAiEmailDetails(data, setState, loader, setLoader))
    }

    useEffect(() => {
        if (currentLeadData) {
            let emailOptions = []
            if (currentLeadData.emails.length > 0) {
                emailOptions = JSON.parse(currentLeadData.emails).map((ele) => {
                    return (
                        { value: ele, label: ele }
                    )
                })
            }
            setEmailOptions(emailOptions)
            setState({
                ...state,
                category: currentLeadData.businessName || "",
                phoneNumber: currentLeadData.phoneNumber || "",
                email: emailOptions || [],
                operation: currentLeadData.businessStatus || ""
            })
        }
    }, [currentLeadData])

    useEffect(() => {
        if (id) {
            fetchAiEmailDetails()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
            :
            <>
                <TitleBar title="Ai Email" />

                <div className="site-wrap">
                    <div className="site-container">
                        <div className="widget-top">
                            <div className="widget-top-left">
                                <h6>Ai Email</h6>
                            </div>
                        </div>

                        <div className="bulk-wrap">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="input-outer mt-4">
                                            <label className="pb-2" htmlFor="">Choose a folder to which you need to send the email</label>
                                            <select
                                                className="common-inp alt"
                                                name="fId"
                                                value={state.fId}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Folder</option>
                                                {leadFolderData.length > 0 ?
                                                    leadFolderData.map((curElem, index) => {
                                                        return (
                                                            <option key={index} value={curElem.fId}>{curElem.folderName}</option>
                                                        )
                                                    })
                                                    : ""
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="input-outer mt-4">
                                            <label className="pb-2" htmlFor="">Select lead</label>
                                            <select
                                                className="common-inp alt"
                                                name="leadId"
                                                value={state.leadId}
                                                onChange={onChangeLead}
                                            >
                                                <option value="">Select Lead</option>
                                                {leadFolderData.length > 0 ?
                                                    leadFolderData.filter((folder) => {
                                                        return +folder.fId === +state.fId
                                                    }).map((currentFolder) =>
                                                        currentFolder.leads.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.id}>{curElem.businessName}</option>
                                                            )
                                                        })
                                                    )
                                                    : ""
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                                        <hr style={{ color: themeColor, opacity: "1" }} />
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="input-outer mt-4">
                                            <label className="pb-2" htmlFor="">Name</label>
                                            <input
                                                type="text"
                                                className="common-inp alt"
                                                placeholder="Enter name"
                                                name="category"
                                                value={state.category}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="input-outer mt-4">
                                            <label className="pb-2" htmlFor="">Phone</label>
                                            <input
                                                type="text"
                                                className="common-inp alt"
                                                placeholder="Enter phone"
                                                name="phoneNumber"
                                                value={state.phoneNumber ? state.phoneNumber : "N/A"}
                                                onChange={handleChange}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="input-outer mt-4">
                                            <label className="pb-2" htmlFor="">Operation</label>
                                            <input
                                                type="text"
                                                className="common-inp alt"
                                                placeholder="Enter operation"
                                                name="operation"
                                                value={state.operation}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-outer mt-4">
                                            <label className="pb-2" htmlFor="">Email</label>
                                            {/* <input
                                                type="text"
                                                className="common-inp alt"
                                                placeholder="Enter email"
                                                name="email"
                                                value={state.email}
                                                onChange={handleChange}
                                            /> */}
                                            <Select
                                                className="common-inp alt"
                                                closeMenuOnSelect={true}
                                                isMulti
                                                options={emailOptions}
                                                value={state.email}
                                                isDisabled={true}
                                                onChange={(e) => dataFunction(e, "email")}

                                                inputValue={state.customEmailInput}
                                                onInputChange={handleEmailInputChange}
                                                onKeyDown={handleEmailKeyDown}
                                                placeholder="N/A"
                                            />
                                        </div>
                                    </div>

                                    <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                                        <hr style={{ color: themeColor, opacity: "1" }} />
                                    </div>
                                </div>


                                <div className="input-outer mt-4">
                                    <div className="row">
                                        <label className="pb-2" htmlFor="">What type of service you want to sell</label>
                                        <div className="col-lg-9">
                                            <input
                                                type="text"
                                                className="common-inp alt"
                                                placeholder="Enter service"
                                                name="searchServiceKeyword"
                                                value={state.searchServiceKeyword}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <button type="button" className="theme-btn full" onClick={generateAiEmail}><span>{loader.generate ? <> Generating Email <i className="fa fa-spinner fa-spin ms-1" /></> : "Generate Email"}</span></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="input-outer mt-4">
                                        <label className="pb-2" htmlFor="">Please enter subject</label>
                                        <input
                                            type="text"
                                            className="common-inp alt"
                                            placeholder="Enter subject"
                                            name="subject"
                                            value={state.subject}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <CommonWysiwyg
                                    text={state.body.replaceAll("\n", "<br>")}
                                    name={"body"}
                                    dataFunction={dataFunction}
                                />

                                <div className="input-outer mt-4">
                                    <div className="row justify-content-end">
                                        <div className="col-lg-3 text-end">
                                            <button type="submit" className="theme-btn full"><span>{loader.submit ? <> Saving <i className="fa fa-spinner fa-spin ms-1" /></> : "Save"}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default CreateAIEmail;