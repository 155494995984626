import React from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import ContactTable from "./ContactTable";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ContactLeads = () => {
    const auth = useSelector(state => state.auth)
    return (
        <>
            <TitleBar title="Contact Leads" />

            <div className="site-wrap">
                <div className="site-container">
                    <section>
                        <div className='d-flex justify-content-between w-100 h-100 align-items-center pt-5 pb-3' >
                            <h5>Your contact leads campaigns</h5>
                            {+auth.user.isClientAccount === 1 ? null :
                                <Link to={`/lead/create-contact-leads`} className='theme-btn'>
                                    <span>Create contact lead</span>
                                </Link>
                            }
                        </div>
                        <ContactTable />
                    </section>
                </div>
            </div>
        </>
    )
}

export default ContactLeads;