import React, { useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import { Link, useLocation } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { onFetchReviewByLocation, onGetGoogleReview } from "../../../../Redux/Actions/ReviewAction";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { IoSettingsOutline } from "react-icons/io5";
import Reviews from "./Reviews";
import { themeColor } from "../../../../Global/Global";

const ReviewInbox = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const socialYoutube = useSelector((state) => state.social.google)
    const { id } = queryString.parse(location.search);
    const [integrationId, setIntegrationId] = useState(id);
    const [locationData, setLocationData] = useState({
        location: [],
        accountId: "",
        value: ""
    });
    const [reviewData, setReviewData] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [filterData, setFilterData] = useState({
        stars: "",
        date: "",
        ratingsSort: "",
        reviewSentiment: "",
        fromDate: "",
        toDate: ""
    })
    const [currentTodo, setCurrentTodo] = useState([])

    const fetchReviewData = () => {
        setLoader({ ...loader, fetch: true })
        if (socialYoutube.length > 0) {
            let currentGoogleAccount = socialYoutube.find((val) => +val.id === +integrationId)
            if (currentGoogleAccount) {
                let data = {
                    id: currentGoogleAccount.id,
                    token: currentGoogleAccount.data.token
                }
                dispatch(onGetGoogleReview(data, setReviewData, setLocationData, loader, setLoader))
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFilterData({ ...filterData, [name]: value })
    }

    const filteredReviewData = (currentReviewDate) => {
        const reviewDate = new Date(currentReviewDate);
        const currentDate = new Date();

        switch (filterData.date) {
            case 'today':
                return reviewDate.toDateString() === currentDate.toDateString();
            case 'yesterday':
                const yesterday = new Date(currentDate);
                yesterday.setDate(currentDate.getDate() - 1);
                return reviewDate.toDateString() === yesterday.toDateString();
            case 'last7Days':
                const sevenDaysAgo = new Date(currentDate);
                sevenDaysAgo.setDate(currentDate.getDate() - 7);
                return reviewDate >= sevenDaysAgo && reviewDate <= currentDate;
            case 'last14Days':
                const fourteenDaysAgo = new Date(currentDate);
                fourteenDaysAgo.setDate(currentDate.getDate() - 14);
                return reviewDate >= fourteenDaysAgo && reviewDate <= currentDate;
            case 'last30Days':
                const thirtyDaysAgo = new Date(currentDate);
                thirtyDaysAgo.setDate(currentDate.getDate() - 30);
                return reviewDate >= thirtyDaysAgo && reviewDate <= currentDate;
            case 'last6Months':
                const sixMonthsAgo = new Date(currentDate);
                sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
                return reviewDate >= sixMonthsAgo && reviewDate <= currentDate;
            case 'last1Year':
                const oneYearAgo = new Date(currentDate);
                oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
                return reviewDate >= oneYearAgo && reviewDate <= currentDate;

            default:
                return true; // No filter applied
        }
    }

    const ratingValue = (rating) => {
        return {
            "FIVE": 5,
            "FOUR": 4,
            "THREE": 3,
            "TWO": 2,
            "ONE": 1
        }[rating];
    };

    const handleChangeAccount = (e) => {
        setIntegrationId(e.target.value);
    }

    const handleChangeLocation = (e) => {
        setLoader({ ...loader, fetch: true })
        setReviewData([])
        const { value } = e.target
        setLocationData({ ...locationData, value: value });
        let data = {
            id: integrationId,
            accountId: locationData.accountId,
            locationId: value.split("/")[1],

        };
        dispatch(onFetchReviewByLocation(data, setReviewData, loader, setLoader));
    }



    useEffect(() => {
        let data = [...reviewData];
        if (filterData.stars) {
            data = data.filter((review) => review.data.starRating === filterData.stars)
        }
        if (filterData.ratingsSort === "ascending") {
            data = data.sort((a, b) => ratingValue(a.data.starRating) - ratingValue(b.data.starRating))
        }
        if (filterData.ratingsSort === "descending") {
            data = data.sort((a, b) => ratingValue(b.data.starRating) - ratingValue(a.data.starRating))
        }

        if (filterData.reviewSentiment) {
            data = data.filter((review) => review.reviewSentiment?.toLowerCase() === filterData.reviewSentiment)
        }
        if (filterData.date) {
            data = data.filter((review) => filteredReviewData(review.data.createTime.split("T")[0]))
        }

        // Custom date range filtering
        if (filterData.fromDate && filterData.toDate) {
            const fromParts = filterData.fromDate.split("/");
            const fromDate = new Date(`${fromParts[2]}-${fromParts[1]}-${fromParts[0]}`);
            const toParts = filterData.toDate.split("/");
            const toDate = new Date(`${toParts[2]}-${toParts[1]}-${toParts[0]}`);
            data = data.filter((review) => {
                const reviewDate = new Date(review.data.createTime.split("T")[0]);
                console.log(reviewDate >= fromDate && reviewDate <= toDate, "check", { reviewDate, fromDate, toDate, from: filterData.fromDate, to: filterData.toDate })
                return reviewDate >= fromDate && reviewDate <= toDate;
            });
        }

        setCurrentTodo(data);
    }, [filterData, reviewData])

    useEffect(() => {
        if (integrationId) {
            setReviewData([]);
            fetchReviewData();
        }
    }, [integrationId, socialYoutube])

    useEffect(() => {
        if (id) {
            setIntegrationId(id);
        }
    }, [id])

    return (
        <>
            <TitleBar title="Review Inbox" />
            <div className="site-wrap">
                <div className="site-container">
                    <Tab.Container defaultActiveKey="reviews">
                        <div className="reviewLink d-flex justify-content-between align-items-end w-100" style={{ padding: "15px 30px" }}>
                            <div className="col-lg-2">
                                <label for="" style={{ fontSize: "13px" }}>Accounts</label>
                                <select
                                    className="common-inp alt"
                                    name="google"
                                    value={integrationId}
                                    onChange={(e) => handleChangeAccount(e)}
                                >
                                    {socialYoutube.length > 0 ?
                                        socialYoutube.map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.id}>{curElem.username}</option>
                                            )
                                        })
                                        : ""
                                    }
                                </select>
                            </div>
                            <div className="col-lg-2">
                                <label for="" style={{ fontSize: "13px" }}>Locations</label>
                                <select
                                    className="common-inp alt"
                                    name="google"
                                    value={locationData.value}
                                    onChange={handleChangeLocation}
                                >
                                    {locationData.location.length > 0 ?
                                        locationData.location.map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.name}>{curElem.title}</option>
                                            )
                                        })
                                        : ""
                                    }
                                </select>
                            </div>
                            <div className="col-lg-2 ps-1">
                                <div className="input-outer">
                                    <label for="">Ratings</label>
                                    <select
                                        className="common-inp alt"
                                        name="stars"
                                        value={filterData.stars}
                                        onChange={handleChange}
                                    >
                                        <option value="">All</option>
                                        <option value="ONE">1 Star</option>
                                        <option value="TWO">2 Star</option>
                                        <option value="THREE">3 Star</option>
                                        <option value="FOUR">4 Star</option>
                                        <option value="FIVE">5 Star</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2 ps-1">
                                <div className="input-outer">
                                    <label for="">Date</label>
                                    <select
                                        className="common-inp alt"
                                        name="date"
                                        value={filterData.date}
                                        onChange={handleChange}
                                    >
                                        <option value="">All</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="last7Days">Last 7 Days</option>
                                        <option value="last14Days">Last 14 Days</option>
                                        <option value="last30Days">Last 30 Days</option>
                                        <option value="last6Months">Last 6 Months</option>
                                        <option value="last1Year">Last 1 Year</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-1 ps-1">
                                <div className="input-outer">
                                    <label for="">Sentiment</label>
                                    <select
                                        className="common-inp alt"
                                        name="reviewSentiment"
                                        value={filterData.reviewSentiment}
                                        onChange={handleChange}
                                    >
                                        <option value="">All</option>
                                        <option value="negative">Negative</option>
                                        <option value="positive">Positive</option>
                                        <option value="neutral">Neutral</option>
                                    </select>
                                </div>
                            </div>

                            <div className="settingBox">
                                <Nav variant="pills align-items-center  mT_dropdown">
                                    <Nav.Item className="d-flex">
                                        <Link style={{ borderRadius: "5px" }} className="theme-btn me-2" to={`/review-inbox/settings?id=${integrationId}`} title="settings"><span><IoSettingsOutline /> Settings</span></Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ background: themeColor, color: "#fff", border: "none" }}>
                                                <BsThreeDots
                                                    style={{
                                                        color: "#fff"
                                                    }}
                                                />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item><Link to={`/review-inbox/create-proof?id=${integrationId}`}>Create proof</Link></Dropdown.Item>
                                                <Dropdown.Item ><Link to={`/review-inbox/create-video?id=${integrationId}`}>Create video</Link></Dropdown.Item>
                                                <Dropdown.Item ><Link to={`/review-inbox/update-profile?id=${integrationId}`}>Update profile</Link></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>

                        <Tab.Content>
                            <Tab.Pane eventKey="reviews">
                                <Reviews
                                    currentTodo={currentTodo}
                                    reviewData={reviewData}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    loader={loader}
                                    integrationId={integrationId}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container >
                </div >
            </div >
        </>
    )
}

export default ReviewInbox;