import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BsXLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux';
import { onCreateLeadFolder, onEditLeadFolder, onMoveLead } from '../../../../Redux/Actions/LeadAction';
import { useSelector } from 'react-redux';

const ManageLeadModal = ({ curElem, index, type, show, handleClose, fetchData, mainCheckedArr, setMainCheckedArr }) => {

    const dispatch = useDispatch();
    const leadFolderData = useSelector((state) => state.lead.data)
    const [state, setState] = useState({
        name: "",
        folder: ""
    });
    const [loader, setLoader] = useState({
        submit: false
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, submit: true })
        if (type === "createFolder") {
            let data = {
                name: state.name
            }
            dispatch(onCreateLeadFolder(data, loader, setLoader, handleClose, setMainCheckedArr))
        } else if (type === "editFolder") {
            let data = {
                id: curElem.fId,
                name: state.name
            }
            dispatch(onEditLeadFolder(data, loader, setLoader, handleClose))
        } else {
            let data = {
                id: curElem,
                fId: state.folder
            }
            dispatch(onMoveLead(data, loader, setLoader, handleClose, index, mainCheckedArr, setMainCheckedArr))
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }

    useEffect(() => {
        setState({ ...state, name: curElem.folderName })
    }, [curElem])

    return (
        <Modal className="theme-modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="modalClose" onClick={handleClose}><BsXLg /></div>
                <h5>{type === "createFolder" ? "Create New Folder" : type === "editFolder" ? "Edit Campaign" : "Move Lead"}</h5>
                <form action="" onSubmit={handleSubmit}>
                    <div className="input-outer mt-4">

                        {type === "moveFolder" ?
                            <select
                                className="common-inp alt"
                                name="folder"
                                value={state.folder}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Folder</option>
                                {leadFolderData.length > 0 ?
                                    leadFolderData.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.fId}>{curElem.folderName}</option>
                                        )
                                    })
                                    : ""
                                }
                            </select>
                            :

                            <input
                                className="common-inp alt"
                                type="text"
                                placeholder={type === "createFolder" ? "Enter Folder name" : type === "editFolder" ? "Enter Campaign name" : ""}
                                name='name'
                                value={state.name}
                                onChange={handleChange}
                                required
                            />
                        }

                    </div>
                    <div className="input-outer mt-4">
                        <div className="row">
                            <div className="col-6">
                                <button type='button' className="theme-btn bdr full" onClick={handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button type="submit" className="theme-btn full">
                                    {type === "createFolder" ?
                                        <span>{loader.submit ? <> Creating <i className="fa fa-spinner fa-spin ms-1" /></> : "Create"}</span>
                                        : type === "editFolder" ?
                                            <span>{loader.submit ? <> Submitting <i className="fa fa-spinner fa-spin ms-1" /></> : "Submit"}</span>
                                            :
                                            <span>{loader.submit ? <> Moving <i className="fa fa-spinner fa-spin ms-1" /></> : "Move"}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ManageLeadModal
