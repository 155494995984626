import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const onGetGoogleReview = (data, setReviewData, setLocationData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("google-review", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setLocationData({
                    location: res.data.LocationData,
                    accountId: res.data.accountId,
                    value: res.data.LocationData[0]?.name
                })
            }
            dispatch(onFetchReviewData(data, setReviewData, loader, setLoader))
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchReviewData = (data, setReviewData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-review", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setReviewData(res.data)
            } else {
                setReviewData([])
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchReviewByLocation = (data, setReviewData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("google-review-info", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onFetchReviewData(data, setReviewData, loader, setLoader))
            } else {
                setReviewData([])
                setLoader({ ...loader, fetch: false });
            }
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchSettingsData = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-setting", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    autoReply: res.data[0].autoReply === "1" ? true : false,
                    phoneNumbers: res.data[0].phoneNumbers,
                    NotificationEmails: res.data[0].NotificationEmails,
                    contactEmails: res.data[0].contactEmails,
                    negativeHandler: res.data[0].negativeHandler,
                    positiveHandler: res.data[0].positiveHandler,
                })
            }
            if (setLoader) {
                setLoader({ ...loader, fetch: false });
            }
        }).catch((err) => {
            console.log(err)
            if (setLoader) {
                setLoader({ ...loader, fetch: false });
            }
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onSaveSettings = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-setting", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, save: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onCreateProof = (data, setState, loader, setLoader, fetchProof, handleClose) => (dispatch, getState) => {
    commonAxios("create-proof", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchProof().then(() => {
                    handleClose();
                    setState({
                        name: "",
                        loop: false
                    })
                    setLoader({ ...loader, submit: false });
                    dispatch(setAlert(res.msg, "success"))
                });
            } else {
                handleClose();
                setLoader({ ...loader, submit: false });
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            handleClose();
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}
export const onUpdateProof = (data, setState, loader, setLoader, fetchProof, handleClose) => (dispatch, getState) => {
    commonAxios("update-proof", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchProof().then(() => {
                    handleClose();
                    setLoader({ ...loader, submit: false });
                    dispatch(setAlert(res.msg, "success"))
                });
            } else {
                handleClose();
                setLoader({ ...loader, submit: false });
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            handleClose();
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchProofData = (data, setState, loader, setLoader, resolve) => (dispatch, getState) => {
    commonAxios("fetch-all-proof", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data);
            }
            setLoader({ ...loader, fetch: false });
            resolve();
        }).catch((err) => {
            console.log(err)
            resolve();
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onGenerateReply = (data, setReply, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-auto-reply", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setReply(res.data);
            }
            setLoader({ ...loader, generate: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, generate: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchBusinessDetails = (data, setBusinessDetails, setBusinessPhotos, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-business", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setBusinessDetails(res.data[0]);
                setBusinessPhotos(res.data[0].photos);
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}
export const onFetchBusinessCategory = (data, setCategory, categoryName) => (dispatch, getState) => {
    commonAxios("fetch-category", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data.filter((cat) => cat.google_category_id === categoryName.split("/")[1])
                setCategory(arr)
            }
        }).catch((err) => {
            console.log(err)
        })
}
export const onGenerateAiDescription = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("business-description", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({ ...state, description: res.data })
            }
            setLoader({ ...loader, generate: false })
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, generate: false })
        })
}

export const onUpdateBusinessInfo = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}
export const onUpdateServiceInfo = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchBusinessPosts = (data, setPost, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-post-all", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setPost(res.data)
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onCreateBusinessPost = (data, loader, setLoader, fetchPosts, handleClose) => (dispatch, getState) => {
    commonAxios("create-post", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchPosts();
                handleClose();
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}
export const onFetchPostDetails = (data, setState, setPhotos, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-post", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = res.data[0]
                setState({
                    id: obj.id,
                    title: obj.title,
                    url: obj.url,
                    fromDate: obj.fromDate,
                    toDate: obj.toDate,
                    fromTime: obj.fromTime,
                    toTime: obj.toTime
                })
                setPhotos(obj.url)
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}