import React, { useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import AccountNav from "./AccountNav";

import { FaSpinner } from "react-icons/fa"

import { BiTrash, BiChevronDownSquare, BiSearch } from "react-icons/bi"
import { MdModeEditOutline } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { changeProfile, onUpdatePassword, onUpdateUser } from "../../../Redux/Actions/AuthActions";
import Swal from "sweetalert2";
import TitleBar from "../../CommonComponents/TitleBar";

const MyAccount = () => {

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [loader, setLoader] = useState({
    user: false,
    password: false,
    image: false
  })
  const [membershipName, setMembershipName] = useState("")
  const [user, setUser] = useState({
    name: "",
    email: "",
  })

  const [password, setPassword] = useState({
    password: '',
    new_password: '',
    confirm_password: '',
  })

  const [passwordMsg, setPasswordMsg] = useState({
    msg: "",
    validation: false
  })


  const handleChange = (e) => {
    const { name, value } = e.target
    setUser({
      ...user,
      [name]: value
    })
  }

  const handlePassword = (e) => {
    const { name, value } = e.target
    setPassword({
      ...password,
      [name]: value
    })
  }


  const onImageChange = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
        if (e.target.files[0].size < 5000000) {
          const formData = new FormData()
          formData.append('user_id', auth.user.id);
          formData.append('file', e.target.files[0]);
          setLoader({
            ...loader,
            image: true
          })
          dispatch(changeProfile(formData, loader, setLoader))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Max allowed size is 5MB!',
            confirmButtonColor: "#544fc4"
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You have selected invalid file type!',
          confirmButtonColor: "#544fc4"
        })
      }
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader({
      ...loader,
      user: true
    })
    delete user.email
    dispatch(onUpdateUser(user, loader, setLoader))
  }
  const updatePassword = (e) => {
    e.preventDefault()
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
    if (password.new_password) {
      if (pattern.test(password.new_password)) {
        if (password.new_password === password.confirm_password) {
          setLoader({
            ...loader,
            password: true
          })
          dispatch(onUpdatePassword(password, loader, setLoader))
        } else {
          setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
        }

      } else {
        setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
      }
    }
  }

  useEffect(() => {
    if (auth.user) {
      setUser({
        ...user,
        name: auth.user.name,
        email: auth.user.email,
      })
      if (auth.user.membership) {
        let strMem = auth.user.membership.split("__")[auth.user.membership.split("__").length - 1]
        setMembershipName(strMem)
      }
    }
  }, [auth])

  return (
    <>
      <Header />
      <TitleBar title="Profile" />
      <div className="site-wrap">
        <div className="site-container">
          <div className="account-wrap">
            <div className="account-nav">
              <AccountNav />
            </div>

            <div className="account-cont">
              <h2 className="account-title">My Account</h2>

              <div className="account-block">
                <div className="account-profile">
                  <div className="account-profile-img">
                    {loader.image ?
                      <div className='profileLoader'>
                        <FaSpinner className="spin spin-style" />
                      </div>
                      :
                      <>
                        <img alt={auth.user.name} src={auth.user.profile} />
                        <div className="profile-img-edit">
                          <MdModeEditOutline />
                          <input
                            type="file"
                            onChange={(e) => onImageChange(e)}
                          />
                        </div>
                      </>}
                  </div>
                  <h5>{auth.user.name}</h5>
                  <div className='text-center mt-4'><a className='theme-btn w-205 text-capitalize'><span>{membershipName}</span></a></div>
                </div>
                <form onSubmit={handleSubmit}>
                  <h5 className="pt-5">Personal Info</h5>
                  <div className="input-wrap">
                    <label htmlFor="">Name</label>
                    <div className="input-outer">
                      <input
                        className="common-inp alt"
                        type="text"
                        name="name"
                        value={user.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="input-wrap">
                    <label htmlFor="">Email</label>
                    <div className="input-outer">
                      <input
                        className="common-inp alt"
                        type="email"
                        name='email'
                        value={user.email}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="input-wrap text-end">
                    <button className="theme-btn" type="submit"><span>{loader.user ? <>Updating  <FaSpinner className="spin" /></> : "Update"}</span></button>

                  </div>
                </form>
              </div>

              <form className="account-block" onSubmit={updatePassword}>
                <h5>Update Password</h5>
                <div className="input-wrap">
                  <label htmlFor="">Current Password</label>
                  <div className="input-outer">
                    <input
                      className="common-inp alt"
                      type="password"
                      name="password"
                      onChange={handlePassword}
                      required
                    />
                  </div>
                </div>
                <div className="input-wrap">
                  <label htmlFor="">New Password</label>
                  <div className="input-outer">
                    <input
                      className="common-inp alt"
                      type="password"
                      name="new_password"
                      onChange={handlePassword}
                      required
                    />
                  </div>
                </div>
                <div className="input-wrap">
                  <label htmlFor="">Confirm Password</label>
                  <div className="input-outer">
                    <input
                      className="common-inp alt"
                      type="password"
                      name="confirm_password"
                      onChange={handlePassword}
                      required
                    />
                  </div>
                </div>
                <div className='alert alert-info text-center mt-3'>
                  <div className='info-msg'>Updating your password will log you out for re-login.</div>
                </div>
                {passwordMsg.validation ?
                  <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                <div className="input-wrap text-end">
                  <button className="theme-btn" type="submit"><span>{loader.password ? <>Updating  <FaSpinner className="spin" /></> : "Update"}</span></button>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default MyAccount;