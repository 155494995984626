import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onUpdateWatermarkInfo } from '../../../../Redux/Actions/VideoActions'

const Watermark = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.campaign)
    const watermarkRef = useRef(null)

    useEffect(() => {
        if (campaign.watermark.src !== "") {
            setTimeout(() => {
                dispatch(onUpdateWatermarkInfo(watermarkRef.current.clientHeight, watermarkRef.current.clientWidth))
            }, 2000)
        } else {
            dispatch(onUpdateWatermarkInfo(0, 0))
        }
    }, [campaign.watermark.src])

    return (

        campaign.watermark.src !== "" ?
            <div className={`watermark-op logo-${campaign.watermark.pos} logo-style`}>
                <img
                    ref={watermarkRef}
                    src={campaign.watermark.src}
                    alt="watermark"
                />
            </div>
            : ''
    )
}

export default Watermark