import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { BiSearch } from "react-icons/bi"
import { onFetchCampaign, onUpdateCampaign } from "../../../Redux/Actions/VideoActions";
import { useDispatch } from "react-redux";
import queryString from "query-string"
import { fetchData } from "../../../Redux/Actions/CommonActions";
import { FaSpinner } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import HoverVideoPlayer from "react-hover-video-player";

const Templates = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const cId = queryString.parse(location.search).cid

    const [q, setQ] = useState("")
    const [templates, setTemplates] = useState([])
    const [loader, setLoader] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)

    const [state, setState] = useState({
        templateId: 0
    })

    const handleSelect = (id) => {
        setState({
            ...state,
            templateId: id
        })
    }

    const handleUpdate = () => {
        if (+state.templateId === 0) {
            dispatch(setAlert("Please select a video template to continue!", "danger"))
        } else {
            let data = { ...state, id: cId }
            setBtnLoader(true)
            dispatch(onUpdateCampaign(data, navigate, setBtnLoader, "face"))
        }
    }


    const fetchCampaign = () => {
        const data = {
            id: cId
        };
        dispatch(onFetchCampaign(data, setState));
    }

    const fetchTemplates = () => {
        dispatch(fetchData("fetch-template", {}, setTemplates, loader, setLoader))
    }


    useEffect(() => {
        fetchTemplates()
    }, [])

    useEffect(() => {
        if (cId) {
            fetchCampaign()
        }
    }, [cId])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header />
            <div className="site-wrap">
                <div className="site-container">
                    <div className="template-list">
                        <div className="temp-title">
                            <h2>Select a video template</h2>
                            <div>
                                <button className="theme-btn large btn-min bdr" type="button" onClick={() => navigate(`/create-content?cid=${cId}`)}><span><BsArrowLeft /> Back</span></button>
                                <button className="theme-btn large btn-min ms-2" type="button" onClick={handleUpdate}><span>{btnLoader ? <> Next <FaSpinner className="spin" /></> : <> Next <BsArrowRight /></>}</span></button>
                            </div>
                        </div>

                        <hr />
                        <div className={`${state.dimension === "9x16" ? " col-lg-8 col-10" : ""}`} style={{ margin: "0 auto" }}>
                            <ul>
                                {templates.length > 0 ?
                                    templates.filter((temp) => {
                                        return temp.name.toLowerCase().includes(q.toLowerCase())
                                    }).map((curElem, index) => {
                                        const imageData = state.dimension === "16x9" ? curElem.previewImage : curElem.preview_vertical
                                        const videoData = state.dimension === "16x9" ? curElem.previewHzVideo : curElem.previewVcVideo
                                        return (
                                            <div className="col-lg-6 col-12 temp-prev" key={index}>
                                                <li style={{ width: "100%" }}>
                                                    {imageData ?
                                                        <div className={`${state.dimension === "9x16" ? "template-single_dynmic" : "template-single"}`} style={{ cursor: "pointer" }} onClick={() => handleSelect(curElem.id)}>
                                                            <div className={`${state.dimension === "16x9" ? "template-img_16X9" : "template-img"}`} style={{ position: "relative" }}>

                                                                <HoverVideoPlayer
                                                                    style={{ height: '100%', zIndex: 3 }}
                                                                    videoSrc={videoData}
                                                                    pausedOverlay={
                                                                        <img
                                                                            src={imageData}
                                                                            alt={""}
                                                                        />
                                                                    }
                                                                    loadingOverlay={
                                                                        <div style={{ color: "#00c4a6", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                            <FaSpinner className="spin" size={20} />
                                                                        </div>
                                                                    }
                                                                />
                                                                {+curElem.id === +state.templateId ?
                                                                    <div className='select-tile-style' style={{ borderRadius: 11, zIndex: "unset" }}>
                                                                        <GiCheckMark style={{ zIndex: 4, position: "relative" }} />
                                                                    </div> : ""}
                                                                {/* {+curElem.id === +state.templateId ?
                                                                <span style={{ border: "2px solid #00D0B0", height: "100%", width: "100%", borderRadius: 12, display: "flex", alignItems: "center", justifyContent: "center", zIndex: 1 }}>
                                                                    <GiCheckMark size={25} color="#00D0B0" style={{ display: "flex" }} />
                                                                </span>
                                                                : ""} */}
                                                            </div>
                                                        </div> : null}
                                                </li>
                                            </div>
                                        )
                                    })

                                    :
                                    <div className='text-center mt-3' style={{ height: "100%", width: "100%" }}>
                                        {loader.fetch ? <FaSpinner className="spin" size={23} color="#00D0B0" /> : ""}
                                    </div>
                                }

                            </ul>
                        </div>
                        <div className="inpx-wrap text-end d-none">
                            <button className="theme-btn large btn-min bdr" type="button" onClick={() => navigate(`/create-content?cid=${cId}`)}><span><BsArrowLeft /> Back</span></button>
                            <button className="theme-btn large btn-min ms-2" type="button" onClick={handleUpdate}><span>{btnLoader ? <> Next <FaSpinner className="spin" /></> : <> Next <BsArrowRight /></>}</span></button>
                        </div>

                    </div>

                </div >
            </div >

            <Footer />
        </>
    )
}

export default Templates;