import React, { useEffect, useState } from "react";

import TitleBar from "../../../CommonComponents/TitleBar";
import { useImmer } from "use-immer";
import { useDispatch } from "react-redux";
import { onCreateFeedback, onFetchFeedback, onGenerateDefaultMessage } from "../../../../Redux/Actions/WidgetActions";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { FiUpload } from "react-icons/fi";
import dummyImg from "../../../../assets/images/dummy.jpg"
import { onUploadMedia } from "../../../../Redux/Actions/CommonActions";
import Swal from "sweetalert2";
import { themeColor } from "../../../../Global/Global";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from 'sanitize-html';
const ChatBotStyle = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = queryString.parse(location.search)

    const autoResponder = useSelector(state => state.social.autoResponder)
    const allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
    const [isEditDefault, setIsActiveDefault] = useState(true)

    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        create: false,
        fetch: id ? true : false,
        upload: false,
        messageLoader: false
    })
    const [link, setLink] = useState({
        pos: {
            name: "",
            url: ""
        },
        neg: {
            name: "",
            url: ""
        }
    })
    const [data, setData] = useImmer({
        name: "",
        preQuestion: "",
        incentiveMessage: "",
        businessName: "",
        negativeHandler: "",
        integrateId: "",
        image: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setData((draft) => {
            draft[name] = value
        })
    }

    const handleChangeHandler = (e, name) => {
        setData((draft) => {
            draft[name] = e.target.value
        })
    }

    const handleChangeLink = (e, type) => {
        const { name, value } = e.target
        setLink({
            ...link,
            [type]: {
                ...link[type],
                [name]: value
            }
        })
    }

    const handleAddLink = (type) => {
        if (type === "pos") {
            if (link.pos.url && link.pos.name) {
                if (link.pos.url.includes("https://") || link.pos.url.includes("http://")) {
                    setData({
                        ...data,
                        incentiveMessage: data.incentiveMessage + (data.incentiveMessage ? `\n${link.pos.name}: ${link.pos.url}` : `${link.pos.name}: ${link.pos.url}`)
                    })
                    setLink({
                        ...link,
                        pos: {
                            ...link.pos,
                            name: "",
                            url: ""
                        }
                    })
                } else {
                    dispatch(setAlert("Please provide valid URL.", "danger"))
                }
            } else if (link.pos.name === "") {
                dispatch(setAlert("Please provide name to create link.", "danger"))
            } else if (link.pos.url === "") {
                dispatch(setAlert("Please provide URL to create link.", "danger"))
            }
        } else {
            if (link.neg.url && link.neg.name) {
                if (link.neg.url.includes("https://") || link.neg.url.includes("http://")) {
                    setData({
                        ...data,
                        negativeHandler: data.negativeHandler + (data.negativeHandler ? `\n${link.neg.name}: ${link.neg.url}` : `${link.neg.name}: ${link.neg.url}`)
                    })
                    setLink({
                        ...link,
                        neg: {
                            ...link.neg,
                            name: "",
                            url: ""
                        }
                    })
                } else {
                    dispatch(setAlert("Please provide valid URL.", "danger"))
                }
            } else if (link.neg.name === "") {
                dispatch(setAlert("Please provide name to create link.", "danger"))
            } else if (link.neg.url === "") {
                dispatch(setAlert("Please provide URL to create link.", "danger"))
            }
        }
    }

    const fetchImgMedia = (url) => {
        let obj = {
            target: {
                name: "image",
                value: url
            }
        }
        handleChange(obj)
    }

    const onInputChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fetchImgMedia, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }


    const handleFindDefault = () => {
        let obj = {
            name: data.name,
            businessName: data.businessName
        }
        setLoader({
            ...loader,
            messageLoader: true
        })
        dispatch(onGenerateDefaultMessage(obj, data, setData, setIsActiveDefault, loader, setLoader))
    }



    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let url = "create-feedback"
        if (id) {
            url = "update-feedback"
        }

      

        dispatch(onCreateFeedback(url, data, navigate, loader, setLoader))
    }

    const fetchFeedback = () => {
        let data = { id }
        dispatch(onFetchFeedback(data, setData, setIsActiveDefault, loader, setLoader))
    }


    useEffect(() => {
        if (id) {
            fetchFeedback()
        }
    }, [id])


    return (
        loader.fetch ?

            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
            :
            <>
                <TitleBar title="Create Feedback Widget" />

                <div className="site-wrap">
                    <div className="site-container">
                        <h4 className="ps-5">{id ? "Update" : "Create"} Feedback Widget</h4>
                        <form className="chatBot-form" onSubmit={handleSubmit}>
                            <h4>General</h4>
                            <hr className="m-0 mt-1" />
                            <div className="input-outer mt-1">
                                <label className="mb-2" htmlFor="">Upload Image</label>
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className='upload-box me-2' style={{ background: "#1d1b41a1", width: "100%" }}>
                                        {loader.upload ?
                                            <div className='percent-loader-box' style={{ fontSize: 16 }}>
                                                {percent}%
                                            </div> :
                                            <>
                                                <FiUpload />
                                                <p>Upload JPG, JPEG & PNG</p>
                                                <input
                                                    type="file"
                                                    onChange={onInputChange}
                                                />
                                            </>
                                        }

                                    </div>
                                    <div style={{ width: 160, height: 120, position: "relative" }}>
                                        <img src={data.image ? data.image : dummyImg} alt="" style={{ height: "100%", width: "100%", borderRadius: 10, objectFit: "contain" }} />
                                        {loader.upload ?
                                            <div style={{ position: "absolute", top: 0, display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
                                                <i className="fa fa-spin fa-spinner" style={{ fontSize: 30, color: themeColor }} />
                                            </div> : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="input-outer mt-4">
                                <label className="mb-2" htmlFor="">Name</label>
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    placeholder="Enter Name"
                                    name="name"
                                    value={data.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-outer mt-4">
                                <label className="mb-2" htmlFor="">Business Name</label>
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    placeholder="Enter Business Name"
                                    name="businessName"
                                    value={data.businessName}
                                    onChange={handleChange}
                                    required
                                    onBlur={handleFindDefault}
                                />
                            </div>
                            <div className="input-outer mt-4">
                                <label className="mb-2" htmlFor="">Default Welcome Message {loader.messageLoader ? <i className="fa fa-spin fa-spinner" /> : ""}</label>
                                <textarea
                                    className="common-inp alt"
                                    placeholder="Add Default Message"
                                    name="preQuestion"
                                    value={data.preQuestion}
                                    onChange={handleChange}
                                    disabled={isEditDefault}
                                    required
                                />
                            </div>



                            <div className="input-outer mt-4">
                                <label className="mb-2" htmlFor="">Auto Responder</label>
                                <select
                                    className="common-inp alt"
                                    name="integrateId"
                                    value={data.integrateId}
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    {autoResponder.length > 0 ?
                                        autoResponder.map((curElem, index) => {
                                            return (
                                                <option value={curElem.id} key={index}>{curElem.username}</option>
                                            )
                                        })
                                        : null}
                                </select>
                            </div>

                            <h4 className="mt-4">Negative Feedback Handler</h4>
                            <hr className="m-0 mt-1" />
                            <div className="input-outer mt-2">
                                <label className="mb-2" htmlFor="">Handler Message</label>
                                <textarea
                                    className="common-inp alt"
                                    placeholder="Enter Handler Message"
                                    name="negativeHandler"
                                    value={data.negativeHandler}
                                    onChange={handleChange}
                                    style={{ minHeight: 200 }}
                                    required
                                />

                                {/* <ContentEditable
                                    html={data.negativeHandler}
                                    onChange={(e) => handleChangeHandler(e, "negativeHandler")}
                                    className="common-inp alt"
                                    tagName="div"
                                    style={{ minHeight: 200 }}

                                /> */}
                            </div>
                            <div className="input-outer mt-2">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h6 className="" htmlFor="">Create Link </h6>
                                    {/* <FaPlusCircle size={20} color="#4944a7" cursor={"pointer"} title="Add Link" onClick={() => handleAddRemove("add")} /> */}
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div>
                                        <label>Link Name</label>
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={link.neg.name}
                                            onChange={(e) => handleChangeLink(e, "neg")}
                                        />
                                    </div>
                                    <div>
                                        <label>Link URL</label>
                                        <input
                                            className="common-inp alt"
                                            type="url"
                                            placeholder="Enter URL"
                                            name="url"
                                            value={link.neg.url}
                                            onChange={(e) => handleChangeLink(e, "neg")}
                                        />
                                    </div>
                                    <div>
                                        <button className="theme-btn mt-3" type="button" onClick={() => handleAddLink("neg")}><span>Add</span></button>
                                        {/* <FaMinusCircle size={20} color="#4944a7" cursor={"pointer"} title="Remove" onClick={() => handleAddRemove("remove", index)} /> */}
                                    </div>
                                </div>
                            </div>

                            <h4 className="mt-4">Positive Feedback Handler</h4>
                            <hr className="m-0 mt-1" />

                            <div className="input-outer mt-2">
                                <label className="mb-2" htmlFor="">Handler Message</label>
                                {/* <ContentEditable
                                    html={data.incentiveMessage}
                                    onChange={(e) => handleChangeHandler(e, "incentiveMessage")}
                                    className="common-inp alt"
                                    tagName="div"
                                    style={{ minHeight: 200 }}
                                /> */}
                                <textarea
                                    className="common-inp alt"
                                    placeholder="Enter Handler Message"
                                    name="incentiveMessage"
                                    value={data.incentiveMessage}
                                    style={{ minHeight: 200 }}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-outer mt-2">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h6 className="" htmlFor="">Create Link </h6>
                                    {/* <FaPlusCircle size={20} color="#4944a7" cursor={"pointer"} title="Add Link" onClick={() => handleAddRemove("add")} /> */}
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div>
                                        <label>Link Name</label>
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={link.pos.name}
                                            onChange={(e) => handleChangeLink(e, "pos")}
                                        />
                                    </div>
                                    <div>
                                        <label>Link URL</label>
                                        <input
                                            className="common-inp alt"
                                            type="url"
                                            placeholder="Enter URL"
                                            name="url"
                                            value={link.pos.url}
                                            onChange={(e) => handleChangeLink(e, "pos")}
                                        />
                                    </div>
                                    <div>
                                        <button className="theme-btn mt-3" type="button" onClick={() => handleAddLink("pos")}><span>Add</span></button>
                                        {/* <FaMinusCircle size={20} color="#4944a7" cursor={"pointer"} title="Remove" onClick={() => handleAddRemove("remove", index)} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="input-outer mt-4">
                                <button type="submit" className="theme-btn full"><span>{loader.create ? <>{id ? "Updating" : "Creating"} <i className="fa fa-spin fa-spinner" /></> : id ? "Update" : "Create"}</span></button>
                            </div>
                        </form>
                    </div>
                </div>

            </>
    )
}

export default ChatBotStyle;