import React, { useEffect, useRef, useState } from "react";
import { GoBold } from "react-icons/go"
import { FiItalic, FiUnderline } from "react-icons/fi"
import { RiStrikethrough2 } from "react-icons/ri"
import { MdOutlineTextFields } from "react-icons/md"
import { FaAlignCenter, FaAlignLeft, FaAlignRight, FaAlignJustify } from "react-icons/fa"
import { IoText } from "react-icons/io5"
import { useDispatch, useSelector } from "react-redux";
import { addInnerStyle, applyToAllSlide, onFontFamily, onFontSize, onTextAlign, onTextBold, onTextCapitalize, onTextItalic, onTextLineHeight, onTextLower, onTextStrike, onTextUnderline, onTextUpper, removeInnerStyle, textLayerAdd, textLayerUpdate } from "../../../../Redux/Actions/VideoActions";
import { onFetchFont } from "../../../../Redux/Actions/CommonActions";
import sanitizeHtml from 'sanitize-html';
import FontColor from "./FontColor";
import { RxLetterCaseLowercase, RxLetterCaseUppercase } from 'react-icons/rx'
import ContentEditable from 'react-contenteditable';
import Gradient from "./Gradient";
import { disableDelete, enableDelete } from "../../../../Redux/Actions/EditorActions";

const Text = () => {

    const dispatch = useDispatch()
    const layerTextRef = useRef(null)
    const campaign = useSelector(state => state.video.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const textIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const selectedText = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

    const [layerText, setLayerText] = useState("")
    const [slideAll, setSlideAll] = useState(false);
    const [fonts, setFonts] = useState([])
    const [isGradient, setIsGradient] = useState(false)
    const [restriction, setRestriction] = useState(true);
    const [dataHigh, setDataHigh] = useState({
        text: "",
        color: "",
        background: ""
    })

    const [styleState, setStyleState] = useState({
        color: "#000000",
        fontFamily: "",
        background: "",
        fontSize: "36",
        textAlign: "center",
        fontWeight: false,
        fontStyle: false,
        textTransform: "lowercase",
        textDecoration: "none",
        lineHeight: 1,
        opacity: 1,
    })

    const addTextLayer = () => {
        dispatch(textLayerAdd(selectedSlideIndex))
    }

    const onLayerTextChange = (text) => {
        if (selectedText.type === "text") {
            const clean = sanitizeHtml(text, {
                allowedTags: []
            });
            setLayerText(clean)
            dispatch(textLayerUpdate(clean, selectedSlideIndex, textIndex))
        }
    }

    const onTextStyle = (type) => {
        setSlideAll(false)
        if (type === 'bold') {
            if (styleState.fontWeight === false || styleState.fontWeight === "") {
                dispatch(onTextBold("bold", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextBold(false, selectedSlideIndex, textIndex));
            }
        } else if (type === 'italic') {
            if (styleState.fontStyle === false || styleState.fontStyle === "") {
                dispatch(onTextItalic("italic", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextItalic(false, selectedSlideIndex, textIndex));
            }
        } else if (type === 'uppercase') {
            if (styleState.textTransform === "uppercase") {
                dispatch(onTextUpper("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextUpper("uppercase", selectedSlideIndex, textIndex));
            }
        }
        else if (type === 'capitalize') {
            if (styleState.textTransform === "capitalize") {
                dispatch(onTextCapitalize("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextCapitalize("capitalize", selectedSlideIndex, textIndex));
            }
        }

        else if (type === 'lowercase') {
            if (styleState.textTransform === "lowercase") {
                dispatch(onTextLower("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextLower("lowercase", selectedSlideIndex, textIndex));
            }
        } else if (type === 'underline') {
            if (styleState.textDecoration === "underline") {
                dispatch(onTextUnderline("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextUnderline("underline", selectedSlideIndex, textIndex));
            }
        } else if (type === 'line-through') {
            if (styleState.textDecoration === "line-through") {
                dispatch(onTextStrike("none", selectedSlideIndex, textIndex));
            } else {
                dispatch(onTextStrike("line-through", selectedSlideIndex, textIndex));
            }
        }
    }

    const onAlign = (align) => {
        setSlideAll(false);
        dispatch(onTextAlign(align, selectedSlideIndex, textIndex));
    }


    const onInputChange = (e, type) => {
        setSlideAll(false);
        if (type === 'size') {
            dispatch(onFontSize(e.target.value, selectedSlideIndex, textIndex));
        } else if (type === 'font_family') {
            dispatch(onFontFamily(e.target.value, selectedSlideIndex, textIndex));
        }
    }

    const onChangeLineHeight = (e) => {
        setSlideAll(false);
        dispatch(onTextLineHeight(e.target.value, selectedSlideIndex, textIndex))
    }

    const fetchFonts = () => {
        dispatch(onFetchFont(setFonts, false))
    }

    const applyChanges = () => {
        if (slideAll === false) {
            setSlideAll(true);
            dispatch(applyToAllSlide(styleState, selectedText.innerCss));
        }
    }

    const handleChecked = (e) => {
        if (selectedText.type === "text") {
            setSlideAll(false);
            if (e.target.checked) {
                dispatch(addInnerStyle(selectedSlideIndex, textIndex, styleState.background))
            } else {
                dispatch(removeInnerStyle(selectedSlideIndex, textIndex))
            }
        }
    }


    const handleClick = (event) => {
        let text = event.currentTarget.innerText;
        setRestriction(false)
        if (selectedText.type === "text") {
            const clean = sanitizeHtml(text, {
                allowedTags: []
            });
            setLayerText(clean)
            dispatch(textLayerUpdate(clean, selectedSlideIndex, textIndex))
        }
    }

    const handleBlur = () => {
        dispatch(enableDelete())
        // setRestriction(true)
    }


    const handleHighlight = (e) => {
        const selObj = window.getSelection().toString();

        if (selObj !== "") {
            setDataHigh({
                ...dataHigh,
                text: selObj
            })
        }
    }

    const handleOutsideText = () => {
        dispatch(disableDelete())

    }

    useEffect(() => {
        if (selectedText) {
            setStyleState({
                ...selectedText.style
            })
            if (selectedText.style.background.includes("linear-gradient")) {
                setIsGradient(true)
            } else {
                setIsGradient(false)
            }
            setLayerText(selectedText.src)
        }
    }, [selectedText])

    useEffect(() => {
        fetchFonts()
    }, [])


    return (
        <>
            <h2 className="tabTitle">Text Customization</h2>
            <div className="left-scroll">
                <div className="fonts">

                    <div className="font-block pb-4">
                        <button className="theme-btn full mt-2" onClick={addTextLayer}><span>Add a text layer</span></button>
                        {selectedText ? selectedText.type === "text" ?
                            <>
                                <ContentEditable
                                    className="inp-small mt-2"
                                    ref={layerTextRef}
                                    html={layerText}
                                    disabled={false}
                                    onBlur={handleBlur}
                                    onClick={(e) => handleClick(e)}
                                    onMouseUp={() => handleHighlight()}
                                    onFocus={() => handleOutsideText()}
                                    onChange={(e) => onLayerTextChange(e.target.value)}
                                    style={{ minHeight: "165px" }}

                                />

                                <FontColor
                                    type="highlight"
                                    selectedSlideIndex={selectedSlideIndex}
                                    textIndex={textIndex}
                                    isHighlight={true}
                                    dataHigh={dataHigh}
                                    selectedText={selectedText}
                                    setDataHigh={setDataHigh}
                                />

                                <FontColor
                                    type="highlight background"
                                    selectedSlideIndex={selectedSlideIndex}
                                    textIndex={textIndex}
                                    isHighlight={true}
                                    dataHigh={dataHigh}
                                    setDataHigh={setDataHigh}
                                    selectedText={selectedText}
                                />
                                <hr />

                            </>
                            : "" : ""}
                    </div>
                    {selectedText ? selectedText.type === "text" ?
                        <div className="font-block">
                            <h6>Font Style</h6>
                            <div className="row sm pt-2">
                                <div className="col-12">
                                    <select
                                        className="inp-small"
                                        onChange={(e) => onInputChange(e, "font_family")}
                                        value={styleState.fontFamily}
                                        style={{ fontFamily: styleState.fontFamily }}
                                    >
                                        <option value="">Select Font</option>
                                        {fonts.length > 0 ?
                                            fonts.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem} style={{ fontFamily: curElem }}>{curElem}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                </div>

                            </div>
                            <div className="font-type pt-3">
                                <h6>Font size</h6>
                                <div className="font-size mt-2">
                                    <IoText />
                                    <div className="slidecontainer">
                                        <input
                                            type="range"
                                            min="10"
                                            max="100"
                                            className="sliderN"
                                            value={styleState.fontSize}
                                            onChange={(e) => onInputChange(e, "size")}
                                        />
                                    </div>
                                    <IoText />
                                </div>
                            </div>
                            <div className="font-type pt-3">
                                <div className="row sm">
                                    <div className="col-8">
                                        <h6>Align</h6>
                                        <div className="type-div d-flex mt-2">
                                            <span style={{ cursor: "pointer" }} title='Text Left' className={styleState.textAlign === "left" ? 'active' : ""} onClick={() => onAlign("left")}><FaAlignLeft /></span>
                                            <span style={{ cursor: "pointer" }} title='Text Center' className={styleState.textAlign === "center" ? 'active' : ""} onClick={() => onAlign("center")}><FaAlignCenter /></span>
                                            <span style={{ cursor: "pointer" }} title='Text Right' className={styleState.textAlign === "right" ? 'active' : ""} onClick={() => onAlign("right")}><FaAlignRight /></span>
                                            <span style={{ cursor: "pointer" }} title='Text Justify' className={styleState.textAlign === "justify" ? 'active' : ""} onClick={() => onAlign("justify")}><FaAlignJustify /></span>
                                            {/* <span><MdVerticalAlignTop /></span> */}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <h6>Line spacing</h6>
                                        <input
                                            className="inp-small mt-2"
                                            type="number"
                                            min={1}
                                            step="0.1"
                                            value={selectedText.innerCss ? selectedText.innerCss.lineHeight : styleState.lineHeight}
                                            onChange={onChangeLineHeight}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="font-type pt-3">
                                <h6>Type Settings</h6>
                                <div className="type-div">
                                    <span style={{ cursor: "pointer" }} title='Bold' className={styleState.fontWeight === "bold" ? 'active' : ""} onClick={() => onTextStyle("bold")}><GoBold /></span>
                                    <span style={{ cursor: "pointer" }} title='Italic' className={styleState.fontStyle === "italic" ? 'active' : ""} onClick={() => onTextStyle("italic")}><FiItalic /></span>
                                    <span style={{ cursor: "pointer" }} title='Underline' className={styleState.textDecoration === "underline" ? 'active' : ""} onClick={() => onTextStyle("underline")}><FiUnderline /></span>
                                    <span style={{ cursor: "pointer" }} title='Strike' className={styleState.textDecoration === "line-through" ? 'active' : ""} onClick={() => onTextStyle("line-through")}><RiStrikethrough2 /></span>
                                    <span style={{ cursor: "pointer" }} title='Uppercase' className={styleState.textTransform === "uppercase" ? 'active' : ""} onClick={() => onTextStyle("uppercase")}><RxLetterCaseUppercase /></span>
                                    <span style={{ cursor: "pointer" }} title='Capitalize' className={styleState.textTransform === "capitalize" ? 'active' : ""} onClick={() => onTextStyle("capitalize")}><MdOutlineTextFields /></span>
                                    <span style={{ cursor: "pointer" }} title='Lowercase' className={styleState.textTransform === "lowercase" ? 'active' : ""} onClick={() => onTextStyle("lowercase")}><RxLetterCaseLowercase /></span>

                                </div>
                            </div>

                            <hr />

                        </div>
                        : "" : ""}

                    {selectedText ?
                        selectedText.type === "text" || selectedText.type === "shape" ?
                            <>
                                {selectedText.type === "text" ?
                                    <FontColor
                                        type="text"
                                        selectedSlideIndex={selectedSlideIndex}
                                        textIndex={textIndex}
                                        color={styleState.color}
                                        isHighlight={false}
                                    /> :
                                    ""
                                }
                                {isGradient ? null :
                                    <FontColor
                                        type="background"
                                        selectedSlideIndex={selectedSlideIndex}
                                        textIndex={textIndex}
                                        color={selectedText.innerCss ? selectedText.innerCss.background : styleState.background}
                                        isHighlight={false}
                                    />
                                }

                            </> : ""
                        : ""}


                    {selectedText ? selectedText.type === "text" ?
                        <>
                            <hr />
                            <div className="opt-select mt-4">
                                <span>Add space between text</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        id='addTtsAll'
                                        checked={selectedText.innerCss && selectedText.type === "text"}
                                        onChange={(e) => handleChecked(e)}
                                    // style={{ width: '28px', height: "19px" }}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                            <div className="opt-select mt-4">
                                <span>Apply to all slides</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={slideAll}
                                        disabled={slideAll}
                                        onChange={() => applyChanges()}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </>

                        : "" : ""}

                    {isGradient ?
                        <div className="font-block mt-4">
                            <h5 className='text-capitalize'>Gradient</h5>
                            <div className="font-color pt-2">
                                <div className="row sm">
                                    <Gradient
                                        type="left"
                                        styleState={styleState}
                                        selectedSlideIndex={selectedSlideIndex}
                                        textIndex={textIndex}
                                    />
                                    <Gradient
                                        type="right"
                                        styleState={styleState}
                                        selectedSlideIndex={selectedSlideIndex}
                                        textIndex={textIndex}
                                    />
                                </div>
                            </div>
                        </div> : ""}
                </div>
            </div>
        </>
    )
}

export default Text;