import React, { useState } from 'react'
import { BsCloudUpload } from 'react-icons/bs'
import Swal from 'sweetalert2'
import { onUploadMedia } from '../../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import dummyImage from "../../../../../assets/images/dummy.jpg"
import { FaTrash } from 'react-icons/fa'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { onChangeOptinWidgetImage, onChangeWidgetImage } from '../../../../../Redux/Actions/WidgetActions'
import { themeColor } from '../../../../../Global/Global'

const ImageSection = (props) => {
    const dispatch = useDispatch()
    let allowedType = ['image/png', 'image/jpg', 'image/jpeg']
    const [loader, setLoader] = useState({
        upload: ""
    })
    const [percent, setPercent] = useState(0)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        loader: false,
        confirmButtonName: "Remove"
    })

    const selectImage = (url) => {
        if (props.type === "optin") {
            dispatch(onChangeOptinWidgetImage(url))
        } else {
            dispatch(onChangeWidgetImage(url))
        }

        // props.setStyleData((draft) => {
        //     draft.image.url = url
        // })

    }

    const onLoadFile = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            if (allowedType.includes(file.type)) {
                if (file.size < 5000000) {
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('upload_type', "images");
                    setLoader(true)
                    dispatch(onUploadMedia(formData, selectImage, loader, setLoader, setPercent, "image"))

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const onRemoveImg = () => {
        setSweet({
            ...sweet,
            enable: true
        })
    }

    const onConfirm = () => {
        dispatch(onChangeWidgetImage(""))
        setSweet({
            ...sweet,
            enable: false,
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
        })
    }

    return (
        <>
            <div className="widget-elements-title">
                <h6>Image</h6>
                <label className="switch">
                    <input
                        type="checkbox"
                        name='image'
                        onChange={props.handleChangeToogle}
                        checked={props.image.enable}
                    />
                    <span className="slider"></span>
                </label>
            </div>
            {props.image.enable ?
                <div className="input-outer " style={{ position: "relative", cursor: "pointer" }}>
                    <div className="widget-upload ">
                        <div style={{ paddingLeft: "26px", }}>
                            {loader.upload ?
                                <i className='fa fa-spin fa-spinner' style={{ fontSize: 30 }} /> :
                                <div className='wd_upload_img'>

                                    <input type="file" onChange={onLoadFile} />
                                    <BsCloudUpload style={{ marginRight: "10px" }} />
                                    Upload Image
                                </div>
                            }
                        </div>


                        <div className='widget-img-box'>
                            <img src={props.image.url ? props.image.url : dummyImage} />
                            <div className='wd_delete_main'>
                                <div className='wd_delete'>
                                    {props.image.url ?
                                        <FaTrash  color={themeColor} onClick={onRemoveImg} />
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                : null}

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br> <br> Do you want to remove image?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />
        </>
    )
}

export default ImageSection