import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const AiEmailModal = ({ curElem, show, handleClose }) => {

    curElem = { ...curElem, email: curElem.email ? JSON.parse(curElem.email) : [] }
    const auth = useSelector(state => state.auth)
    const [tableBody, setTableBody] = useState("")
    useEffect(() => {
        let text = curElem.body.replace(/<br\s*\/?>/gi, '\n');
        text = text.replace(/<[^>]*>?/gm, '');
        setTableBody(text);
    }, [curElem.body])

    return (
        <Modal Modal className="theme-modal video-modal" show={show} onHide={handleClose} centered >
            <Modal.Body>
                <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "-55px" }} /></div>
                <div className="row">
                    <div className="col-md-6 col-12 mb-3">
                        <label for="exampleInputEmail1" className="">Name</label>
                        <input type="email" className="common-inp alt" id="exampleInputEmail1" aria-describedby="emailHelp" value={curElem.category ? curElem.category : "N/A"} />
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                        <label for="exampleInputPassword1" className="">Phone</label>
                        <input type="tell" className="common-inp alt" id="exampleInputPassword1" value={curElem.phoneNumber ? curElem.phoneNumber : "N/A"} />
                    </div>
                    <div className=" col-6 mb-3">
                        <label for="exampleInputPassword1" className="">Operation</label>
                        <input type="text" className="common-inp alt" id="exampleInputPassword1" value={curElem.operation ? curElem.operation : "N/A"} />
                    </div>
                    <div className=" col-6 mb-3">
                        <label for="exampleInputPassword1" className="">Email</label>
                        <input type="text" className="common-inp alt" id="exampleInputPassword1" value={curElem.email?.length > 0 ? curElem.email : "N/A"} />
                    </div>
                    <div className=" col-12 mb-3">
                        <label for="exampleInputPassword1" className="">Subject</label>
                        <input type="text" className="common-inp alt" id="exampleInputPassword1" value={curElem.subject ? curElem.subject : "N/A"} />
                    </div>

                    <div className=" col-12 mb-3">
                        <label for="exampleInputPassword1" className="">Body</label>
                        <textarea name="" id="" cols="10" rows="12" className="common-inp alt" value={tableBody ? tableBody : "N/A"}></textarea>
                    </div>
                    <div className=" col-12">
                        <div className="d-flex justify-content-end">
                            {+auth.user.isClientAccount === 1 ? null :
                                <Link to={`/lead/create-ai-emails?id=${curElem.id}`} className="theme-btn me-3" style={{ width: "130px" }}><span>Edit</span></Link>
                            }
                            <button className="theme-btn" style={{ width: "130px" }} onClick={handleClose}><span>Close</span></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default AiEmailModal
