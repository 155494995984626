import React, { useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import { useDispatch } from "react-redux";
import AutoPostRow from "./AutoPostRow";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


const AutoPost = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [autoPost, setAutoPost] = useState([])
    const [q, setQ] = useState("")
    const [loader, setLoader] = useState({
        fetch: true
    })

    //Fetch Stuff
    const fetchautoPost = () => {
        dispatch(fetchData("fetch-all-auto-post", {}, setAutoPost, loader, setLoader))
    }

    useEffect(() => {
        fetchautoPost()
    }, [])

    return (
        <>
            <TitleBar title="Auto Post" />

            <div className="site-wrap">
                <div className="site-container">

                    <div className="widget-top">
                        <div className="d-flex align-items-center ">
                            <h6>Your auto post</h6>
                            <div className="searchBar ms-2">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => setQ(e.target.value)}
                                />
                                <span><BsSearch /></span>
                            </div>
                        </div>
                        {+auth.user.isClientAccount === 1 ? null :
                            <Link to={`/post/create?type=Auto Post`} className="theme-btn" ><span>+ Create auto post</span></Link>
                        }
                    </div>


                    <div className="table-area mt-5">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>Name</th>
                                    <th>Keyword</th>
                                    <th># of Images</th>
                                    <th>Frequency of Posts</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th className="text-end">Action</th>
                                </tr>
                                {autoPost.length > 0 ?
                                    autoPost.filter((cur) => {
                                        return cur.name.toLowerCase().includes(q.toLowerCase()) || q === ""
                                    }).length > 0 ?
                                        autoPost.filter((cur) => {
                                            return cur.name.toLowerCase().includes(q.toLowerCase()) || q === ""
                                        }).map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <AutoPostRow
                                                        curElem={curElem}
                                                        autoPost={autoPost}
                                                        setAutoPost={setAutoPost}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={7}>
                                                No auto post found.
                                            </td>
                                        </tr>
                                    :
                                    <tr>
                                        <td className="text-center" colSpan={7}>
                                            {loader.fetch ? <i className="fa fa-spin fa-spinner spin-style" /> : "No auto post created yet."}
                                        </td>
                                    </tr>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default AutoPost;