import React, { useEffect, useState } from 'react'
import { BsFileEarmarkText, BsClockHistory, BsPencil, BsTrash3 } from "react-icons/bs";
import CreatePost from './CreatePost';
import PostRow from './PostRow';
import { useDispatch } from 'react-redux';
import { onFetchBusinessPosts } from '../../../../../Redux/Actions/ReviewAction';

const Post = () => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id, type) => {
        setShow(true)
        setModalType({
            id: id,
            type: type
        })
    }
    const [modalType, setModalType] = useState({
        id: -1,
        type: "create"
    });
    const [post, setPost] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })

    const fetchPosts = () => {
        setLoader({ ...loader, fetch: true });
        let data = {};
        dispatch(onFetchBusinessPosts(data, setPost, loader, setLoader));
    }

    useEffect(() => {
        fetchPosts()
    }, [])

    return (
        <>
            {!show ?
                <div>
                    <h3><BsFileEarmarkText /> Posts</h3>
                    <div className='post-head'>
                        <h4>Offer Posts</h4>
                        <button className='theme-btn' onClick={() => handleShow(-1, "create")}><span>+ Add Post</span></button>
                    </div>
                    <div className='post-list'>
                        {post.length > 0 ?
                            post.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <PostRow
                                            curElem={curElem}
                                            post={post}
                                            setPost={setPost}
                                            fetchPosts={fetchPosts}
                                            handleShow={handleShow}
                                        />
                                    </React.Fragment>
                                )
                            })
                            : <div className="text-center mt-5">
                                <span>No post created yet.</span>
                            </div>
                        }
                    </div>
                </div>
                : <CreatePost
                    type={modalType}
                    handleClose={handleClose}
                    fetchPosts={fetchPosts}
                />
            }
        </>
    )
}

export default Post
