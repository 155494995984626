import React, { useState } from 'react'
import { onCheckSocialData, onCheckSocialStatus } from '../../../../../Redux/Actions/WidgetActions'
import { useDispatch } from 'react-redux'
import InfoBoxFind from './InfoBoxFind'
import { setAlert } from '../../../../../Redux/Actions/AlertActions'
import { themeColor } from '../../../../../Global/Global'

const SocialReviewSection = (props) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState("")

    const handleChangeToggle = (e) => {
        let name = props.type.toLowerCase()
        dispatch(onCheckSocialStatus(name, e.target.checked))
    }

    const handleChange = (e) => {
        let name = props.type.toLowerCase()
        if (e.target.name === "text") {
            if (e.target.value.length <= 30) {
                dispatch(onCheckSocialData(name, e.target.name, e.target.value))
            } else {
                dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
            }
        } else {
            dispatch(onCheckSocialData(name, e.target.name, e.target.value))
        }
    }

    const handleShow = () => {
        if (props.type === "Facebook" || props.type === "Yelp") {
            if (show) {
                setShow("")
            } else {
                setShow(props.type)
            }
        }
    }

    return (
        <>
            <div className="widget-elements-title">
                <h6>{props.type} Review Button</h6>
                <label class="switch">
                    <input
                        type="checkbox"
                        checked={props.data.enable}
                        onChange={handleChangeToggle}
                    />
                    <span class="slider"></span>
                </label>
            </div>
            {props.data.enable ?
                <>
                    <div className="input-outer">
                        <input
                            className="common-inp alt"
                            type="text"
                            name='text'
                            placeholder={`Review us on ${props.type}`}
                            value={props.data.text}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-outer">
                        <input
                            className="common-inp alt"
                            type="text"
                            name='btnUrl'
                            placeholder="Button URL"
                            value={props.data.btnUrl}
                            onChange={handleChange}
                        />
                    </div>
                    <p className="pt-2 widgt-link"><a target="_blank" href={props.officialUrl} style={{ color: props.officialUrl === undefined && themeColor, cursor: "pointer" }} onClick={handleShow} >Where do I find this URL?</a></p>
                    {show === props.type &&
                        <InfoBoxFind
                            data={props.findData}
                        />}
                </> : null}

            <hr />

        </>
    )
}

export default SocialReviewSection