import React, { useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import { useLocation } from "react-router-dom";

import { BsFillStarFill, BsStarHalf, BsStar, BsChatSquareDots, BsChatSquareHeart, BsReplyAll, BsTrash3, BsXLg } from "react-icons/bs";

import Modal from 'react-bootstrap/Modal';
import queryString from "query-string";


const SavedReplies = () => {

    const location = useLocation()
    const { id } = queryString.parse(location.search);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <TitleBar title="Review Inbox" />

            <div className="site-wrap">
                <div className="site-container">
                    <div className="review-container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">

                                <div className="feedback dark">
                                    <div>
                                        <h6 className="pt-0">No saved replies have been created yet.</h6>
                                        <p>Click the "+" button on the right to create a new saved reply.</p>
                                    </div>
                                    <div>
                                        <button className="theme-btn" onClick={handleShow}><span>+</span></button>
                                    </div>
                                </div>

                                <div className="feedback">
                                    <div className="feedback-user">
                                        <div className="feedback-user-img"><img src="https://images.pexels.com/photos/2218786/pexels-photo-2218786.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                    </div>
                                    <div className="feedback-txt">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        <h6>John Doe</h6>
                                        <div className="rating-top-outer">
                                            <div className="rating-top">
                                                <div className="star-group">
                                                    <BsFillStarFill />
                                                    <BsFillStarFill />
                                                    <BsFillStarFill />
                                                    <BsStarHalf />
                                                    <BsStar />
                                                </div>
                                                3.5 <span>80 Reviews</span>
                                            </div>
                                            <div className="revIcon">
                                                <span><BsReplyAll /></span>
                                                <span><BsTrash3 /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Modal className="theme-modal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="modalClose" onClick={handleClose}><BsXLg /></div>
                    <h5>Create saved reply</h5>
                    <div className="input-outer mt-4">
                        <input className="common-inp alt" type="text" placeholder="Enter title for your saved reply" />
                    </div>
                    <div className="input-outer mt-4">
                        <textarea className="common-inp alt" name="" id="" cols="30" rows="6" placeholder="Start typing your reply here"></textarea>
                    </div>
                    <div className="input-outer mt-4">
                        <div className="row">
                            <div className="col-6">
                                <button className="theme-btn bdr full" onClick={handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button className="theme-btn full" onClick={handleClose}><span>Save</span></button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SavedReplies;