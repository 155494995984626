import React, { useEffect, useState } from 'react'
import { BiTrash, BiChevronDownSquare } from "react-icons/bi"
import { MdOutlineEdit, MdPublish, MdSchedule } from "react-icons/md"
import Dropdown from 'react-bootstrap/Dropdown';
import { BsBoxArrowInDown, BsChevronDown } from "react-icons/bs"
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SweetAlert from './SweetAlert';
import { onCheckStatus, onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import dummy16x9 from "../../assets/images/thumb_16x9.png"
import dummy9x16 from "../../assets/images/thumb_9x16.png"
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from 'react-redux';
import { CgScreen } from 'react-icons/cg'
import { baseURL } from '../../Global/Global';
import ScheduleModal from './ScheduleModal';
import PublishModal from '../Pages/CreatePosts/PublishModal';

const VideoCard = ({ curElem, videos, setVideos, isDash }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [show1, setShow1] = useState(false)
    const handleShow1 = () => setShow1(true)
    const handleClose1 = () => setShow1(false)

    const [memberShip, setMemberShip] = useState([])

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [common, setCommon] = useState({
        isEditabel: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
    })

    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = common.isDownloadable
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-video", data, videos, setVideos, setSweet))
    }

    const fetchVideo = (id, interval) => {
        let data = {
            id: id
        }
        dispatch(onCheckStatus(data, common, setCommon, interval))
    }

    const handleOCP = (id) => {
        window.open(`${baseURL}ocp/${id}`, '_blank');
    }



    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = false
            let progress = 100
            let bgColor = "#FF7F50"

            videoUrl = curElem.videoUrl

            switch (+curElem.status) {
                case 1:
                    text = "Generating Script..."
                    showProg = true
                    activeIntrval = true
                    bgColor = "#FF7F50"
                    break;
                case 2:
                    text = "Render Successful"
                    canEdit = true
                    url = "post/editor"
                    bgColor = "#008000"
                    break;
                case 3:
                    canEdit = true
                    text = "Rendering Failed"
                    url = "post/editor"
                    bgColor = "#FF0000"

                    break;
                case 4:
                    canEdit = true
                    text = "Script Generated"
                    url = "post/review-script"
                    bgColor = "#008000"
                    break;
                case 5:
                    text = "Generating Video..."
                    showProg = true
                    activeIntrval = true
                    url = "post/editor"
                    progress = 100
                    bgColor = "#FF7F50"
                    break;
                case 6:
                    canEdit = true
                    text = "Awaiting Customization"
                    url = "post/editor"
                    bgColor = "#191970"
                    break;
                case 7:
                    canEdit = true
                    text = "Downloading Resources..."
                    activeIntrval = true
                    showProg = true
                    url = "post/editor"
                    bgColor = "#FF7F50"
                    break;
                case 8:
                    canEdit = true
                    activeIntrval = true
                    url = "post/editor"
                    showProg = true
                    text = curElem.renderProgress
                    bgColor = "#DAA520"

                    break;
                case 9:
                    text = "Rendering"
                    canEdit = true
                    showRend = true
                    activeIntrval = true
                    url = "post/editor"
                    progress = curElem.renderProgress
                    bgColor = "#DAA520"
                    break;
                case 11:
                    canEdit = true
                    text = "Waiting in Queue..."
                    activeIntrval = true
                    showProg = true
                    url = "post/editor"
                    bgColor = "#4B0082"
                    break;
                default:
                    text = ""
                    canEdit = true
                    url = ""
            }

            setCommon({
                ...common,
                statusText: text,
                isEditabel: canEdit,
                isDownloadable: videoUrl,
                showRendering: showRend,
                urlToRedirect: url,
                showProgAnimation: showProg,
                thumbUrl: curElem.thumbnailUrl,
                failedData: curElem.logFile ? JSON.parse(curElem.logFile) : false,
                progress: progress,
                bgColor: bgColor
            })

            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchVideo(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }
        }
    }, [curElem])


    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <div className={`col-xl-${isDash ? 3 : 4} col-lg-6`}>
            <div className={curElem.dimension === "16x9" ? "video-project-16x9" : 'video-project-9x16'}>
                <div className={curElem.dimension === "16x9" ? 'video-project-img_16x9' : 'video-project-img_9x16'}>
                    <img alt="" src={common.thumbUrl ? common.thumbUrl : curElem.dimension === "16x9" ? dummy16x9 : dummy9x16} style={{ height: "100%", width: "100%" }} />
                </div>
                <div className="Video_Project_Render">
                    <div className="progress" style={{ height: 22, background: "gray", position: "relative", textAlign: "center" }}>
                        {common.showRendering ?
                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${common.progress}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                {/* <span className="progress-bar-text">{common.progress}% {common.statusText}</span> */}
                            </div>
                            :
                            common.showProgAnimation ?
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" title={common.statusText} style={{ width: `${common.progress}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                                    {/* <span className="progress-bar-text">{common.statusText}</span> */}
                                </div>
                                :
                                <div className='non-rendering-bar' data-tooltip-id={`my-tooltip-${curElem.id}`} style={common.statusText === "Rendering Failed" ? { background: "#DC143C" } : {}}>{common.statusText}</div>
                        }
                        {common.statusText === "Rendering Failed" ? <></> :
                            <div style={{ height: "100%", width: "100%", position: "absolute", top: 0 }}>
                                {common.showRendering ?
                                    <span className="progress-bar-text">{common.progress}% {common.statusText}</span>
                                    :
                                    common.showProgAnimation ?
                                        <span className="progress-bar-text">{common.statusText}</span>
                                        : ""
                                }
                            </div>
                        }

                    </div>

                </div>
                <div className="video-project-bottom">
                    <div>
                        <span style={{ fontSize: '15px' }} title={curElem.id} >{curElem.name}</span>
                        <p>{curElem.created.split(" ")[0]}</p>
                    </div>
                    <div className="drop-style-1">
                        <Dropdown>
                            <Dropdown.Toggle variant="r" id="dropdown-basic">
                                <BsChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    {common.isDownloadable ?
                                        <>
                                            <li style={{cursor:"pointer"}}><a onClick={handleDownload}><BsBoxArrowInDown /> Download</a></li>
                                            {+auth.user.isClientAccount === 1 ? "" :
                                                <>
                                                    <li style={{cursor:"pointer"}}><a onClick={handleShow1}><MdPublish /> Publish</a></li>
                                                    <li style={{cursor:"pointer"}}><a onClick={handleShow}><MdSchedule /> Schedule</a></li>
                                                </>
                                            }
                                            {memberShip.includes("enterprise") ||
                                                memberShip.includes("fastpass") ||
                                                memberShip.includes("bundle") ?
                                                <li  style={{cursor:"pointer"}}><a onClick={() => handleOCP(curElem.id)}> <CgScreen />OCP</a></li>
                                                : null}

                                        </>
                                        : null}
                                    {+auth.user.isClientAccount === 1 ? "" :
                                        <>
                                            {common.isEditabel ?
                                                <li ><Link to={`/${common.urlToRedirect}?cid=${curElem.id}`}><MdOutlineEdit /> Edit</Link></li> : null}
                                            <li onClick={() => onDelete(curElem.id)} style={{cursor:"pointer"}}><a><BiTrash /> Delete</a></li>
                                        </>
                                    }
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {common.failedData && common.statusText === "Rendering Failed" ?
                        <Tooltip
                            id={`my-tooltip-${curElem.id}`}
                            style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                            place="bottom"
                            content={common.failedData.errorMessage}
                        />
                        : ""}
                </div>
            </div>

            <ScheduleModal
                show={show}
                handleClose={handleClose}
                id={curElem.id}
                type={"video"}
            />

            <PublishModal
                curElem={curElem}
                show={show1}
                handleClose={handleClose1}
                type={"video"}
            />

            {/* <OcpCopyModal
                setOcp={setOcp}
                ocp={ocp}
            /> */}

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure? <br> <br> You want to delete this campaign."}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </div>
    )
}

export default VideoCard