import React, { useEffect, useState } from 'react'
import { BsFillStarFill, BsReplyAll, BsStar, BsStarHalf, BsTrash3 } from 'react-icons/bs'
import SweetAlert from '../../../CommonComponents/SweetAlert'
import { useDispatch } from 'react-redux'
import { onGenerateReply } from '../../../../Redux/Actions/ReviewAction'
import { useSelector } from 'react-redux'

const ReviewCard = (props) => {

    const { curElem, integrationId } = props
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const socialYoutube = useSelector((state) => state.social.google)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(!show)

    const [reply, setReply] = useState("")
    const [loader, setLoader] = useState({
        generate: false
    });

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleGenerateReply = () => {
        setLoader({ ...loader, generate: true })
        let currentGoogleAccount = socialYoutube.find((val) => +val.id === +integrationId)
        if (currentGoogleAccount) {
            let data = {
                name: currentGoogleAccount.username,
                comment: curElem.data?.comment ? curElem.data?.comment : "",
                sentiment: curElem.reviewSentiment,
                email: props.emails,
                phone: props.phones
            }
            dispatch(onGenerateReply(data, setReply, loader, setLoader))
        }
    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: curElem.id,
            type: "folder"
        }
        // dispatch(onDeleteLeadFolder("delete-folder", data, leadFolderData, index, setSweet, mainCheckedArr, setMainCheckedArr))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const convertNumber = (strNum) => {
        switch (strNum?.toLowerCase()) {
            case "zero":
                return 0;
            case "one":
                return 1;
            case "two":
                return 2;
            case "three":
                return 3;
            case "four":
                return 4;
            case "five":
                return 5;
            default:
                return 0; // Not a number
        }
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true
        })
    }

    useEffect(() => {
        setReply("");
        setShow(false);
    }, [integrationId])

    return (
        <div className="feedback">
            <div className="feedback-user">
                <div className="feedback-user-img"><img src={curElem.data.reviewer.profilePhotoUrl} alt="" /></div>
            </div>
            <div className="feedback-txt" style={{ position: "relative" }}>
                <div className='d-flex justify-content-between align-items-end w-100' >
                    <div>
                        <h6 className='py-1'>{curElem.data.reviewer.displayName}</h6>
                        <div className="rating-top py-1">
                            <div className="star-group">
                                {[...Array(5)].map((_, index) => (
                                    index < convertNumber(curElem.data.starRating) ? (
                                        <BsFillStarFill key={index} />
                                    ) : (
                                        <BsStar key={index} />
                                    )
                                ))}
                            </div>
                            ({convertNumber(curElem.data.starRating)})
                        </div>
                        <p className='py-1'>{curElem.data.comment}</p>
                        <p className='py-1 text-white'>{curElem.data.createTime?.split("T")[0]}</p>
                    </div>
                    <div className="rating-top-outer justify-content-end">
                        <div className="revIcon">
                            {+auth.user.isClientAccount === 1 ? null :
                                <>
                                    <span><BsReplyAll onClick={handleShow} /></span>
                                    {/* <span title='This has been disabled by the administrator' style={{ color: "gray" }} ><BsTrash3 /></span> */}
                                </>
                            }
                        </div>
                    </div>
                </div>
                {show && (
                    <div className="auto_reply row mt-3">
                        <div className="col-8">
                            <div className="auto_input">
                                <textarea id="" cols="30" rows="10" className="common-inp alt pt-2" name="reply" value={reply} style={{ border: "0" }} onChange={(e) => setReply(e.target.value)} ></textarea>
                                <div className="auto_reply_btn">
                                    <button className="theme-btn" onClick={handleGenerateReply} disabled={loader.generate}><span>{loader.generate ? <> Generating reply <i className="fa fa-spinner fa-spin ms-1" /></> : "Generate reply"}</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="auto_send">
                                <button className="theme-btn "><span>Send</span></button>
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
            <div className="feedback_ribbon" style={{ backgroundColor: curElem.reviewSentiment === "Positive" ? "green" : curElem.reviewSentiment === "Negative" ? "red" : "#FFC107" }}>
                <span>{curElem.reviewSentiment}</span>
            </div>
            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? Do you want to delete this review?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </div >
    )
}

export default ReviewCard
