import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { connectSocial } from '../../../Redux/Actions/SocialActions';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';

const ConnectModal = (props) => {
    const FormDataJson = require('form-data-json-convert')
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const [appDetails, setAppDetails] = useState({
        user_id: auth.user.id,
        app_name: props.type
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setAppDetails({
            ...appDetails,
            [name]: value
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        let obj = {...appDetails}
        let url = ""
        if (props.type === "smtp") {
            url = "smtp-add"
        } else {
            url = "twilio-account-add"
        }

        if (props.type === "autoresponder") {
            url = "connect-auto"
            let element = document.getElementById("auto-form")
            let form = element.getElementsByTagName("form")
            let data = FormDataJson.toJson(document.querySelector("#auto-form"), { includeDisabled: true, includeButtonValues: true, arrayify: true })
            data.postUrl = form[0].action
             obj = {
                name: appDetails.name,
                code: data,
                appName: props.type
            }
        }

        dispatch(connectSocial(url, obj, props.name, props.handleClose, setLoader))
    }



    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <form onSubmit={onFormSubmit}>
                    <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                    <div className="connect-modal">
                        <h2 className='text-capitalize'>Connect {props.name === "Autoresponders" ? "Your" : ""}<span className={props.type === "smtp" ? "text-uppercase" : ""}> {props.type}</span> </h2>
                        {props.type === "smtp" ?
                            <>
                                <div className="input-wrap">
                                    <label htmlFor="">Enter Host</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp"
                                            type="text"
                                            placeholder="Enter Host"
                                            name='host'
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Enter Port</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp"
                                            type="number"
                                            placeholder="Enter Port"
                                            name='port'
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Enter Username</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp"
                                            type="text"
                                            placeholder="Enter Username"
                                            onChange={handleChange}
                                            name='username'
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Enter Password</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp"
                                            type="password"
                                            placeholder="Enter Password"
                                            onChange={handleChange}
                                            name='password'
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Enter Email</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp"
                                            type="email"
                                            placeholder="Enter Email"
                                            onChange={handleChange}
                                            name='from'
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">SSL</label>
                                    <div className="input-outer">
                                        <select
                                            className="common-inp"
                                            onChange={handleChange}
                                            name='ssl'
                                            required
                                        >
                                            <option value="">Select Option</option>
                                            <option value="ssl">Yes</option>
                                            <option value="tls">No</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                            :

                            props.type === "twilio" ?
                                <>
                                    <div className="input-wrap">
                                        <label htmlFor="">Enter Account SID</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="text"
                                                placeholder="Enter SID"
                                                name='sId'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">Enter Auth Token</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="text"
                                                placeholder="Enter Auth Token"
                                                name='token'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">Enter From Number</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="number"
                                                placeholder="Enter Number"
                                                name='number'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">Enter To Number (Need for testing)</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="number"
                                                placeholder="Enter number"
                                                onChange={handleChange}
                                                name='toNumber'
                                                required
                                            />
                                        </div>
                                    </div>
                                </> :
                                props.type === "autoresponder" ? <>
                                    <div className="input-wrap">
                                        <label htmlFor="">Name</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="name"
                                                placeholder="Enter Name Here"
                                                name='name'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="input-wrap">
                                        <label htmlFor="">Opt-in Code</label>
                                        <div className="input-outer">
                                            <textarea
                                                className="common-inp"
                                                type="text"
                                                placeholder="Enter Code Here"
                                                name='code'
                                                onChange={handleChange}
                                                required
                                                rows={8}
                                            />
                                        </div>
                                    </div>

                                </> : null

                        }
                    </div>
                    <div className="group-btn">
                        <div className="row">
                            <div className="col-6">
                                <button className="theme-btn full bdr" style={{ cursor: "pointer" }} type='button' onClick={props.handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button className="theme-btn full" type='submit' style={{ cursor: "pointer" }}><span>{loader ? <>Connecting <FaSpinner className="spin" /></> : "Connect"}</span></button>
                            </div>
                        </div>
                    </div>
                </form>

                <div id='auto-form' style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: appDetails.code }} />
            </Modal.Body>
        </Modal>
    )
}

export default ConnectModal