import React, { useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import { fetchData, onPostStatusCheck } from "../../../../Redux/Actions/CommonActions";
import { useDispatch } from "react-redux";
import AutoInnerPostRow from "./AutoInnerPostRow";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { GoArrowLeft } from "react-icons/go";


const AutoInnerPost = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const { id } = queryString.parse(location.search)
    const { name, dimension, noi } = location.state

    const [autoPost, setAutoPost] = useState([])
    const [generatedData, setGeneratedData] = useState(false)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const fetchStatus = (interval) => {
        let data = {
            id: id
        }
        dispatch(onPostStatusCheck(data, fetchautoPost, setGeneratedData, interval, "inner", generatedData))
    }

    useEffect(() => {
        if (+autoPost.length !== +noi) {
            const interval = setInterval(() => {
                fetchStatus(interval)
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        }
        setGeneratedData(autoPost.length)
    }, [autoPost])



    //Fetch Stuff
    const fetchautoPost = () => {
        let data = {
            id: id
        }
        dispatch(fetchData("fetch-inner-auto-post", data, setAutoPost, loader, setLoader))
    }

    useEffect(() => {
        fetchautoPost()
    }, [])

    return (
        <>
            <TitleBar title="Auto Posts" />

            <div className="site-wrap">
                <div className="site-container">
                    <div className="widget-top">
                        <div className="d-flex align-items-center ">
                            <h6>Your generated posts</h6>
                        </div>
                        <div className="d-flex align-items-center">
                            {(+generatedData !== +noi) && generatedData ?
                                <div>
                                    <i className="fa fa-spin fa-spinner" />  Generated {generatedData} / {noi}
                                </div>
                                : null
                            }
                            <Link to={`/post/auto-post`} className='theme-btn ms-2' style={{ width: "130px" }}> <span> <GoArrowLeft />Back</span></Link>
                        </div>
                    </div>

                    <div className="table-area mt-5">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Created</th>
                                    <th className="text-end">Action</th>
                                </tr>
                                {autoPost.length > 0 ?
                                    autoPost.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <AutoInnerPostRow
                                                    curElem={curElem}
                                                    autoPost={autoPost}
                                                    setAutoPost={setAutoPost}
                                                    name={name}
                                                    dimension={dimension}
                                                    noi={noi}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <tr >
                                        <td colSpan={7} className="text-center">
                                            {loader.fetch ? <i className="fa fa-spin fa-spinner spin-style" /> : "No auto post found."}
                                        </td>
                                    </tr>
                                }
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AutoInnerPost;