import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { BsListUl, BsExclamationCircle } from "react-icons/bs";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onUpdateServiceInfo } from '../../../../Redux/Actions/ReviewAction';
import OpeningHours from './OpeningHours';
import { useImmer } from 'use-immer';


const ServicesInfo = (props) => {

    const { businessDetails } = props
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const [loader, setLoader] = useState({
        submit: false
    })
    const [serviceTypes, setServiceTypes] = useState([]);

    const [state, setState] = useImmer({
        town: businessDetails.town,
        serviceTypesId: "",
        openingHours: [
            {
                day: "Sunday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Monday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Tuesday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Wednesday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Thursday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Friday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Saturday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            }
        ]
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, submit: true })
        let data = { ...state }
        dispatch(onUpdateServiceInfo(data, loader, setLoader))
    }

    useEffect(() => {
        if (businessDetails) {
            let arr = [...state.openingHours]
            if (businessDetails?.regularHours?.length > 0) {
                arr = businessDetails.regularHours.map((curElem) => {
                    return (
                        {
                            day: curElem.openDay,
                            enable: true,
                            fromTime: `${curElem.openTime.hours}:${curElem.openTime.minutes}`,
                            toTime: `${curElem.closeTime.hours}:${curElem.closeTime.minutes}`,
                        }
                    )
                })
            }
            setState({
                ...state,
                town: businessDetails.town,
                openingHours: arr
            })
            setServiceTypes(businessDetails.businessCategory?.serviceTypes ? businessDetails.businessCategory.serviceTypes : [])
        }
    }, [businessDetails])

    return (
        <div>
            <h3><BsListUl /> Services Info</h3>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='input-wrap'>
                        <label htmlFor="">Service Offered
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                        </label>
                        <select
                            className='common-inp alt'
                            name="serviceTypesId"
                            value={state.serviceTypesId}
                            onChange={handleChange}
                        >
                            <option value="">Select service</option>
                            {serviceTypes.length > 0 ?
                                serviceTypes.map((curElem, index) => {
                                    return (
                                        <option key={index} value={curElem.serviceTypeId}>{curElem.displayName}</option>
                                    )
                                })
                                : ""
                            }
                        </select>
                    </div>
                    <div className='input-wrap'>
                        <label htmlFor="">Service Area
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                            {/* <span className='label-link'>+ Add Area</span> */}
                        </label>
                        <input
                            className='common-inp alt'
                            type="text"
                            placeholder='Enter a Location'
                            name='town'
                            value={state.town}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='openTime'>
                        {state.openingHours?.length > 0 ?
                            state.openingHours.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <OpeningHours
                                            curElem={curElem}
                                            index={index}
                                            format={"24"}
                                            setState={setState}
                                        />
                                    </React.Fragment>
                                )
                            })
                            : ""
                        }
                    </div>
                </div>
            </div>

            <hr />

            <div className='input-wrap'>
                <button type="button" className='theme-btn'><span>{loader.submit ? <> Updating <i className="fa fa-spinner fa-spin" /></> : "Update"}</span></button>
            </div>

        </div>
    )
}

export default ServicesInfo
