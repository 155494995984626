import React from 'react'
import { BsExclamationCircle } from 'react-icons/bs'

const OpeningHours = ({ curElem, index, format, setState }) => {

    const handleTime = (e, isFrom, isTo) => {
        const { name, value, checked } = e.target

        if (name === "enable") {
            setState((preData) => {
                preData.openingHours[index].enable = checked
            })
        }
        else if (name === "meridiem") {
            if (isFrom) {
                setState((preData) => {
                    preData.openingHours[index].fromMeridiem = value
                })
            }
            if (isTo) {
                setState((preData) => {
                    preData.openingHours[index].toMeridiem = value
                })
            }
        }
        else {
            if (isFrom) {
                setState((preData) => {
                    preData.openingHours[index].fromTime = value
                })
            }
            if (isTo) {
                setState((preData) => {
                    preData.openingHours[index].toTime = value
                })
            }
        }
    }

    return (
        <div className='opentime-row'>
            <div className='opentime-single'>
                <label className='table-check ' htmlFor="">
                    <input
                        type="checkbox"
                        name="enable"
                        checked={curElem.enable}
                        onChange={(e) => handleTime(e, index)}
                    />
                    <span className='check-mark'></span>
                </label>
                <span>{curElem.day}</span>
                <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
            </div>

            {curElem.enable ?
                <>
                    <div className='opentime-single'>
                        <select
                            className='common-inp alt'
                            name="from"
                            value={curElem.fromTime}
                            onChange={(e) => handleTime(e, true, false)}
                        >
                            <option value="">Opens at</option>
                            <option value="00:00">00:00 AM</option>
                            <option value="00:30">00:30 AM</option>
                            <option value="1:00">1:00 AM</option>
                            <option value="1:30">1:30 AM</option>
                            <option value="2:00">2:00 AM</option>
                            <option value="2:30">2:30 AM</option>
                            <option value="3:00">3:00 AM</option>
                            <option value="3:30">3:30 AM</option>
                            <option value="4:00">4:00 AM</option>
                            <option value="4:30">4:30 AM</option>
                            <option value="5:00">5:00 AM</option>
                            <option value="5:30">5:30 AM</option>
                            <option value="6:00">6:00 AM</option>
                            <option value="6:30">6:30 AM</option>
                            <option value="7:00">7:00 AM</option>
                            <option value="7:30">7:30 AM</option>
                            <option value="8:00">8:00 AM</option>
                            <option value="8:30">8:30 AM</option>
                            <option value="9:00">9:00 AM</option>
                            <option value="9:30">9:30 AM</option>
                            <option value="10:00">10:00 AM</option>
                            <option value="10:30">10:30 AM</option>
                            <option value="11:00">11:00 AM</option>
                            <option value="11:30">11:30 AM</option>
                            <option value="12:00">12:00 PM</option>
                            <option value="12:30">12:30 PM</option>
                            <option value="13:00">1:00 PM</option>
                            <option value="13:30">1:30 PM</option>
                            <option value="14:00">2:00 PM</option>
                            <option value="14:30">2:30 PM</option>
                            <option value="15:00">3:00 PM</option>
                            <option value="15:30">3:30 PM</option>
                            <option value="16:00">4:00 PM</option>
                            <option value="16:30">4:30 PM</option>
                            <option value="17:00">5:00 PM</option>
                            <option value="17:30">5:30 PM</option>
                            <option value="18:00">6:00 PM</option>
                            <option value="18:30">6:30 PM</option>
                            <option value="19:00">7:00 PM</option>
                            <option value="19:30">7:30 PM</option>
                            <option value="20:00">8:00 PM</option>
                            <option value="20:30">8:30 PM</option>
                            <option value="21:00">9:00 PM</option>
                            <option value="21:30">9:30 PM</option>
                            <option value="22:00">10:00 PM</option>
                            <option value="22:30">10:30 PM</option>
                            <option value="23:00">11:00 PM</option>
                            <option value="23:30">11:30 PM</option>
                            <option value="12:00">12:00 PM</option>
                            <option value="12:30">12:30 PM</option>
                        </select>
                    </div>
                    <div className='opentime-single'>
                        <select
                            className='common-inp alt'
                            name="to"
                            value={curElem.toTime}
                            onChange={(e) => handleTime(e, false, true)}
                        >
                            <option value="">Closes at</option>
                            <option value="00:00">00:00 AM</option>
                            <option value="00:30">00:30 AM</option>
                            <option value="1:00">1:00 AM</option>
                            <option value="1:30">1:30 AM</option>
                            <option value="2:00">2:00 AM</option>
                            <option value="2:30">2:30 AM</option>
                            <option value="3:00">3:00 AM</option>
                            <option value="3:30">3:30 AM</option>
                            <option value="4:00">4:00 AM</option>
                            <option value="4:30">4:30 AM</option>
                            <option value="5:00">5:00 AM</option>
                            <option value="5:30">5:30 AM</option>
                            <option value="6:00">6:00 AM</option>
                            <option value="6:30">6:30 AM</option>
                            <option value="7:00">7:00 AM</option>
                            <option value="7:30">7:30 AM</option>
                            <option value="8:00">8:00 AM</option>
                            <option value="8:30">8:30 AM</option>
                            <option value="9:00">9:00 AM</option>
                            <option value="9:30">9:30 AM</option>
                            <option value="10:00">10:00 AM</option>
                            <option value="10:30">10:30 AM</option>
                            <option value="11:00">11:00 AM</option>
                            <option value="11:30">11:30 AM</option>
                            <option value="12:00">12:00 PM</option>
                            <option value="12:30">12:30 PM</option>
                            <option value="13:00">1:00 PM</option>
                            <option value="13:30">1:30 PM</option>
                            <option value="14:00">2:00 PM</option>
                            <option value="14:30">2:30 PM</option>
                            <option value="15:00">3:00 PM</option>
                            <option value="15:30">3:30 PM</option>
                            <option value="16:00">4:00 PM</option>
                            <option value="16:30">4:30 PM</option>
                            <option value="17:00">5:00 PM</option>
                            <option value="17:30">5:30 PM</option>
                            <option value="18:00">6:00 PM</option>
                            <option value="18:30">6:30 PM</option>
                            <option value="19:00">7:00 PM</option>
                            <option value="19:30">7:30 PM</option>
                            <option value="20:00">8:00 PM</option>
                            <option value="20:30">8:30 PM</option>
                            <option value="21:00">9:00 PM</option>
                            <option value="21:30">9:30 PM</option>
                            <option value="22:00">10:00 PM</option>
                            <option value="22:30">10:30 PM</option>
                            <option value="23:00">11:00 PM</option>
                            <option value="23:30">11:30 PM</option>
                            <option value="12:00">12:00 PM</option>
                            <option value="12:30">12:30 PM</option>
                        </select>
                    </div>
                </>
                : ""
            }
        </div>
    )
}

export default OpeningHours