import React, { useEffect, useState } from 'react'
import { BsTrash3, BsEye } from "react-icons/bs";
import SweetAlert from '../../../CommonComponents/SweetAlert';
import { onDeleteCampaign, onPostStatusCheck } from '../../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AutoPostRow = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [common, setCommon] = useState(props.curElem)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })


    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: props.curElem.id,
            type: ""
        }
        dispatch(onDeleteCampaign("delete-auto-post", data, props.autoPost, props.setAutoPost, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }


    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }


    const fetchStatus = (interval) => {
        let data = {
            id: props.curElem.id
        }
        dispatch(onPostStatusCheck(data, common, setCommon, interval))
    }

    useEffect(() => {
        if (+props.curElem.status === 0) {
            const interval = setInterval(() => {
                fetchStatus(interval)
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        }
        setCommon(props.curElem)
    }, [props.curElem])

    return (
        <tr>
            <td>{common.name}</td>
            <td>{common.keyword}</td>
            <td>{common.noOfImages}</td>
            <td>{common.frequency === "everyday" ? "Everyday" : `After every ${common.days} days`}</td>
            <td>
                <span
                    className='post-url-btn'
                    style={{ backgroundColor: +common.status === 1 ? "#008000" : "#FF7F50", fontSize: 12 }}
                >
                    {+common.status === 1 ? "Generated" : <> Generating {common.status.number ? common.status.number : 0} / {common.noOfImages}  </>}
                </span>
            </td>
            <td>{common.created}</td>
            <td className="text-end">
                <span className="widg-icon">
                    {+common.status || common.status.number ?
                        <Link to={`/post/auto-inner-post?id=${common.id}`} state={{ name: common.name, dimension: common.dimension , noi: common.noOfImages}} title='View'><BsEye /></Link>
                        : null}
                    {+common.status ?
                        <>

                            {+auth.user.isClientAccount === 1 ? null :
                                <>
                                    <a onClick={deleteRow} title='Delete'><BsTrash3 /></a>
                                </>
                            }
                        </>
                        : null}
                </span>
            </td>

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure?<br><br> Do you want to delete this auto post?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />


        </tr>
    )
}

export default AutoPostRow