import React, { useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SmtpForm from "./SmtpForm";
import CommonWysiwyg from "../../../CommonComponents/CommonWysiwyg";
import Swal from "sweetalert2";
import { onFetchEmailTemplates, onSaveContactLead, onSendContactLead, onTestContactLead } from "../../../../Redux/Actions/LeadAction";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import { useNavigate } from "react-router-dom";
import { themeColor } from "../../../../Global/Global";
import Select from 'react-select';


const CreateContactLead = () => {

    const dispatch = useDispatch();
    const navigator = useNavigate();
    const leadFolderData = useSelector((state) => state.lead.data);
    const socialData = useSelector((state) => state.social);
    const [isSave, setIsSave] = useState(false)
    const [testEmail, setTestEmail] = useState("")
    const [template, setTemplates] = useState();
    const [emailOptions, setEmailOptions] = useState([]);
    const [state, setState] = useState({
        fId: "",
        leadId: "",
        leadType: "",
        leadName: "",
        leadEmail: [],
        customEmailInput: "",
        leadPhoneNumber: "",
        leadOperation: "",
        type: "",
        contact: "",
        integrationId: "",
        name: "",
        category: "",
        smtpEmail: "",
        file: "",
        subject: "",
        attachment: "",
        body: ""
    });

    const [loader, setLoader] = useState({
        save: false,
        test: false,
        submit: false
    });

    const onChangeLead = (e) => {
        const { value } = e.target
        if (value === "all" || value === "") {
            setState({
                ...state,
                leadId: value,
                leadType: value,
                leadName: "",
                leadPhoneNumber: "",
                leadOperation: "",
                leadEmail: []
            })
        } else {
            let currentFolder = leadFolderData.find((folder) => +folder.fId === +state.fId)
            let currentLead = currentFolder.leads.find((lead) => +lead.id === +value)

            let emailOptions = []
            if (currentLead.emails.length > 0) {
                emailOptions = JSON.parse(currentLead.emails).map((ele) => {
                    return (
                        { value: ele, label: ele }
                    )
                })
            }
            setEmailOptions(emailOptions)
            setState({
                ...state,
                leadId: currentLead.id,
                leadType: "one",
                leadName: currentLead.businessName || "",
                leadPhoneNumber: currentLead.phoneNumber || "",
                leadEmail: emailOptions || [],
                leadOperation: currentLead.businessStatus || ""
            })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "category") {
            const currentTemplate = template.find(val => val.category === value)
            setState((preData) => {
                return {
                    ...preData,
                    subject: currentTemplate?.subject || "",
                    body: currentTemplate?.body || "",
                    category: currentTemplate?.category || ""
                }
            })
        } else {
            setState({ ...state, [name]: value })
        }
    }

    const dataFunction = (value, name) => {
        setState({ ...state, [name]: value })
    }

    const handleEmailInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setState({ ...state, customEmailInput: inputValue });
        return inputValue;
    };

    const handleEmailKeyDown = (event) => {
        if (event.key === 'Enter' && state.customEmailInput.trim() !== '') {
            event.preventDefault();
            const newEmailOption = { value: state.customEmailInput.toLowerCase(), label: state.customEmailInput };
            setEmailOptions([...emailOptions, newEmailOption]);
            setState({ ...state, leadEmail: [...state.leadEmail, newEmailOption], customEmailInput: '' });
        }
    };

    const onInputChange = (e) => {
        let allowedExt = ['text/csv', 'application/pdf', 'image/jpeg', 'image/png'];
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    setState({ ...state, file: e.target.files[0] })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for file is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let arr = []
        arr = [...arr, state.leadEmail.map((val) => [val.value])]
        setLoader({ ...loader, save: true })
        const formData = new FormData()
        formData.append('fId', state.fId)
        formData.append('integrationId', state.integrationId)
        formData.append('type', state.type)
        formData.append('name', state.name)
        formData.append('category', state.category)
        formData.append('subject', state.subject)
        formData.append('body', state.body)
        formData.append('file', state.file)
        formData.append('leadId', state.leadType === "all" ? "" : state.leadId)
        formData.append('leadType', state.leadType)
        formData.append('leadName', state.leadName)
        formData.append('leadPhoneNumber', state.leadPhoneNumber)
        formData.append('email', arr);
        formData.append('leadOperation', state.leadOperation)
        dispatch(onSaveContactLead(formData, loader, setLoader, setIsSave))
    }

    const handleSendEmail = () => {
        if (state.fId) {
            setLoader({ ...loader, submit: true })
            let data = {
                fId: state.fId,
            }
            dispatch(onSendContactLead(data, loader, setLoader, navigator))
        } else {
            dispatch(setAlert("Please select folder", "danger"))
        }
    }

    const handleTestEmail = () => {
        if (state.fId && testEmail) {
            setLoader({ ...loader, test: true })
            let data = {
                fId: state.fId,
                email: testEmail
            }
            dispatch(onTestContactLead(data, loader, setLoader))
        }
    }

    const fetchEmailTemplate = () => {
        let data = {}
        dispatch(onFetchEmailTemplates(data, setTemplates))
    }

    useEffect(() => {
        fetchEmailTemplate()
    }, [])

    useEffect(() => {
        if (isSave) {
            setIsSave(false)
        }
    }, [state])

    return (
        <>
            <TitleBar title="Contact Leads" />

            <div className="site-wrap">
                <div className="site-container">
                    <div className="widget-top">
                        <div className="widget-top-left">
                            <h6>Contact Leads</h6>
                        </div>
                    </div>

                    <div className="bulk-wrap">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="input-outer mt-4">
                                        <label className="pb-2" htmlFor="">Choose a folder to which you need to send the email</label>
                                        <select
                                            className="common-inp alt"
                                            name="fId"
                                            value={state.fId}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select Folder</option>
                                            {leadFolderData.length > 0 ?
                                                leadFolderData.map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={curElem.fId}>{curElem.folderName}</option>
                                                    )
                                                })
                                                : ""
                                            }
                                        </select>
                                    </div>
                                </div>


                                <div className="col-lg-6">
                                    <div className="input-outer mt-4">
                                        <label className="pb-2" htmlFor="">Choose a lead to which you need to send the email</label>
                                        <select
                                            className="common-inp alt"
                                            name="leadId"
                                            value={state.leadId}
                                            onChange={onChangeLead}
                                            required
                                        >
                                            <option value="">Select Lead</option>
                                            <option value="all" style={{ fontWeight: "bold" }}>All</option>
                                            {leadFolderData.length > 0 ?
                                                leadFolderData.filter((folder) => {
                                                    return +folder.fId === +state.fId
                                                }).map((currentFolder) =>
                                                    currentFolder.leads.map((curElem, index) => {
                                                        return (
                                                            <option key={index} value={curElem.id}>{curElem.businessName}</option>
                                                        )
                                                    })
                                                )
                                                : ""
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                                    <hr style={{ color: themeColor, opacity: "1" }} />
                                </div>

                                {state.leadType === "all" || state.leadType === "" ? "" :
                                    <>
                                        <div className="col-lg-6">
                                            <div className="input-outer mt-4">
                                                <label className="pb-2" htmlFor="">Name</label>
                                                <input
                                                    type="text"
                                                    className="common-inp alt"
                                                    placeholder="Enter name"
                                                    name="leadName"
                                                    value={state.leadName}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input-outer mt-4">
                                                <label className="pb-2" htmlFor="">Phone</label>
                                                <input
                                                    type="text"
                                                    className="common-inp alt"
                                                    placeholder="Enter phone"
                                                    name="leadPhoneNumber"
                                                    value={state.leadPhoneNumber ? state.leadPhoneNumber : "N/A"}
                                                    onChange={handleChange}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input-outer mt-4">
                                                <label className="pb-2" htmlFor="">Operation</label>
                                                <input
                                                    type="text"
                                                    className="common-inp alt"
                                                    placeholder="Enter operation"
                                                    name="leadOperation"
                                                    value={state.leadOperation}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input-outer mt-4">
                                                <label className="pb-2" htmlFor="">Email</label>
                                                {/* <input
                                                    type="text"
                                                    className="common-inp alt"
                                                    placeholder="Enter email"
                                                    name="leadEmail"
                                                    value={state.leadEmail}
                                                    onChange={handleEmails}
                                                /> */}
                                                <Select
                                                    className="common-inp alt"
                                                    isMulti
                                                    options={emailOptions}
                                                    value={state.leadEmail}
                                                    isDisabled={true}
                                                    // onChange={(e) => dataFunction(e, "leadEmail")}
                                                    // inputValue={state.customEmailInput}
                                                    // onInputChange={handleEmailInputChange}
                                                    // onKeyDown={handleEmailKeyDown}
                                                    placeholder="N/A"
                                                />
                                            </div>
                                        </div>

                                        <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                                            <hr style={{ color: themeColor, opacity: "1" }} />
                                        </div>
                                    </>
                                }


                                <div className="col-lg-12">
                                    <div className="input-outer mt-4">
                                        <label className="pb-2" htmlFor="">Please select one contact method</label>
                                        <select
                                            className="common-inp alt"
                                            name="type"
                                            value={state.type}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select Method</option>
                                            {socialData.smtp.length > 0 ? <option value="smtp">SMTP</option> : ""}
                                            {socialData.twilio.length > 0 ? <option value="twilio">Twilio</option> : ""}
                                        </select>
                                    </div>
                                </div>

                                {state.type === "smtp" ?
                                    <SmtpForm
                                        type="email"
                                        state={state}
                                        setState={setState}
                                        handleChange={handleChange}
                                        onInputChange={onInputChange}
                                        socialData={socialData}
                                        template={template}
                                    />
                                    :
                                    state.type === "twilio" ?
                                        <div className="col-lg-6">
                                            <div className="input-outer mt-4">
                                                <label className="pb-2" htmlFor="">Please select one of your Twilio</label>
                                                <select
                                                    className="common-inp alt"
                                                    name="contact"
                                                    value={state.contact}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select Number</option>
                                                    {socialData.twilio.length > 0 ?
                                                        socialData.twilio.map((curElem, index) => {
                                                            return (
                                                                <option key={index} value={curElem.number}>{curElem.number}</option>
                                                            )
                                                        })
                                                        : ""
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        : ""
                                }

                            </div>
                            <CommonWysiwyg
                                text={state.body.replaceAll("\n", "<br>")}
                                name={"body"}
                                dataFunction={dataFunction}
                            />
                            <div className="input-outer mt-4">
                                <div className="row">
                                    <div className="col-lg-3 mb-lg-0 mb-md-3 mb-3 d-flex">
                                        <button type="button" className="theme-btn bdr full mx-2" style={{ backgroundColor: isSave ? "" : "#6c757d" }} disabled={!isSave} onClick={handleSendEmail}><span>{loader.submit ? <> Sending <i className="fa fa-spinner fa-spin ms-1" /></> : state.type === "twilio" ? "Send message" : "Send Email"}</span></button>
                                        <button type="submit" className="theme-btn full"><span>{loader.save ? <> Saving <i className="fa fa-spinner fa-spin ms-1" /></> : "Save"}</span></button>
                                    </div>
                                    <div className="col-lg-6 mb-lg-0 mb-md-3 mb-3">
                                        <input
                                            type={state.type === "twilio" ? "number" : "text"}
                                            className="common-inp alt"
                                            placeholder={state.type === "twilio" ? "Enter the number in Which you send the text message" : "Enter the email in Which you send the test email"}
                                            value={testEmail}
                                            onChange={(e) => setTestEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-lg-3 ">
                                        <button type="button" className="theme-btn bdr full" style={{ backgroundColor: isSave ? "" : "#6c757d" }} disabled={!isSave} onClick={handleTestEmail}><span>{loader.test ? <> Sending <i className="fa fa-spinner fa-spin ms-1" /></> : state.type === "twilio" ? "Send Text Message" : "Send Test Email"}</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateContactLead;