import React, { useEffect, useState } from 'react'
import { FaBold, FaItalic, FaUnderline, FaAlignLeft } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { onFetchFont } from '../../../../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import WidgetColor from '../WidgetColor';
import { FiAlignCenter, FiAlignLeft, FiAlignRight } from "react-icons/fi";
import { onChangeOptinStyle, onChangeSocialStyle, onChangeStyle, onCheckSocialData } from '../../../../../../Redux/Actions/WidgetActions';
import { themeColor } from '../../../../../../Global/Global';



const WindowStyle = () => {
    const dispatch = useDispatch()
    const widget = useSelector(state => state.widget.data)
    const optin = useSelector(state => state.widget.optinData)

    const socialNames = ["google", "bing", "angi", "zillow", "foursquare", "trustpilot", "tripadvisor", "shopify", "facebook", "yelp"]
    const sizeArr = Array.from({ length: 41 }, (_, index) => index + 10 + "px");
    const [font, setFont] = useState([])
    const [isIcon, setIsIcon] = useState(true)
    const [toggle, setToggle] = useState(false)
    const [dataStyle, setDataStyle] = useState({
        fontFamily: "",
        fontSize: 10,
        textTransform: "",
        color: "",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        textAlign: "",
        background: ""
    })

    const handleChange = (e) => {
        let { name, value, checked } = e.target
        if (name === "icon") {
            dispatch(onCheckSocialData(widget.activeStyle, name, checked))
            setIsIcon(checked)
        } else if (socialNames.includes(widget.activeStyle)) {
            name = name === "textAlign" ? "justifyContent" : name
            dispatch(onChangeSocialStyle(widget.activeStyle, name, value))
        } else {
            if (widget) {
                dispatch(onChangeStyle(widget.activeStyle, name, value))
            } else if (optin) {
                dispatch(onChangeOptinStyle(optin.activeStyle, name, value))
            }
        }
    }

    const handleChangeColor = (val, type) => {
        let obj = {
            target: {
                name: type,
                value: val
            }
        }
        handleChange(obj)
    }

    const handleFormate = (type) => {
        let obj = {
            target: {
                name: "",
                value: ""
            }
        }
        if (type === "w") {
            obj.target.name = "fontWeight"
            obj.target.value = dataStyle.fontWeight === "bold" ? "" : "bold"
        } else if (type === "i") {
            obj.target.name = "fontStyle"
            obj.target.value = dataStyle.fontStyle === "" ? "italic" : ""
        } else if (type === "u") {
            obj.target.name = "textDecoration"
            obj.target.value = dataStyle.textDecoration === "" ? "underline" : ""
        } else if (type === "al") {
            obj.target.name = "textAlign"
            obj.target.value = "left"
        } else if (type === "ac") {
            obj.target.name = "textAlign"
            obj.target.value = "center"
        } else if (type === "ar") {
            obj.target.name = "textAlign"
            obj.target.value = "right"
        }

        handleChange(obj)
    }

    const handleToggle = (e) => {
        e.stopPropagation()
        setToggle(!toggle)
    }

    useEffect(() => {
        let styleCss = {}
        if (widget) {
            if (widget.activeStyle === "headline") {
                styleCss = widget.data.headline.css
            }
            else if (widget.activeStyle === "description") {
                styleCss = widget.data.description.css
            }
            else if (widget.activeStyle === "google") {
                styleCss = widget.data.socials.google.css
                setIsIcon(widget.data.socials.google.icon)
            }
            else if (widget.activeStyle === "bing") {
                styleCss = widget.data.socials.bing.css
                setIsIcon(widget.data.socials.bing.icon)
            }
            else if (widget.activeStyle === "angi") {
                styleCss = widget.data.socials.angi.css
                setIsIcon(widget.data.socials.angi.icon)
            }
            else if (widget.activeStyle === "zillow") {
                setIsIcon(widget.data.socials.zillow.icon)
                styleCss = widget.data.socials.zillow.css
            }
            else if (widget.activeStyle === "foursquare") {
                setIsIcon(widget.data.socials.foursquare.icon)
                styleCss = widget.data.socials.foursquare.css
            }
            else if (widget.activeStyle === "trustpilot") {
                setIsIcon(widget.data.socials.trustpilot.icon)
                styleCss = widget.data.socials.trustpilot.css
            }
            else if (widget.activeStyle === "tripadvisor") {
                setIsIcon(widget.data.socials.tripadvisor.icon)
                styleCss = widget.data.socials.tripadvisor.css
            }
            else if (widget.activeStyle === "shopify") {
                setIsIcon(widget.data.socials.shopify.icon)
                styleCss = widget.data.socials.shopify.css
            }
            else if (widget.activeStyle === "facebook") {
                setIsIcon(widget.data.socials.facebook.icon)
                styleCss = widget.data.socials.facebook.css
            }
            else if (widget.activeStyle === "yelp") {
                setIsIcon(widget.data.socials.yelp.icon)
                styleCss = widget.data.socials.yelp.css
            }
        } else if (optin) {
            if (optin.activeStyle === "headline") {
                styleCss = optin.data.headline.css
            }
            else if (optin.activeStyle === "description") {
                styleCss = optin.data.description.css
            }
            else if (optin.activeStyle === "name") {
                styleCss = optin.data.name.css
            }
            else if (optin.activeStyle === "email") {
                styleCss = optin.data.email.css
            }
            else if (optin.activeStyle === "phone") {
                styleCss = optin.data.phone.css
            }
            else if (optin.activeStyle === "review") {
                styleCss = optin.data.review.css
            }
        }
        setDataStyle(styleCss)
    }, [widget, optin])

    useEffect(() => {
        dispatch(onFetchFont(setFont))
    }, [])

    return (
        <div className="widget-elements pop" style={{ display: widget ? widget.activeStyle ? "" : "none" : optin ? optin.activeStyle ? "" : "none" : "none" }} onClick={() => setToggle(false)}>
            {socialNames.includes(widget.activeStyle) ?
                <>
                    <div className="widget-elements-title">
                        <h6>Icon</h6>
                        <label class="switch">
                            <input
                                type="checkbox"
                                checked={isIcon}
                                name='icon'
                                onChange={handleChange}
                            />
                            <span class="slider"></span>
                        </label>
                    </div>

                    <hr />
                </>
                : null}

            <div className="widget-elements-title">
                <h6>Font Family</h6>
            </div>
            <div className="input-outer">
                <select
                    className="common-inp alt"
                    name="fontFamily"
                    value={dataStyle.fontFamily}
                    style={{ fontFamily: dataStyle.fontFamily }}
                    onChange={handleChange}
                >
                    <option value={""}>None</option>
                    {font.length > 0 ?
                        font.map((curElem, index) => {
                            return (
                                <option style={{ fontFamily: curElem }} key={index} value={curElem}>{curElem}</option>
                            )
                        }) : ""}

                </select>
            </div>

            <hr />

            <div className="widget-elements-title">
                <h6>Font Size</h6>
            </div>
            <div className="input-outer">
                <select
                    className="common-inp alt"
                    name="fontSize"
                    value={dataStyle.fontSize}
                    onChange={handleChange}
                >
                    {sizeArr.length > 0 ?
                        sizeArr.map((curElem, index) => {
                            return (
                                <option key={index} value={curElem}>{curElem.replace("px", "")}</option>
                            )
                        })
                        : ""}
                </select>
            </div>

            <hr />

            <div className="widget-elements-title">
                <h6>Color</h6>
            </div>
            <WidgetColor
                type={"color"}
                color={dataStyle.color}
                handleChangeColor={handleChangeColor}
            />

            <hr />

            <div className="widget-elements-title">
                <h6>Formatting</h6>
            </div>
            <div style={{ position: "relative" }}>
                <div className="txt-format" >
                    <ul>
                        <li onClick={() => handleFormate("w")}><span className={dataStyle.fontWeight === "bold" ? 'active' : ""}><FaBold /></span></li>
                        <li onClick={() => handleFormate("i")}><span className={dataStyle.fontStyle === "italic" ? 'active' : ""}><FaItalic /></span></li>
                        <li onClick={() => handleFormate("u")}><span className={dataStyle.textDecoration === "underline" ? 'active' : ""}><FaUnderline /></span></li>
                        <li ><span className={dataStyle.textAlign === "bold" ? 'active' : ""} onClick={handleToggle}><FaAlignLeft /></span></li>
                    </ul>

                </div>

                <div style={{ display: toggle ? "block" : "none" }}>
                    <div className='txt_arr'></div>
                    <div className='txt_align'>
                        <div
                            style={{ borderTop: "none", background: dataStyle.textAlign === "left" || dataStyle.justifyContent === "left" ? themeColor : "", cursor: "pointer" }}
                            onClick={() => handleFormate("al")}
                        >
                            <FiAlignLeft style={{ fontSize: "20px", marginRight: "5px" }} />
                            Left align
                        </div>
                        <div
                            style={{ background: dataStyle.textAlign === "center" || dataStyle.justifyContent === "center" ? themeColor : "", cursor: "pointer" }}
                            onClick={() => handleFormate("ac")}
                        >
                            <FiAlignCenter style={{ fontSize: "20px", marginRight: "5px" }} />
                            Center align
                        </div>
                        <div
                            style={{ background: dataStyle.textAlign === "right" || dataStyle.justifyContent === "right" ? themeColor : "", cursor: "pointer" }}
                            onClick={() => handleFormate("ar")}
                        >
                            <FiAlignRight style={{ fontSize: "20px", marginRight: "5px" }} />
                            Right align
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            {socialNames.includes(widget.activeStyle) ?
                <>
                    <div className="widget-elements-title">
                        <h6>Button Color</h6>
                    </div>
                    <WidgetColor
                        type={"background"}
                        color={dataStyle.background}
                        handleChangeColor={handleChangeColor}
                    />
                </> : null}
        </div>
    )
}

export default WindowStyle