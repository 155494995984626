import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const onSearchLeads = (data, setLeadData, state, setState, loader, setLoader, type) => (dispatch, getState) => {
    commonAxios("search-leads", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (type === "fetchMore") {
                    setLeadData(res.data.data);
                } else {
                    setLeadData(res.data.data);
                }
                setState({ ...state, fId: res.data.fId, next_page: res.data.next_page_token });
            } else {
                setLeadData([])
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onCreateLeadFolder = (data, loader, setLoader, handleClose, setMainCheckedArr) => (dispatch, getState) => {
    commonAxios("create-folder", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onFetchLeadFolder(handleClose, loader, setLoader, res.msg))
                setMainCheckedArr((draft) => {
                    draft.unshift([])
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchLeadFolder = (handleClose, loader, setLoader, message, setSweet) => (dispatch, getState) => {
    commonAxios("fetch-folder", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: res.data.folders, loader: false } })
            } else {
                dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: false, loader: false } })
            }
            if (handleClose) {
                handleClose();
                dispatch(setAlert(message, "success"))
            }
            if (setLoader) {
                setLoader({ ...loader, submit: false });
            }
            if (setSweet) {
                setSweet({
                    enable: false,
                    id: false,
                    confirmButtonName: "Delete",
                    loader: false
                })
            }
        }).catch((err) => {
            console.log(err)
            if (setLoader) {
                setLoader({ ...loader, submit: false });
            }
            if (setSweet) {
                setSweet({
                    enable: false,
                    id: false,
                    confirmButtonName: "Delete",
                    loader: false
                })
            }
            dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: false, loader: false } })
            dispatch(setAlert(err.message, "danger"))
        })
}
export const onFetchFolderDataById = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-leads", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onEditLeadFolder = (data, loader, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("edit-folder-name", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onFetchLeadFolder(handleClose, loader, setLoader, res.msg))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onDeleteLeadFolder = (url, data, state, index, setSweet, mainCheckedArr, setMainCheckedArr) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.type === "all") {
                    dispatch(onFetchLeadFolder(false, false, false, res.msg, setSweet))
                    setMainCheckedArr([])
                } else if (data.type === "lead") {
                    let lead = state[index].leads.filter((lead) => {
                        return +lead.id !== +data.id[0]
                    })

                    let checkArray = mainCheckedArr.filter((lead) => {
                        return +lead !== +data.id[0]
                    })
                    setMainCheckedArr((draft) => {
                        draft[index] = checkArray
                    })

                    dispatch({ type: "DELETE_FOLDER_LEAD", payload: { data: lead, index: index } })
                } else {
                    let folder = state.filter((curElem) => {
                        return +curElem.fId !== +data.id
                    })
                    setMainCheckedArr((draft) => {
                        draft[index] = []
                    })
                    dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: folder, loader: false } })
                }
                if (data.type !== "all") {
                    dispatch(setAlert(res.msg, "success"))
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            if (data.type !== "all") {
                setSweet({
                    enable: false,
                    id: false,
                    confirmButtonName: "Delete",
                    loader: false
                })
            }
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onMoveLead = (data, loader, setLoader, handleClose, index, mainCheckedArr, setMainCheckedArr) => (dispatch, getState) => {
    commonAxios("move-leads", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onFetchLeadFolder(handleClose, loader, setLoader, res.msg))
                if (data.id.length > 1 || !index) {
                    setMainCheckedArr([]);
                } else {
                    let checkArray = mainCheckedArr.filter((lead) => {
                        return +lead !== +data.id[0]
                    })
                    setMainCheckedArr((draft) => {
                        draft[index] = checkArray
                    })
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onAddManualLead = (data, setState, loader, setLoader, fetchAllFolder) => (dispatch, getState) => {
    commonAxios("add-lead", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setState({
                    fId: "",
                    name: "",
                    emails: "",
                    phoneNumber: ""
                });
                fetchAllFolder();
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onLeadStatusChange = (data, index, leadFolderData) => (dispatch, getState) => {
    let leadIndex = leadFolderData[index].leads.findIndex((lead) => +lead.id === +data.id)
    dispatch({ type: "CHANGE_FOLDER_LEAD_STATUS", payload: { index, leadIndex, status: data.status } })

    commonAxios("change-lead-status", data, dispatch, getState().auth.token)
        .then((res) => {

        }).catch((err) => {
            console.log(err)
        })
}

export const onUploadLeadFile = (data, setState, loader, setLoader, fetchAllFolder) => (dispatch, getState) => {
    commonAxios("add-lead-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    fId: "",
                    file: ""
                });
                fetchAllFolder();
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, upload: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, upload: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchEmailCampaigns = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-contact-emails", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data);
            } else {
                setState([]);
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchEmailTemplates = (data, setState) => (dispatch, getState) => {
    commonAxios("fetch-email-template", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data);
            } else {
                setState([]);
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onSaveContactLead = (data, loader, setLoader, setIsSave) => (dispatch, getState) => {
    commonAxios("create-contact", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setIsSave(true)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, save: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onSendContactLead = (data, loader, setLoader, navigator) => (dispatch, getState) => {
    commonAxios("send-leads-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigator("/lead/contact-leads")
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onTestContactLead = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("test-email-contact", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, test: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, test: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onSaveAiEmail = (data, loader, setLoader, navigator) => (dispatch, getState) => {
    commonAxios("ai-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigator("/lead/ai-email")
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onGenerateAiEmail = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-ai-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    subject: res.data.subject,
                    body: res.data.body
                })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, generate: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, generate: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchAiEmailCampaigns = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-ai-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data);
            } else {
                setState([]);
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchAiEmailDetails = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = res.data[0]
                let emailOptions = []
                if (obj.email.length > 0) {
                    emailOptions = JSON.parse(obj.email).map((ele) => {
                        return (
                            { value: ele, label: ele }
                        )
                    })
                }
                setState({
                    id: obj.id,
                    fId: obj.fId,
                    leadId: obj.leadId,
                    email: emailOptions,
                    category: obj.category,
                    phoneNumber: obj.phoneNumber,
                    description: obj.description,
                    operation: obj.operation,
                    address: obj.address,
                    searchServiceKeyword: obj.searchServiceKeyword,
                    subject: obj.subject,
                    body: obj.body,
                })
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onGetContactInfo = (data, leadData, setLeadData, setLoader) => (dispatch, getState) => {
    commonAxios("scrap-website-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let leadIndex = leadData.findIndex((val) => +val.id === +data.id)
                if (leadIndex !== -1) {
                    setLeadData(prevData => {
                        const updatedData = [...prevData];
                        updatedData[leadIndex] = {
                            ...updatedData[leadIndex],
                            contact: res.data.contactNumber,
                            videoLink: res.data.videoUrls,
                            emails: res.data.emails
                        };
                        return updatedData;
                    });
                }
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}

export const onGenerateLeadReport = (data, loader, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("lead-report", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                window.open(res.data, "_blank")
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
        })
}

export const onRemoveLeadFolderData = () => (dispatch, getState) => {
    dispatch({ type: "UNMOUNT_LEAD_FOLDER_DATA" })
}
